<template>
  <div>
    <!-- Overlay to display loading spinner -->
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <!-- Container for license information -->
      <div class="d-flex flex-row justify-content-start">
        <div class="d-flex flex-column">
          <!-- Alert to display total number of licenses -->
          <b-alert class="p-1" show variant="light"
            >Total de licenças:
            {{
              companyData.aecLicenses ? companyData.aecLicenses : " - "
            }}</b-alert
          >

          <!-- Alert to display licenses assigned and percentage -->
          <b-alert class="p-1" show variant="light"
            >Licenças atribuídas:
            {{ totalActiveLicenses ? totalActiveLicenses + " - " : "" }}
            {{ licensePorcentage ? licensePorcentage + "%" : "" }}</b-alert
          >
          <!-- Alert to display total number of active users in the period -->
          <b-alert v-if="filteredAecUsers.length" class="p-1" show variant="light"
            >Total de usuários ativos no periodo: {{ totalUsers }}</b-alert
          >
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Total number of active users
      totalUsers: 0,
      // Percentage of active licenses
      licensePorcentage: "",
    };
  },
  methods: {
    /**
     * Asynchronously calculates the percentage of active AEC licenses compared to the total available licenses for the company.
     * The result is stored in the licensePorcentage property rounded to two decimal places.
     */
    async getLicensePorcentage() {
      this.licensePorcentage = (
        (this.totalActiveLicenses * 100) /
        this.companyData.aecLicenses
      ).toFixed(2);
    },
  },
  watch: {
    // Watcher for changes in filteredAecUsers prop
    filteredAecUsers: async function () {
      // Get a copy of the filtered users list
      var accessList = await this.filteredAecUsers.slice();

      //remove duplicates emails and get the length to define total of users
      var usersWithAccess = await accessList.filter((u) => {
        return u.aecCollectionAccess.length > 0;
      });

      // Set totalUsers to the count of users with AEC access
      this.totalUsers = usersWithAccess.length;
    },
  },
  props: [
    // Props received from parent component
    "companyData",
    "filteredAecUsers",
    "isLoading",
    "totalActiveLicenses",
  ],
  updated() {
    // Update the license percentage when component is updated
    this.getLicensePorcentage();
  },
};
</script>