import {
    get as getAecUserApi
} from '../apiRequest';

/**
 * Retrieves AEC user information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which AEC user information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getAecUser = async (companyId) => {
    const apiResponse = await getAecUserApi('api/aecUser/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}