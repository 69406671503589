<template>
  <div>
    <div>
      <b-card>
        <aec-collection-access-dashboard-form
          @filterAecCollectionAccess="filterAecCollectionAccess"
          :users="users"
          :products="products"
          :teams="teams"
          :versions="versions"
          :isLoading="isLoading"
          :aecCollectionAccessesReportLog="aecCollectionAccessesReportLog"
        >
        </aec-collection-access-dashboard-form>
      </b-card>
    </div>    
    <div>
      <b-card class="mt-3">
        <aec-collection-access-dashboard-info
          :companyData="companyData"
          :filteredAecUsers="filteredAecUsers"
          :isLoading="isLoading"
          :totalActiveLicenses="totalActiveLicenses"
        ></aec-collection-access-dashboard-info>
      </b-card>
    </div>
    <div>
      <aec-collection-access-dashboard-grid
        :filteredAecUsers="filteredAecUsers"
        :isLoading="isLoading"
      ></aec-collection-access-dashboard-grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from "../form";
import Grid from "../grid";
import Info from "../info";
import jwt_decode from "jwt-decode";
import { getAecCollectionAccessReportLog } from "../../../../../../services/aecCollectionAccessService";
import { getAecUser } from "../../../../../../services/aecUserService";

export default {
  data() {
    return {
      users: [],
      teams: [],
      products: [],
      versions: [],
      aecCollectionAccesses: [],
      aecUsers: [],
      filteredAecUsers: [],
      isLoading: true,
      aecCollectionAccessesReportLog: {},
      totalActiveLicenses: 0,
    };
  },
  components: {
    "aec-collection-access-dashboard-form": Form,
    "aec-collection-access-dashboard-grid": Grid,
    "aec-collection-access-dashboard-info": Info,
  },
  methods: {
    /**
     * Asynchronously retrieves AEC collection accesses for the current company.
     */
    async getAecCollectionAccesses() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      this.aecUsers = await getAecUser(companyId).then((result) => {
        return result.data;
      });

      this.aecCollectionAccesses = await this.aecUsers.reduce((acc, curr) => {
        return acc.concat(curr.aecCollectionAccess);
      }, []);

      const oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

      var filter = {
        email: undefined,
        product: [],
        team: undefined,
        initialPeriod: oneYearAgo
          .toISOString()
          .split("T")[0],
        endPeriod: new Date().toISOString().split("T")[0],
        version: undefined,
        accessNumber: "",
      };

      this.filterAecCollectionAccess(filter);
    },
    /**
     * Asynchronously retrieves the AEC collection access report log for the current company.
     * Sets the fetched report log data to the component's 'aecCollectionAccessesReportLog' property.
     * Retrieves and sets the total number of active licenses from the fetched report log data.
     */
    async getAecCollectionAccessReportLog() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      var result = await getAecCollectionAccessReportLog(companyId);
      this.aecCollectionAccessesReportLog = result.data;

      this.totalActiveLicenses = await this.aecCollectionAccessesReportLog.sort(
        (a, b) => {
          return (
            new Date(b.scriptExecutionDate) - new Date(a.scriptExecutionDate)
          );
        }
      )[0].totalActiveUsers;
    },
    filterAecCollectionAccess(filter, ...callback) {
      this.isLoading = true;
      var filteredList = JSON.parse(JSON.stringify(this.aecUsers));

      //filter by period
      if (filter.initialPeriod && filter.endPeriod) {
        //convert initial date
        let initialDate = new Date(filter.initialPeriod.replace("-", "/"));
        initialDate.setHours(0, 0, 0, 0);

        //convert end date
        let endDate = new Date(filter.endPeriod.replace("-", "/"));
        endDate.setHours(0, 0, 0, 0);

        //filter dates between
        filteredList = filteredList.filter((b) => {
          b.aecCollectionAccess = b.aecCollectionAccess.filter((ba) => {
            let accessDate = new Date(ba.dayUsed).setHours(0, 0, 0, 0);

            return accessDate >= initialDate && accessDate <= endDate;
          });

          if (filter.accessNumberLess) {
            return b.aecCollectionAccess.length <= filter.accessNumberLess;
          } else {
            return b.aecCollectionAccess;
          }
        });
      }

      //filter by email
      if (filter.email) {
        filteredList = filteredList.filter((b) => {
          b.aecCollectionAccess = b.aecCollectionAccess.filter((aca) => {
            return aca.email == filter.email;
          });

          return b.aecCollectionAccess;
        });
      }

      //filter by product
      if (filter.product.length != 0) {
        filteredList = filteredList.filter((b) => {
          b.aecCollectionAccess = b.aecCollectionAccess.filter((aca) => {
            return filter.product.includes(aca.productName);
          });

          return b.aecCollectionAccess;
        });
      }

      //filter by team
      if (filter.team) {
        filteredList = filteredList.filter((b) => {
          b.aecCollectionAccess = b.aecCollectionAccess.filter((aca) => {
            return aca.teamAlias == filter.team;
          });

          return b.aecCollectionAccess;
        });
      }

      //filter by version
      if (filter.version) {
        filteredList = filteredList.filter((b) => {
          b.aecCollectionAccess = b.aecCollectionAccess.filter((aca) => {
            return aca.version == filter.version;
          });

          return b.aecCollectionAccess;
        });
      }

      //filter by access number
      if (filter.accessNumber) {
        filteredList = filteredList.filter(
          (b) => b.aecCollectionAccess.length >= filter.accessNumber
        );
      }

      //update chart
      this.filteredAecUsers = filteredList;

      if (callback) {
        callback.forEach((c) => c.apply());
      }

      this.isLoading = false;
    },
    /**
     * Asynchronously retrieves user options based on perpetual access data and sets the options to the component's users property.
     */
    getUserOptions() {
      this.users = Array.from(
        this.aecCollectionAccesses.reduce(
          (acc, cur) => acc.set(cur.email, (acc.get(cur.email) || 0) + 1),
          new Map()
        )
      ).map(([email]) => {
        return {
          text: email,
          value: email,
        };
      });

      this.users.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    getVersionOptions() {
      const seen = new Set();

      this.versions = this.aecCollectionAccesses
          .filter((item) => {
            const isDuplicate = seen.has(item.version);
            seen.add(item.version);
            return !isDuplicate;
          })
          .map((u) => ({
            text: u.version,
            value: u.version,
          }));

      this.versions.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    /**
     * Retrieves unique team options from the AEC collection accesses and populates the teams array.
     * Teams are sorted alphabetically by team alias.
     */
    getTeamOptions() {
        const seen = new Set();

        this.teams = this.aecCollectionAccesses
          .filter((item) => {
            const isDuplicate = seen.has(item.teamAlias);
            seen.add(item.teamAlias);
            return !isDuplicate;
          })
          .map((u) => ({
            text: u.teamAlias,
            value: u.teamAlias,
          }));

      this.teams.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    /**
     * Retrieves unique team options from the AEC collection accesses and populates the teams array.
     * Teams are sorted alphabetically by team alias.
     */
    getProductOptions() {
        const seen = new Set();

        this.products = this.aecCollectionAccesses
          .filter((item) => {
            const isDuplicate = seen.has(item.productName);
            seen.add(item.productName);
            return !isDuplicate;
          })
          .map((u) => ({
            text: u.productName,
            value: u.productName,
          }));

      this.products.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
  },
  props: ["companyData"],
  async created() {
    await this.getAecCollectionAccesses();

    this.getUserOptions();
    this.getTeamOptions();
    this.getProductOptions();
    this.getVersionOptions();
    this.getAecCollectionAccessReportLog();

    this.isLoading = false;
  },
};
</script>
