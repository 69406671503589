<template>
  <div>
    <!-- Overlay to display loading spinner -->
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <!-- Card container for AEC access charts -->
      <b-card class="mt-3">      
        
        <!-- AEC access number chart container -->
        <aec-access-number-chart-container
          :filteredAecUsers="filteredAecUsers"
        >
        </aec-access-number-chart-container>

        <!-- AEC cloud access number chart container -->
        <aec-cloud-access-number-chart-container
          :filteredAecUsers="filteredAecUsers"
        >
        </aec-cloud-access-number-chart-container>

        <!-- Flex container for most used product charts -->
        <div class="d-flex justify-content-center">
          <div>
            <!-- Most used product chart container -->
            <aec-most-used-product-chart-container
              :filteredAecUsers="filteredAecUsers"
            >
            </aec-most-used-product-chart-container>
          </div>

          <div>
            <!-- Most used cloud product chart container -->
            <aec-most-used-cloud-product-chart-container
              :filteredAecUsers="filteredAecUsers"
            >
            </aec-most-used-cloud-product-chart-container>
          </div>
        </div>

        <!-- Flex container for access per version chart -->
        <div class="d-flex justify-content-center">
          <div>
            <!-- Access per version chart container -->
            <aec-access-per-version-chart-container
              :filteredAecUsers="filteredAecUsers"
            >
            </aec-access-per-version-chart-container>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import AecAccessNumberChartContainer from "../components/aecAccessNumberChartContainer";
import AecCloudAccessNumberChartContainer from "../components/aecCloudAccessNumberChartContainer";
import AecMostUsedProductChartContainer from "../components/aecMostUsedProductChartContainer";
import AecMostUsedCloudProductChartContainer from "../components/aecMostUsedCloudProductChartContainer";
import AecAccessPerVersionChartContainer from "../components/aecAccessPerVersionChartContainer";
export default {
  data() {
    return {
      // Total number of users
      totalUsers: 0,
    };
  },
  components: {
    // Registering chart components
    "aec-access-number-chart-container": AecAccessNumberChartContainer,
    "aec-access-per-version-chart-container": AecAccessPerVersionChartContainer,
    "aec-cloud-access-number-chart-container": AecCloudAccessNumberChartContainer,
    "aec-most-used-product-chart-container": AecMostUsedProductChartContainer,
    "aec-most-used-cloud-product-chart-container": AecMostUsedCloudProductChartContainer,
  },
  props: [
    // Props received from parent component
    "filteredAecUsers",
    "isLoading",
  ],
};
</script>
