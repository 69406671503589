<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <b-card class="mt-3">
        <simultaneous-license-use-chart-container
          :filteredPerpetualAccesses="filteredPerpetualAccesses"
        >
        </simultaneous-license-use-chart-container>

      <!-- Container for hours per user chart -->
      <hours-per-user-chart-container
          :filteredPerpetualAccesses="filteredPerpetualAccesses"
        >
        </hours-per-user-chart-container>
        <!-- Flex container for two more chart components -->
        <div class="d-xl-flex justify-content-around">
          <!-- Container for hours per product chart -->
          <!-- <license-solicitation-fulfilled-chart-container :filteredPerpetualAccesses="filteredPerpetualAccesses">
          </license-solicitation-fulfilled-chart-container> -->
          <div>
            <hours-per-product-chart-container
              :filteredPerpetualAccesses="filteredPerpetualAccesses"
            >
            </hours-per-product-chart-container>
          </div>

        <!-- Container for access per product chart -->
        <div>
            <access-per-product-chart-container
              :filteredPerpetualAccesses="filteredPerpetualAccesses"
            >
            </access-per-product-chart-container>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// Importing chart container components
import MaxSimultaneousLicenseUseChartContainer from "../components/maxSimultaneousLicenseUseChartContainer";
// import LicenseSolicitationFulfilledChartContainer from "../components/licenseSolicitationFulfilledChartContainer";
import HoursPerProductChartContainer from "../components/hoursPerProductChartContainer";
import AccessPerProductChartContainer from "../components/accessPerProductChartContainer";
import HoursPerUserChartContainer from "../components/hoursPerUserChartContainer";

export default {
  components: {
    // Registering chart container components
    "simultaneous-license-use-chart-container":
      MaxSimultaneousLicenseUseChartContainer,
    // "license-solicitation-fulfilled-chart-container":
    //   LicenseSolicitationFulfilledChartContainer,
    "hours-per-product-chart-container": HoursPerProductChartContainer,
    "access-per-product-chart-container": AccessPerProductChartContainer,
    "hours-per-user-chart-container": HoursPerUserChartContainer,
  },
  props: ["filteredPerpetualAccesses", "isLoading"],
};
</script>
