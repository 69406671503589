<template>
  <div>
    <access-number-chart
      :data="data"
      :categories="categories"
    ></access-number-chart>
  </div>
</template>

<script>
import AccessNumberChart from "../../../charts/cloudAccessNumberChart";

export default {
  components: {
    "access-number-chart": AccessNumberChart,
  },
  data() {
    return {
      data: [],
      categories: [],
    };
  },
  watch: {
    filteredBimUsers: async function () {
      //remove duplicates
      var filteredList = await JSON.parse(
        JSON.stringify(this.filteredBimUsers)
      );

      //sort
      await filteredList.sort((a, b) => {
        if (a.bimAccesses.length > b.bimAccesses.length) {
          return -1;
        } else {
          return 1;
        }
      });

      //update chart option
      this.data = await filteredList.map((a) => a.bimAccesses.length);

      //update chart xAxis
      this.categories = await filteredList.map((u) => u.email);
    },
  },
  props: ["filteredBimUsers"],
};
</script>