<template>
  <div>
    <!-- Render the HoursPerUserChart component with data and categories props -->
    <access-number-chart :data="data" :categories="categories">
    </access-number-chart>
  </div>
</template>

<script>
import AccessNumberChart from "../../../charts/accessNumberChart";
export default {
  components: {
    "access-number-chart": AccessNumberChart,
  },
  data() {
    return {
      // Initialize data and categories as empty arrays
      data: [],
      categories: [],
    };
  },
  watch: {
    filteredPerpetualAccesses: async function () {
      var result = [];

      this.filteredPerpetualAccesses.forEach(function (a) {
        // If the user does not exist in the result array, create it
        if (!this[a.user]) {
          this[a.user] = { user: a.user, used: 0 };
          // Add the hours to the corresponding user
          result.push(this[a.user]);
        }
        this[a.user].used += a.used;
      }, Object.create(null)); // Use an empty object as the initial accumulator for forEach

      result.sort((a, b) => {
        if (a.used > b.used) {
          return -1;
        } else {
          return 1;
        }
      });

      //update chart option
      this.data = result.map((u) => u.used);

      //update chart xAxis
      this.categories = result.map((u) => u.user);
    },
  },
  props: ["filteredPerpetualAccesses"],
};
</script>