<template>
  <div>
    <!-- Highcharts component for displaying column chart -->
    <highcharts
      class="hc mt-5"
      :options="chartOptions"
      ref="chart"
    ></highcharts>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Chart options for Highcharts
      chartOptions: {
        lang: {
          noData: "Nenhuma Informação Disponível",
        },
        credits: {
          enabled: false,
        },
        legend: { enabled: false },
        chart: {
          type: "pie",
        },
        yAxis: {
          title: {
            text: "Acessos",
          },
          tickInterval: 1,
        },
        title: {
          text: "Acessos por Produtos (Cloud)",
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b> <br> {point.percentage:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Acessos",
            dataSorting: {
              enabled: true,
            },
            data: [],
          },
        ],
        exporting: {
          showTable: false,
          csv: {
            columnHeaderFormatter: function (item) {
              if (!item) {
                return "Produto";
              } else {
                return item.name;
              }
            },
          },
        },
      },
    };
  },
  watch: {
    // Watch for changes in data prop and update chart data accordingly
    data: function () {
      this.chartOptions.series[0].data = this.data;
    },
  },
  props: ["data"],
};
</script>