<template>
  <div>
    <!-- Render the HoursPerUserChart component with data and categories props -->
    <hours-per-user-chart :data="data" :categories="categories">
    </hours-per-user-chart>
  </div>
</template>

<script>
import HoursPerUserChart from "../../../charts/hoursPerUserChart";
export default {
  components: {
    "hours-per-user-chart": HoursPerUserChart,
  },
  data() {
    return {
      // Initialize data and categories as empty arrays
      data: [],
      categories: [],
    };
  },
  watch: {
    filteredPerpetualAccesses: async function () {
      // Initialize an array to hold processed data
      var result = [];
      
      // Loop through each filtered perpetual access entry
      this.filteredPerpetualAccesses.forEach(function (a) {
        // If the user does not exist in the result array, create it
        if (!this[a.user]) {
          this[a.user] = { user: a.user, hours: 0 };
          // Add the hours to the corresponding user
          result.push(this[a.user]);
        }
        this[a.user].hours += a.hours;
      }, Object.create(null)); // Use an empty object as the initial accumulator for forEach

      result.sort((a, b) => {
        if (a.hours > b.hours) {
          return -1;
        } else {
          return 1;
        }
      });

      //update chart option
      this.data = result.map((u) => parseFloat(u.hours.toFixed(2)));

      //update chart xAxis
      this.categories = result.map((u) => u.user);
    },
  },
  props: ["filteredPerpetualAccesses"],
};
</script>