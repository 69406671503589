import {
    get as getAecCollectionAccessApi,
} from '../apiRequest';

/**
 * Retrieves AEC collection access information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which AEC collection access information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getAecCollectionAccess = async (companyId) => {
    const apiResponse = await getAecCollectionAccessApi('api/aecCollectionAccess/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}

/**
 * Retrieves the report log for AEC collection access of a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which the report log is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getAecCollectionAccessReportLog = async (companyId) => {
    const apiResponse = await getAecCollectionAccessApi('api/aecCollectionAccess/report/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}