<template>
  <div>
    <!-- Highcharts component for displaying column chart -->
    <highcharts
      class="hc mt-1"
      :options="chartOptions"
      ref="chart"
    ></highcharts>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Chart options for Highcharts
      chartOptions: {
        column: {
          dataSorting: {
            enabled: true,
          },
        },
        lang: {
          noData: "Nenhuma Informação Disponível",
        },
        tooltip: {
          outside: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            minPointLength: 5,
          },
        },
        legend: { enabled: false },
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 0,
            scrollPositionX: 0,
          },
        },
        yAxis: {
          title: {
            text: "Acessos",
          },
          tickInterval: 1,
        },
        xAxis: {
          title: {
            text: "Usuário",
          },
          categories: [],
          labels: {
            rotation: 0,
          },
        },
        title: {
          text: "Quantidade de acessos por usuário (Cloud)",
        },
        series: [
          {
            name: "Acessos",
            dataSorting: {
              enabled: true,
            },
            data: this.data,
          },
        ],
      },
    };
  },
  watch: {
    // Watch for changes in data prop and update chart data accordingly
    data: function () {
      this.chartOptions.series[0].data = this.data;
    },
    // Watch for changes in categories prop and update chart categories and minWidth accordingly
    categories: function () {
      this.chartOptions.xAxis.categories = this.categories;
      this.chartOptions.chart.scrollablePlotArea.minWidth =
        this.categories.length * 30;
    },
  },
  props: ["data", "categories"],
};
</script>