<template>
  <div>
    <div>
      <b-card>
        <perpetual-access-dashboard-form
          @filterPerpetualAccess="filterPerpetualAccess"
          :users="users"
          :products="products"
          :teams="teams"
          :isLoading="isLoading"
          :perpetualAccessesReportLog="perpetualAccessesReportLog"
        ></perpetual-access-dashboard-form>
      </b-card>
    </div>
    <div>
      <b-card class="mt-3">
        <perpetual-access-dashboard-info
          :companyData="companyData"
          :filteredPerpetualAccesses="filteredPerpetualAccesses"
          :isLoading="isLoading"
        >
        </perpetual-access-dashboard-info>
      </b-card>
    </div>
    <div>
      <perpetual-access-dashboard-grid
        :filteredPerpetualAccesses="filteredPerpetualAccesses"
        :isLoading="isLoading"
      >
      </perpetual-access-dashboard-grid>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from "../form";
import Grid from "../grid";
import Info from "../info";
import jwt_decode from "jwt-decode";
import {
  getPerpetualAccess,
  getPerpetualAccessReportLog,
} from "../../../../../../services/perpetualAccessService";
import { getFeatureByCode } from "../../../../../../utils";

export default {
  data() {
    return {
      users: [],
      teams: [],
      products: [],
      perpetualAccesses: [],
      filteredPerpetualAccesses: [],
      isLoading: true,
      perpetualAccessesReportLog: [],
    };
  },
  components: {
    "perpetual-access-dashboard-form": Form,
    "perpetual-access-dashboard-grid": Grid,
    "perpetual-access-dashboard-info": Info,
  },
  methods: {
    /**
     * Asynchronously retrieves perpetual accesses data based on the user's token and sets the retrieved data to the component's perpetualAccesses property.
     */
    async getPerpetualAccesses() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      this.perpetualAccesses = await getPerpetualAccess(companyId).then(
        (result) => {
          return result.data;
        }
      );

      this.perpetualAccesses.forEach(async (p) => {
        p.featureDescription = await getFeatureByCode(p.feature);
      });

      this.perpetualAccesses.sort((a, b) => {
        if (a.hours > b.hours) {
          return -1;
        } else {
          return 1;
        }
      });

      this.filteredPerpetualAccesses = this.perpetualAccesses;
    },
    /**
     * Asynchronously retrieves perpetual access report log data based on the user's token and sets the retrieved data to the component's perpetualAccessesReportLog property.
     */
    async getPerpetualAccessReportLog() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      this.perpetualAccessesReportLog = await getPerpetualAccessReportLog(
        companyId
      ).then((result) => {
        return result.data;
      });
    },
    /**
     * Asynchronously retrieves user options based on perpetual access data and sets the options to the component's users property.
     */
    async getUserOptions() {
      this.users = Array.from(
        this.perpetualAccesses.reduce(
          (acc, cur) => acc.set(cur.user, (acc.get(cur.user) || 0) + 1),
          new Map()
        )
      )
        .filter(([user]) => {
          return (
            user != "[None]" &&
            user != "000000000000000000000000d96d330a" &&
            user != "[lost]"
          );
        })
        .map(([user]) => {
          return {
            text: user.toLowerCase(),
            value: user.toLowerCase(),
          };
        });
      // Sort user options alphabetically
      this.users.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    /**
     * Asynchronously retrieves product options based on perpetual access data and sets the options to the component's products property.
     */
    async getProductOptions() {
      this.products = this.perpetualAccesses
        .filter(
          (v, i, a) =>
            a.findIndex(
              (t) => t.featureDescription === v.featureDescription
            ) === i
        )
        .map((u) => {
          return {
            text: u.featureDescription,
            value: u.featureDescription,
          };
        });

      this.products.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    async filterPerpetualAccess(filter, ...callback) {
      this.isLoading = true;

      setTimeout(() => {
        var filteredList = this.perpetualAccesses;

        //filter by user
        if (filter.user) {
          filteredList = filteredList.filter((b) => b.user == filter.user);
        }

        //filter by product
        if (filter.product) {
          filteredList = filteredList.filter(
            (b) => b.featureDescription == filter.product
          );
        }

        //filter by hours of access
        if (filter.accessHours) {
          //group users and sum the hours
          let result = [];
          filteredList.reduce(function (res, value) {
            if (!res[value.user]) {
              res[value.user] = { user: value.user, hours: 0 };
              result.push(res[value.user]);
            }
            res[value.user].hours += value.hours;
            return res;
          }, {});

          // get only results wich match with hour filter
          result = result.filter((r) => r.hours >= filter.accessHours);

          //match result data with filteredList
          filteredList = filteredList.filter((fl) => {
            return result.some((r) => r.user == fl.user);
          });
        }

        //filter by hours of access
        if (filter.accessHoursLess) {
          //group users and sum the hours
          let result = [];
          filteredList.reduce(function (res, value) {
            if (!res[value.user]) {
              res[value.user] = { user: value.user, hours: 0 };
              result.push(res[value.user]);
            }
            res[value.user].hours += value.hours;
            return res;
          }, {});

          // get only results wich match with hour filter
          result = result.filter((r) => r.hours <= filter.accessHoursLess);

          //match result data with filteredList
          filteredList = filteredList.filter((fl) => {
            return result.some((r) => r.user == fl.user);
          });
        }

        //filter by access number
        if (filter.accessNumber) {
          //group users and sum the accesses
          let result = [];
          filteredList.reduce(function (res, value) {
            if (!res[value.user]) {
              res[value.user] = { user: value.user, used: 0 };
              result.push(res[value.user]);
            }
            res[value.user].used += value.used;
            return res;
          }, {});

          // get only results wich match with hour filter
          result = result.filter((r) => r.used >= filter.accessNumber);

          //match result data with filteredList
          filteredList = filteredList.filter((fl) => {
            return result.some((r) => r.user == fl.user);
          });
        }

        if (filter.accessNumberLess) {
          //group users and sum the accesses
          let result = [];
          filteredList.reduce(function (res, value) {
            if (!res[value.user]) {
              res[value.user] = { user: value.user, used: 0 };
              result.push(res[value.user]);
            }
            res[value.user].used += value.used;
            return res;
          }, {});

          // get only results wich match with hour filter
          result = result.filter((r) => r.used <= filter.accessNumberLess);

          //match result data with filteredList
          filteredList = filteredList.filter((fl) => {
            return result.some((r) => r.user == fl.user);
          });
        }

        //update chart
        this.filteredPerpetualAccesses = filteredList;

        this.isLoading = false;

        if (callback) {
          callback.forEach((c) => c.apply());
        }
      }, 500);
    },
  },
  props: ["companyData"],
  async created() {
    await this.getPerpetualAccesses();

    this.getUserOptions();
    this.getProductOptions();
    this.getPerpetualAccessReportLog();

    this.isLoading = false;
  },
};
</script>
