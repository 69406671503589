<template>
  <!-- Container for the access-per-version chart component -->
  <div>
    <!-- Include the AccessPerVersionChart component with data prop -->
    <access-per-version-chart :data="data"></access-per-version-chart>
  </div>
</template>

<script>
// Import the AccessPerVersionChart component
import AccessPerVersionChart from "../../../charts/accessPerVersionChart";

export default {
  // Register the AccessPerVersionChart component
  components: {
    "access-per-version-chart": AccessPerVersionChart,
  },
  // Data properties
  data() {
    return {
      // Data array for the chart
      data: [],
    };
  },
  // Watcher for changes in filteredAecUsers prop
  watch: {
    filteredAecUsers: async function () {
      var accessList = [];
      var filteredUsers = [];
      // Clear existing data
      this.data = [];
      this.categories = [];

      // Copy filteredAecUsers array
      filteredUsers = await this.filteredAecUsers.slice();

      // Extract access data from filtered users
      accessList = await filteredUsers.reduce((acc, curr) => {
        return acc.concat(curr.aecCollectionAccess);
      }, []);

      // Count access per version and populate data array
      Array.from(
        accessList.reduce(
          (acc, cur) => acc.set(cur.version, (acc.get(cur.version) || 0) + 1),
          new Map()
        )
      ).map(([version, access]) =>
        this.data.push({
          name: version,
          y: access,
        })
      );
    },
  },
  // Props received from parent component
  props: ["filteredAecUsers"],
};
</script>
