<template>
  <div>
    <!-- Overlay to show loading spinner -->
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <div class="d-flex flex-row justify-content-start">
        <div class="d-flex flex-column">
          <!-- Alert displaying total number of licenses -->
          <b-alert class="p-1" show variant="light">
            <!--Display total number of licenses or dash if not available-->
            Total de licenças: {{ totalLicenses }}
          </b-alert>

          <!-- Alert displaying total number of active users in the period -->
          <b-alert v-if="filteredPerpetualAccesses.length" class="p-1" show variant="light"
            >Total de usuários ativos no periodo: {{ totalUsers }}</b-alert
          >
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalUsers: 0, // Initialize totalUsers data property
    };
  },
  watch: {
    filteredPerpetualAccesses: async function () {
      //remove duplicates emails and get the length to define total of users
      this.totalUsers = await this.filteredPerpetualAccesses.filter(
        (v, i, a) => a.findIndex((t) => t.user === v.user) === i
      ).length;
    },
  },
  props: ["companyData", "filteredPerpetualAccesses", "isLoading"], // Define props passed to the component
  computed: {
    totalLicenses() {
      return this.companyData?.perpetualLicenses ?? ' - ';
    }
  }
};
</script>