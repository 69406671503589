<template>
    <!-- Card component for displaying IDS - Perpétua access information -->
    <b-card class="mt-4" title="IDS - Perpétua">
      <!-- PerpetualAccessInfo component for displaying perpetual access information -->
      <perpetual-access-info
        :companyData="companyData"
        :filteredPerpetualAccesses="[]"
        :isLoading="false"
      >
      </perpetual-access-info>
    </b-card>
    
</template>

<script>
import PerpetualAccessInfo from "../../../../perpetualAccess/info";

export default {
    components: {
    "perpetual-access-info": PerpetualAccessInfo,
  },
  props: ["companyData"],

}
</script>