<template>
  <b-card class="mt-4" title="AEC Collection">
    <aec-collection-access-info
      :companyData="companyData"
      :filteredAecUsers="[]"
      :isLoading="false"
      :totalActiveLicenses="totalActiveLicenses"
    >
    </aec-collection-access-info>
    <line-chart-container
      :accessesReportLog="aecCollectionAccessesReportLog"
    />
  </b-card>
</template>

<script>
import Vue from "vue";
import jwt_decode from "jwt-decode";
import AecCollectionAccessInfo from "../../../../aecCollection/info";
import AecCollectionLineChartContainer from "../../lineChartContainer";
import { getAecCollectionAccessReportLog } from "../../../../../../../../services/aecCollectionAccessService";

export default {
  data() {
    return {
      totalActiveLicenses: 0,
      aecCollectionAccessesReportLog: [],
    };
  },
  components: {
    "line-chart-container": AecCollectionLineChartContainer,
    "aec-collection-access-info": AecCollectionAccessInfo,
  },
  props: ["companyData"],
  methods: {
    /**
     * Asynchronously retrieves the AEC collection access report log for the current company.
     * Sets the fetched report log data to the component's 'aecCollectionAccessesReportLog' property.
     * Retrieves and sets the total number of active licenses from the fetched report log data.
     */
    async getAecCollectionAccessReportLog() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      var result = await getAecCollectionAccessReportLog(companyId);
      this.aecCollectionAccessesReportLog = result.data;

      this.totalActiveLicenses = await this.aecCollectionAccessesReportLog.sort(
        (a, b) => {
          return (
            new Date(b.scriptExecutionDate) - new Date(a.scriptExecutionDate)
          );
        }
      )[0].totalActiveUsers;
    },
  },
  async created() {
    this.getAecCollectionAccessReportLog();
  },
};
</script>