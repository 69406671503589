<template>
  <div>
    <div>
      <b-card>
        <bim-access-dashboard-form
          @filterBimAccess="filterBimAccess"
          :users="users"
          :companies="companies"
          :projects="projects"
          :isLoading="isLoading"
          :bimAccessesReportLog="bimAccessesReportLog"
        ></bim-access-dashboard-form>
      </b-card>
    </div>
    <div>
      <b-card class="mt-3">
        <bim-access-dashboard-info
          :companyData="companyData"
          :filteredBimUsers="filteredBimUsers"
          :isLoading="isLoading"
          :bimAccessesReportLog="bimAccessesReportLog"
        >
        </bim-access-dashboard-info>
      </b-card>
    </div>
    <div>
      <bim-access-dashboard-grid
        :filteredBimUsers="filteredBimUsers"
        :isLoading="isLoading"
      >
      </bim-access-dashboard-grid>
    </div>
    <div>
      <bim-access-project-users-info
        :showProjectUsersInfo="showProjectUsersInfo"
        :selectedProject="selectedProject"
        :items="projectUsers"
      >
      </bim-access-project-users-info>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from "../form";
import Grid from "../grid";
import Info from "../info";
import ProjectUsersInfo from "../projectUsersInfo";
import jwt_decode from "jwt-decode";
import { getBimAccessReportLog } from "../../../../../../services/bimAccessService";
import { getBimUser } from "../../../../../../services/bimUserService";
import { getBimProject } from "../../../../../../services/bimProjectService";

export default {
  data() {
    return {
      users: [],
      companies: [],
      projects: [],
      bimAccesses: [],
      filteredBimUsers: [],
      bimUsers: [],
      isLoading: true,
      bimAccessesReportLog: [],
      showProjectUsersInfo: false,
      selectedProject: "",
      projectUsers: {},
    };
  },
  components: {
    "bim-access-dashboard-form": Form,
    "bim-access-dashboard-grid": Grid,
    "bim-access-dashboard-info": Info,
    "bim-access-project-users-info": ProjectUsersInfo,
  },
  methods: {
    async getBimAccesses() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      this.bimUsers = await getBimUser(companyId).then((result) => {
        return result.data;
      });

      this.bimAccesses = await this.bimUsers.reduce((acc, curr) => {
        return acc.concat(curr.bimAccesses);
      }, []);

      //Initial filter to get only data from las 30 days FIX THIS LATER
      var filter = {
        email: undefined,
        initialPeriod: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0],
        endPeriod: new Date().toISOString().split("T")[0],
        accessNumber: "",
      };
      this.filterBimAccess(filter);
    },
    async getBimAccessReportLog() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      var result = await getBimAccessReportLog(companyId);
      this.bimAccessesReportLog = result.data;      
    },
    async filterBimAccess(filter, ...callback) {
      this.isLoading = true;
      this.showProjectUsersInfo = false;

      setTimeout(() => {
        var filteredList = JSON.parse(JSON.stringify(this.bimUsers));

        //filter by period
        if (filter.initialPeriod && filter.endPeriod) {
          //convert initial date
          let initialDate = new Date(filter.initialPeriod.replace("-", "/"));
          initialDate.setHours(0, 0, 0, 0);

          //convert end date
          let endDate = new Date(filter.endPeriod.replace("-", "/"));
          endDate.setHours(0, 0, 0, 0);

          this.bimAccessesReportLog = this.bimAccessesReportLog.filter(barl=>{ 
            let executionDate = new Date(barl.scriptExecutionDate).setHours(0, 0, 0, 0);
            
            return executionDate >= initialDate && executionDate <= endDate;
          });

          //filter dates between
          filteredList = filteredList.filter((b) => {
            b.bimAccesses = b.bimAccesses.filter((ba) => {
              let accessDate = new Date(ba.accessDate).setHours(0, 0, 0, 0);

              return accessDate >= initialDate && accessDate <= endDate;
            });

            if (filter.accessNumberLess) {
              return b.bimAccesses.length <= filter.accessNumberLess;
            } else {
              return b.bimAccesses;
            }
          });
        }

        //filter by project name
        if (filter.projectName) {
          filteredList = filteredList.filter((b) => {
            b.bimProjects = b.bimProjects.filter((ba) => {
              return ba.projectName == filter.projectName;
            });

            let inProject = b.bimProjects.some(
              (bp) => bp.projectName == filter.projectName
            );

            return b.bimProjects && inProject;
          });

          this.projectUsers = filteredList.map(data=>{
            return {
              Email: data.email,
              Empresa: (data.company == 'None') ? "" : data.company
            }
          })

          this.showProjectUsersInfo = true;
          this.selectedProject = filter.projectName;
        }

        //filter by email
        if (filter.email) {
          filteredList = filteredList.filter((b) => {
            b.bimAccesses = b.bimAccesses.filter((ba) => {
              return ba.email == filter.email;
            });

            return b.bimAccesses;
          });
        }

        //filter by company name
        if (filter.companyName) {
          filteredList = filteredList.filter((b) => {
            b.bimAccesses = b.bimAccesses.filter((ba) => {
              return ba.company == filter.companyName;
            });

            return b.bimAccesses;
          });
        }

        //filter by access number
        if (filter.accessNumber) {
          filteredList = filteredList.filter(
            (b) => b.bimAccesses.length >= filter.accessNumber
          );
        }

        //update chart
        this.filteredBimUsers = filteredList;

        if (callback) {
          callback.forEach((c) => c.apply());
        }

        this.isLoading = false;
      }, 500);
    },
    /**
     * Asynchronously retrieves user options based on perpetual access data and sets the options to the component's users property.
     */
    getUserOptions() {
      this.users = this.bimUsers
        // .filter((v, i, a) => a.findIndex((t) => t.email === v.email) === i)
        .map((u) => {
          return {
            text: u.email,
            value: u.email,
          };
        });

      this.users.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    getCompanyOptions() {
      this.companies = this.bimUsers
        .filter(
          (v, i, a) =>
            a.findIndex((t) => t.company === v.company) === i &&
            v.company != "None"
        )
        .map((u) => {
          return {
            text: u.company,
            value: u.company,
          };
        });

      this.companies.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
    async getBimProjectsOptions() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      var data = await getBimProject(companyId).then((result) => {
        return result.data;
      });

      this.projects = data.map((u) => {
        return {
          text: u.projectName,
          value: u.projectName,
        };
      });

      this.projects.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    },
  },
  props: ["companyData"],
  created() {
    this.getBimAccesses()
      .then(() => {
        this.getUserOptions();
        this.getCompanyOptions();
      })
      .then(() => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    this.getBimAccessReportLog();
    this.getBimProjectsOptions();
  },
};
</script>