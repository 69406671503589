<template>
  <div>
    <most-bim-access-company-chart :data="data"></most-bim-access-company-chart>
  </div>
</template>

<script>
import MostBimAccessByCompanyChart from "../../../charts/accessByCompanyChart";
export default {
  components: {
    "most-bim-access-company-chart": MostBimAccessByCompanyChart,
  },
  data() {
    return {
      data: [],
    };
  },
  watch: {
    filteredBimUsers: async function () {
      //get all access of all users
      var accesses = await this.filteredBimUsers.reduce((acc, curr) => {
        return acc.concat(curr.bimAccesses);
      }, []);

      var filteredList = await accesses.filter(
        (v, i, a) => a.findIndex((t) => t.company === v.company) === i
      );

      //sort
      await filteredList.sort((a, b) => {
        if (
          accesses.filter((d) => d.company == a.company).length >
          accesses.filter((d) => d.company == b.company).length
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      //update chart option
      this.data = await filteredList.map((a) => {
        var companyData = accesses.filter((c) => c.company == a.company);

        return {
          name: a.company,
          y: companyData.length,
        };
      });
    },
  },
  props: ["filteredBimUsers"],
};
</script>