<template>
  <div>
    <!-- Highcharts component for displaying column chart -->
    <highcharts
      class="hc mt-1"
      :options="chartOptions"
      ref="chart"
    ></highcharts>
  </div>
</template>

<script>
export default {
  // Chart options for Highcharts
  data() {
    return {
      chartOptions: {
        lang: {
          noData: "Nenhuma Informação Disponível",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          outside: true,
        },
        legend: { enabled: false },
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 0,
            scrollPositionX: 0,
          },
        },
        yAxis: {
          title: {
            text: "Horas",
          },
          tickInterval: 1,
        },
        xAxis: {
          title: {
            text: "Usuário",
          },
          categories: this.categories,    
          labels: {
            rotation: 0
          },      
        },
        title: {
          text: "Quantidade de horas de acessos por usuário",
        },
        series: [
          {
            name: "Horas",
            dataSorting: {
              enabled: false,
            },
            data: this.data,
          },
        ],
      },
    };
  },
  watch: {
    // Watch for changes in data prop and update chart data accordingly
    data: function () {
      this.chartOptions.series[0].data = this.data;
    },
    // Watch for changes in categories prop and update chart categories and minWidth accordingly
    categories: function () {
      this.chartOptions.xAxis.categories = this.categories;
      this.chartOptions.chart.scrollablePlotArea.minWidth =
        this.categories.length * 30;
    },
  },
  props: ["data", "categories"],
};
</script>