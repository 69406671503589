<template>
  <div>
    <access-info-line-chart :data="data" />
  </div>
</template>
  
  <script>
import AccessInfoLineChart from "../../../charts/accessInfoLineChart";
export default {
  data() {
    return {
      data: [],
    };
  },
  props: ["accessesReportLog"],
  components: {
    "access-info-line-chart": AccessInfoLineChart,
  },
  methods: {
    /**
     * Generates an array containing the dates representing the first day of each of the last twelve months.
     * @returns {Date[]} - An array of Date objects representing the first day of each of the last twelve months.
     */
    getLastTwelveMoths: function () {
      let months = [];
      let today = new Date();
      for (let i = 0; i < 12; i++) {
        const month = today.getMonth() - i;
        const year = today.getFullYear();
        months.push(new Date(year, month, 1));
      }
      return months;
    },
    /**
     * Retrieves the last execution record from the provided access list for the given month.
     * If no execution record is found for the month, a default record with the first day of the month and zero total active users is returned.
     * @param {Date} month - The month for which to retrieve the last execution record.
     * @param {Object[]} accessList - The list of access records to search.
     * @returns {Object} - The last execution record for the given month, or a default record if no execution record is found.
     */
    getLastExecutionByMonth: function (month, accessList) {
      var monthExecutions = accessList.filter((access) => {
        let executionDate = new Date(access.scriptExecutionDate);
        return (
          executionDate.getMonth() == month.getMonth() &&
          executionDate.getFullYear() == month.getFullYear()
        );
      });
      var lastExecution = monthExecutions.sort((a, b) => {
        return (
          new Date(b.scriptExecutionDate) - new Date(a.scriptExecutionDate)
        );
      });
      if (lastExecution.length) return lastExecution[0];
      else
        return {
          scriptExecutionDate: new Date(
            month.getFullYear(),
            month.getMonth(),
            1
          ),
          totalActiveUsers: 0,
        };
    },
    /**
     * Extracts access records for the last twelve months from the provided access list.
     * @param {Object[]} accessList - The list of access records.
     * @returns {Object[]} - An array of access records for the last twelve months.
     */
    extractAccessPerMonth: function (accessList) {
      var dataList = [];
      var lasTwelveMonths = this.getLastTwelveMoths();
      lasTwelveMonths.forEach((month) => {
        dataList.unshift(this.getLastExecutionByMonth(month, accessList));
      });
      return dataList;
    },
  },
  watch: {
    accessesReportLog: function () {
      this.data = this.extractAccessPerMonth(this.accessesReportLog);
    },
  },
};
</script>