<template>
  <div>
    <!-- Executive Content component -->
    <executive-content :companyData="companyData"></executive-content>
  </div>
</template>

<script>
import ExecutiveContent from "../content/index.vue";
export default {
  data() {
    return {};
  },
  components: {
    "executive-content": ExecutiveContent,
  },
  props: ["companyData"],
};
</script>