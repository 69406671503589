<template>
  <div>
    <most-used-product-chart :data="data"></most-used-product-chart>
  </div>
</template>

<script>
import MostUsedProductChartAccessNumberChart from "../../../charts/mostUsedProductChart";
export default {
  components: {
    "most-used-product-chart": MostUsedProductChartAccessNumberChart,
  },
  data() {
    return {
      data: [],
    };
  },
  watch: {
    filteredAecUsers: async function () {
      var filteredUsers = await this.filteredAecUsers.slice();
      this.data = [];
      this.categories = [];

      var accessList = await filteredUsers.reduce((acc, curr) => {
        return acc.concat(curr.aecCollectionAccess);
      }, []);

      //get accesses only for cloud products
      var cloudAccess = await accessList.filter((f) => f.version !== "cloud");

      Array.from(
        cloudAccess.reduce(
          (acc, cur) =>
            acc.set(cur.productName, (acc.get(cur.productName) || 0) + 1),
          new Map()
        )
      ).map(([product, access]) =>
        this.data.push({
          name: product,
          y: access,
        })
      );
    },
  },
  props: ["filteredAecUsers"],
};
</script>