<template>
  <div>
    <!-- BIM 360 Container component -->
    <div>
      <bim-360-container :companyData="companyData"></bim-360-container>
    </div>
    <!-- AEC Collection Container component -->
    <div>
      <aec-collection-conatainer
        :companyData="companyData"
      ></aec-collection-conatainer>
    </div>
    <!-- Perpetual Access Container component -->
    <div>
      <perpetual-access-container
        :companyData="companyData"
      ></perpetual-access-container>
    </div>
  </div>
</template>

<script>
import Bim360Container from "../components/bim360/bim360Container/index.vue";
import AecCollectionContainer from "../components/aecCollection/aecCollectionContainer/index.vue";
import PerpetualAccessContainer from "../components/perpetualAccess/perpetualAccessContainer/index.vue";

export default {
  components: {
    "bim-360-container": Bim360Container,
    "aec-collection-conatainer": AecCollectionContainer,
    "perpetual-access-container": PerpetualAccessContainer,
  },
  props: ["companyData"],
};
</script>