import {
    get as getBimUserApi
} from '../apiRequest';

/**
 * Retrieves BIM user information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which BIM user information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getBimUser = async (companyId) => {
    const apiResponse = await getBimUserApi('api/bimUser/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}