import {
    get as getBimProjectApi
} from '../apiRequest';

/**
 * Retrieves BIM project information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which BIM project information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getBimProject = async (companyId) => {
    const apiResponse = await getBimProjectApi('api/bimProject/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}