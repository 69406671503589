<template>
  <div>
    <access-streak-chart
      :data="data"
      :categories="categories"
    ></access-streak-chart>
  </div>
</template>

<script>
import { summary } from "date-streaks";
import AccessStreakChart from "../../../charts/accessStreakChart";

export default {
  components: {
    "access-streak-chart": AccessStreakChart,
  },
  data() {
    return {
      data: [],
      categories: [],
    };
  },
  watch: {
    filteredBimUsers: async function () {
      var filteredList = await JSON.parse(
        JSON.stringify(this.filteredBimUsers)
      );

      //sort
      await filteredList.sort((a, b) => {
        if (a.bimAccesses.length > b.bimAccesses.length) {
          return -1;
        } else {
          return 1;
        }
      });

      //calculate date streak for each user
      this.data = await filteredList.map((a) => {
        var access = this.filteredBimUsers.filter((c) => c.email == a.email)[0]
          .bimAccesses;

        var dates = access.reduce((acc, crr) => {
          acc.push(crr.accessDate);

          return acc;
        }, []);

        var dateStreak = summary({ dates });

        return dateStreak.longestStreak;
      });

      //sort DESC
      this.data.sort();
      this.data.reverse();

      //update chart xAxis
      this.categories = await filteredList.map((u) => u.email);
    },
  },
  props: ["filteredBimUsers"],
};
</script>