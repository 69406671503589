<template>
  <div v-if="showProjectUsersInfo" class="accordion mt-3 collapse-container" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.peoject-users-accordion class="collapse-button">
          {{ selectedProject }} (Usuários)
        </b-button>
      </b-card-header>
      <b-collapse
        id="peoject-users-accordion"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-table striped hover :items="items"> </b-table>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["showProjectUsersInfo", "selectedProject", "items"],
};
</script>