<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <b-form>
        <b-form-row>
          <b-col>
            <b-form-group
              id="perpetual-users-group"
              label="Usuário"
              label-for="inline-form-input-users"
            >
              <b-form-select
                id="perpetual-inline-form-input-users"
                v-model="filter.user"
                :options="users"
                @change="filterPerpetualAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <template #first>
                  <b-form-select-option :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              id="perpetual-products-group"
              label="Produto"
              label-for="inline-form-input-products"
            >
              <b-form-select
                id="perpetual-inline-form-input-products"
                v-model="filter.product"
                :options="products"
                @change="filterPerpetualAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <template #first>
                  <b-form-select-option :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-5"
              label="Quantidade de acessos (igual ou superior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de acessos"
                v-on:keyup.enter="filterPerpetualAccess"
                v-model="filter.accessNumber"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-6"
              label="Quantidade de acessos (igual ou inferior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de acessos igual ou inferior"
                v-on:keyup.enter="filterPerpetualAccess"
                v-model="filter.accessNumberLess"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-5"
              label="Quantidade de horas (igual ou superior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de horas"
                v-on:keyup.enter="filterPerpetualAccess"
                v-model="filter.accessHours"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-6"
              label="Quantidade de horas (igual ou inferior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de horas igual ou inferior"
                v-on:keyup.enter="filterPerpetualAccess"
                v-model="filter.accessHoursLess"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <div v-if="!isLoading">
          <b-button
            variant="success"
            class="float-right"
            @click="filterPerpetualAccess"
            >Pesquisar</b-button
          >

          <b-button class="float-right mr-2" @click="cleanFilter"
            >Limpar Filtros</b-button
          >
        </div>
      </b-form>
      <div>
        <b-alert class="p-1 col-md-6" show variant="light">
          Dados referentes ao período entre
          {{ formatDate(firstDateOfPeriod) }} e
          {{ formatDate(lastExecutionDate) }}
        </b-alert>

        <b-alert class="p-1 col-md-6" show variant="light">
          Ultima atualização:
          {{ formatDate(lastExecutionDate) }}
        </b-alert>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {
        user: undefined,
        product: undefined,
        accessNumber: "",
        accessNumberLess: "",
        accessHours: "",
        accessHoursLess: "",
      },
      perpetualAccesses: [],
      lastExecutionDate: new Date(),
      firstDateOfPeriod: new Date(),
    };
  },
  methods: {
    /**
     * Asynchronously emits an event to filter perpetual access data based on the provided filter object.
     */
    async filterPerpetualAccess() {
      this.$emit("filterPerpetualAccess", this.filter);
    },
    /**
     * Resets the filter object to its initial state and emits an event to trigger the filtering of perpetual access data.
     */
    cleanFilter() {
      this.filter = {
        user: undefined,
        product: undefined,
      };
      this.$emit("filterPerpetualAccess", this.filter);
    },
    /**
     * Formats the given date to 'DD/MM/YYYY' format.
     * @param {string|number|Date} dateToFormat - The date to be formatted.
     * @returns {string} - The formatted date string.
     */
    formatDate(dateToFormat) {
      var date = new Date(dateToFormat);
      return (
        date.getDate().toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear().toString().padStart(2, "0")
      );
    },
    /**
     * Checks if the given date is in the future compared to the current date.
     * @param {string} ymd - The date string in 'YYYY-MM-DD' format.
     * @param {Date} date - The date object to be checked.
     * @returns {boolean} - Returns true if the date is in the future, otherwise false.
     */
    limitFutureDays(ymd, date) {
      if (date > new Date()) {
        return true;
      }
    },
  },
  watch: {
    perpetualAccessesReportLog: async function () {
      if (this.perpetualAccessesReportLog) {
        //remove object reference
        let data = JSON.parse(JSON.stringify(this.perpetualAccessesReportLog));

        this.lastExecutionDate = data.sort((a, b) => {
          return (
            new Date(b.scriptExecutionDate) - new Date(a.scriptExecutionDate)
          );
        })[0].scriptExecutionDate;
      }
    },
    lastExecutionDate: async function () {
      if (this.lastExecutionDate) {
        let data = JSON.parse(JSON.stringify(this.lastExecutionDate));

        this.firstDateOfPeriod = new Date(data).setMonth(
          new Date(data).getMonth() - 2
        );
      }
    },
  },
  props: [
    "perpetualAccessesReportLog",
    "users",
    "products",
    "teams",
    "isLoading",
  ],
};
</script>