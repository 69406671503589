import feature from './data/feature'


//method to return the product description by code
export const getFeatureByCode = function (code) {

    //get map of autodesk products
    const featureData = feature.find(f => f.featureid == code);

    //verify if code exist
    //if exist return description of product code
    //else return default not found message 
    if (featureData) {
        return featureData.description;
    } else {
        return 'Descrição indisponível';
    }
};