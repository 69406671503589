<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <div class="d-flex flex justify-content-between">
        <div class="p-1">
          <b-alert class="p-1" show variant="light"
            >Total de licenças: {{ companyData.bimLicenses || " - " }}</b-alert
          >
          <b-alert class="p-1" show variant="light"
            >Licenças atribuídas:
            {{ totalActiveLicenses ? totalActiveLicenses + " - " : "" }}
            {{ licensePorcentage ? licensePorcentage + "%" : "" }}</b-alert
          >
        </div>

        <div class="p-1">
          <b-alert class="p-1" show variant="light"
            >Total de Licenças Autodesk Docs Build:
            {{ companyData.buildLicenses || " - " }}</b-alert
          >
          <b-alert class="p-1" show variant="light"
            >Licenças Autodesk Docs Build Atribuídas:
            {{
              bimAccessesReportLog.length
                ? bimAccessesReportLog.at(-1).totalFieldMenagementUsers + "-"
                : "-"
            }}
            {{ buildLicensePorcentage ? buildLicensePorcentage + "%" : "" }}
          </b-alert>
        </div>

        <div class="p-1">
          <b-alert class="p-1" v-if="filteredBimUsers.length" show variant="light"
            >Total de usuários ativos no período (Autodesk Docs e Autodesk Docs Build):
            {{ totalUsers }}</b-alert
          >
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalUsers: 0,
      licensePorcentage: "",
      buildLicensePorcentage: "",
      totalActiveLicenses: 0,
    };
  },
  props: [
    "companyData",
    "filteredBimUsers",
    "isLoading",
    "bimAccessesReportLog",
  ],
  methods: {
    getLicensePorcentage() {
      if (this.bimAccessesReportLog.length) {
        this.totalActiveLicenses =
          this.bimAccessesReportLog.at(-1).totalActiveUsers;

        //Autodesk Docs license
        this.licensePorcentage = (
          (this.totalActiveLicenses * 100) /
          this.companyData.bimLicenses
        ).toFixed(2);

        //Autodesk Docs build license
        if (this.bimAccessesReportLog.at(-1).totalFieldMenagementUsers)
          this.buildLicensePorcentage = (
            (this.bimAccessesReportLog.at(-1).totalFieldMenagementUsers * 100) /
            this.companyData.buildLicenses
          ).toFixed(2);
      }
    },
  },
  watch: {
    filteredBimUsers: async function () {
      //remove duplicates emails and get the length to define total of users
      var usersWithAccess = await this.filteredBimUsers.filter((u) => {
        return u.bimAccesses.length > 0;
      });

      this.totalUsers = usersWithAccess.length;
    },
  },
  updated() {
    this.getLicensePorcentage();
  },
};
</script>