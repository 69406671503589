<template>
  <div>
    <access-number-chart
      :data="data"
      :categories="categories"
    ></access-number-chart>
  </div>
</template>

<script>
import AccessNumberChart from "../../../charts/accessNumberChart";
export default {
  components: {
    "access-number-chart": AccessNumberChart,
  },
  data() {
    return {
      data: [],
      categories: [],
    };
  },
  watch: {
    filteredAecUsers: async function () {
      this.data = [];
      this.categories = [];

      //remove duplicates
      var filteredList = await JSON.parse(
        JSON.stringify(this.filteredAecUsers)
      );

      filteredList = await filteredList.filter((f) => {
        f.aecCollectionAccess = f.aecCollectionAccess.filter((ac) => {
          return ac.version != "cloud";
        });

        return f.aecCollectionAccess;
      });

      //sort
      await filteredList.sort((a, b) => {
        if (a.aecCollectionAccess.length > b.aecCollectionAccess.length) {
          return -1;
        } else {
          return 1;
        }
      });

      await filteredList.forEach((fl) => {
        this.data.push(fl.aecCollectionAccess.length);
        this.categories.push(fl.email);
      });
    },
  },
  props: ["filteredAecUsers"],
};
</script>