<template>
  <div>
    <b-tabs v-model="index">
      <div style="margin: 1em">
        <b-tab title="Autodesk Docs">
          <div v-if="index == 0">
            <bim-access :companyData="companyData" />
          </div>
        </b-tab>

        <b-tab title="AEC Collection">
          <div>
            <div v-if="index == 1">
              <aec-collection :companyData="companyData" />
            </div>
          </div>
        </b-tab>

        <b-tab title="IDS - Perpétua">
          <div>
            <div v-if="index == 2">
              <perpetual-access :companyData="companyData" />
            </div>
          </div>
        </b-tab>

        <b-tab title="Executivo">
          <div>
            <div v-if="index == 3">
              <executive :companyData="companyData" />
            </div>
          </div>
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import jwt_decode from "jwt-decode";
import BimAccess from "../components/bimAccess/view";
import AecCollection from "../components/aecCollection/view";
import PerpetualAccess from "../components/perpetualAccess/view";
import Executive from "../components/executive/view";
import { getCompanyInfo } from "../../../../services/companyService";

export default {
  data() {
    return {
      index: 0,
      companyData: {},
    };
  },
  methods: {
    /**
     * Asynchronously retrieves company data based on the user's token and sets the retrieved data to the component's companyData property.
     */
    async getCompanyData() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;

      this.companyData = await getCompanyInfo(companyId).then((result) => {
        return result.data;
      });
    },
  },
  components: {
    "bim-access": BimAccess,
    "aec-collection": AecCollection,
    "perpetual-access": PerpetualAccess,
    "executive": Executive
  },
  created() {
    this.getCompanyData();
  },
};
</script>