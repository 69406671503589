<template>
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <b-card class="mt-3">
        <bim-access-number-chart-container :filteredBimUsers="filteredBimUsers">
        </bim-access-number-chart-container>

        <bim-access-streak-chart-container :filteredBimUsers="filteredBimUsers">
        </bim-access-streak-chart-container>

        <div class="d-flex justify-content-center">
          <div>
            <bim-access-company-chart-container
              :filteredBimUsers="filteredBimUsers"
            >
            </bim-access-company-chart-container>
          </div>
          <div>
            <bim-users-per-company-chart-container
              :filteredBimUsers="filteredBimUsers"
            >
            </bim-users-per-company-chart-container>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <bim-users-per-project-chart-container
            :filteredBimUsers="filteredBimUsers"
          >
          </bim-users-per-project-chart-container>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import BimAccessNumberChartContainer from "../components/bimAccessNumberChartContainer";
import BimAccessStreakChartContainer from "../components/bimAccessStreakChartContainer";
import BimAccessByCompanyChartContainer from "../components/bimAccessByCompanyChartContainer";
import BimUsersPerCompanyChartContainer from "../components/bimUsersPerCompanyChartContainer";
import BimUsersPerProjectChartContainer from "../components/bimUsersPerProjectChartContainer";
export default {
  data() {
    return {
      totalUsers: 0,
      licensePorcentage: "",
    };
  },
  components: {
    "bim-access-number-chart-container": BimAccessNumberChartContainer,
    "bim-access-streak-chart-container": BimAccessStreakChartContainer,
    "bim-access-company-chart-container": BimAccessByCompanyChartContainer,
    "bim-users-per-company-chart-container": BimUsersPerCompanyChartContainer,
    "bim-users-per-project-chart-container": BimUsersPerProjectChartContainer,
  },
  props: ["companyData", "filteredBimUsers", "isLoading"],
  updated() {},
};
</script>