<template>
  <div>
    <users-project-chart :data="data"></users-project-chart>
  </div>
</template>

<script>
import UsersByProjectChart from "../../../charts/usersByProjectChart";
export default {
  components: {
    "users-project-chart": UsersByProjectChart,
  },
  data() {
    return {
      data: [],
    };
  },
  watch: {
    filteredBimUsers: async function () {
      //get all projects of all users
      var projects = await this.filteredBimUsers.reduce((acc, curr) => {
        return acc.concat(curr.bimProjects);
      }, []);

      var filteredList = await projects.filter(
        (v, i, a) => a.findIndex((t) => t.projectName === v.projectName) === i
      );

      //sort
      await filteredList.sort((a, b) => {
        if (
          projects.filter((d) => d.projectName == a.projectName).length >
          projects.filter((d) => d.projectName == b.projectName).length
        ) {
          return -1;
        } else {
          return 1;
        }
      });

      //update chart option
      this.data = await filteredList.map((a) => {
        var projectData = projects.filter((c) => c.projectName == a.projectName);

        return {
          name: a.projectName,
          y: projectData.length,
        };
      });
    },
  },
  props: ["filteredBimUsers"],
};
</script>