<template>
  <!-- Container for AEC Collection access filter form -->
  <div>
    <b-overlay
      :show="isLoading"
      variant="transparent"
      spinner-variant="warning"
      opacity="0.99"
    >
      <!-- AEC Collection access filter form -->
      <b-form @submit.stop.prevent>
        <!-- Row for input fields -->
        <b-form-row>
          <!-- Column for User select -->
          <b-col>
            <b-form-group
              id="aec-users-group"
              label="Usuário"
              label-for="inline-form-input-users"
            >
              <b-form-select
                id="aec-inline-form-input-users"
                v-model="filter.email"
                :options="users"
                @change="formFilterAecCollectionAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <!-- Default select option -->
                <template #first>
                  <b-form-select-option :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <!-- Column for Initial Date input -->
          <b-col>
            <b-form-group
              id="aec-initial-date-group"
              label="Data inicial"
              label-for="start-date"
            >
              <b-form-datepicker
                id="aec-start-date"
                placeholder="Data inicial"
                @input="formFilterAecCollectionAccess"
                v-model="filter.initialPeriod"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>

          <!-- Column for End Date input -->
          <b-col>
            <b-form-group
              id="aec-end-date-group"
              label="Data final"
              label-for="end-date"
            >
              <b-form-datepicker
                id="aec-end-date"
                :min="filter.initialPeriod"
                :date-disabled-fn="limitFutureDays"
                placeholder="Data final"
                @input="formFilterAecCollectionAccess"
                v-model="filter.endPeriod"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Row for additional input fields -->
        <b-form-row>
          <!-- Column for Team select -->
          <b-col>
            <b-form-group
              id="aec-teams-group"
              label="Time"
              label-for="inline-form-input-teams"
            >
              <b-form-select
                id="aec-inline-form-input-teams"
                v-model="filter.team"
                :options="teams"
                @change="formFilterAecCollectionAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <!-- Default select option -->
                <template #first>
                  <b-form-select-option :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          
          <!-- Column for Version select -->
          <b-col>
            <b-form-group
              id="aec-versions-group"
              label="Versão"
              label-for="inline-form-input-versions"
            >
              <b-form-select
                id="aec-inline-form-input-versions"
                v-model="filter.version"
                :options="versions"
                @change="formFilterAecCollectionAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <!-- Default select option -->
                <template #first>
                  <b-form-select-option :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="aec-products-group"
              label="Produto"
              label-for="inline-form-input-products"
            >
              <b-form-select
                multiple
                id="aec-inline-form-input-products"
                v-model="filter.product"
                :options="products"
                @change="formFilterAecCollectionAccess"
                class="mb-2 mr-sm-5 mb-sm-0"
              >
                <!-- Default select option -->
                <template #first>
                  <b-form-select-option disabled :value="undefined"
                    >Selecione...</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <!-- Row for access number input -->
        <b-form-row>
          <!-- Column for Access Number (Greater than or equal to) input -->
          <b-col>
            <b-form-group
              id="input-group-5"
              label="Quantidade de acessos (igual ou superior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de acessos"
                v-on:keyup.enter="formFilterAecCollectionAccess"
                v-model="filter.accessNumber"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          
          <!-- Column for Access Number (Less than or equal to) input -->
          <b-col>
            <b-form-group
              id="input-group-6"
              label="Quantidade de acessos (igual ou inferior)"
              label-for="inline-form-input-access"
              class="w-100"
            >
              <b-form-input
                placeholder="Quantidade de acessos igual ou inferior"
                v-on:keyup.enter="formFilterAecCollectionAccess"
                v-model="filter.accessNumberLess"
                type="number"
                min="0"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <!-- columns to adust the width first one -->
          <b-col> </b-col>
        </b-form-row>

        <!-- Buttons for search and filter reset -->
        <div v-if="!isLoading">
          <b-button
            variant="success"
            class="float-right"
            @click="formFilterAecCollectionAccess"
            >Pesquisar</b-button
          >

          <b-button class="float-right mr-2" @click="cleanFilter"
            >Limpar Filtros</b-button
          >
        </div>
      </b-form>

      <!-- Display first and last data collection dates -->
      <div>
        <b-alert class="p-1 col-md-6" show variant="light">
          Primeira coleta de dados em:
          {{ formatDate(firstExecutionDate.scriptExecutionDate) }}
        </b-alert>

        <b-alert class="p-1 col-md-6" show variant="light">
          Ultima atualização:
          {{ formatDate(lastExecutionDate.scriptExecutionDate) }}
        </b-alert>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Filter object to store form input values
      filter: {
        email: undefined,
        initialPeriod: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0],
        endPeriod: new Date().toISOString().split("T")[0],
        product: [],
        team: undefined,
        version: undefined,
        accessNumber: "",
        accessNumberLess: "",
      },
      // Arrays to store AEC Collection access data and other related information
      aecCollectionAccesses: [],
      lastExecutionDate: new Date(),
      firstExecutionDate: new Date(),
    };
  },
  // Props received from parent component
  props: [
    "aecCollectionAccessesReportLog",
    "isLoading",
    "users",
    "products",
    "teams",
    "versions",
  ],
  methods: {
    // Method to handle form submission and trigger filtering of AEC Collection access data
    async formFilterAecCollectionAccess() {
      this.$emit("filterAecCollectionAccess", this.filter);
    },
    /**
     * Formats the given date to 'DD/MM/YYYY' format.
     * @param {string|number|Date} dateToFormat - The date to be formatted.
     * @returns {string} - The formatted date string.
     */
    formatDate(dateToFormat) {
      var date = new Date(dateToFormat);
      return (
        date.getDate().toString().padStart(2, "0") +
        "/" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        date.getFullYear().toString().padStart(2, "0")
      );
    },
    /**
     * Resets the filter object to its initial state and emits an event to trigger the filtering of AEC Collection access data.
     */
    cleanFilter() {
      this.filter = {
        email: undefined,
        product: [],
        team: undefined,
        initialPeriod: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .split("T")[0],
        endPeriod: new Date().toISOString().split("T")[0],
        version: undefined,
        accessNumber: "",
        accessNumberLess: "",
      };
      this.$emit("filterAecCollectionAccess", this.filter);
    },
    /**
     * Checks if the given date is in the future compared to the current date.
     * @param {string} ymd - The date string in 'YYYY-MM-DD' format.
     * @param {Date} date - The date object to be checked.
     * @returns {boolean} - Returns true if the date is in the future, otherwise false.
     */
    limitFutureDays(ymd, date) {
      if (date > new Date()) {
        return true;
      }
    },
  },
  watch: {
    aecCollectionAccessesReportLog: async function () {
      var data = await JSON.parse(JSON.stringify(this.aecCollectionAccessesReportLog));

      this.lastExecutionDate = data.sort((a, b) => {
        return (
          new Date(b.scriptExecutionDate) - new Date(a.scriptExecutionDate)
        );
      })[0];

      this.firstExecutionDate = data.sort((a, b) => {
        return (
          new Date(a.scriptExecutionDate) - new Date(b.scriptExecutionDate)
        );
      })[0];
    },
  },
};
</script>