<template>
  <div>
    <!-- Highcharts component for displaying line chart -->
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Chart options for Highcharts
      chartOptions: {
        chart: {
          type: "line",
        },
        title: {
          text: "Licenças atribuídas (12 meses)",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "Licenças atribuídas",
          },
        },
        series: [
          {
            name: "Licenças atribuídas",
            data: [],
          },
        ],
      },
    };
  },
  props: ["data"],
  watch: {
    // Watch for changes in data prop and update chart data accordingly
    data: function () {
      // Update categories for X-axis based on data
      this.chartOptions.xAxis.categories = this.data.map((access) => {
        let date = new Date(access.scriptExecutionDate);
        return date.getFullYear().toString() + "/" + date.toLocaleString('default', { month: 'long' }).toString();
      });
      // Update series data for Y-axis based on data
      this.chartOptions.series[0].data = this.data.map(
        (access) => access.totalActiveUsers
      );
    },
  },
};
</script>