import {
    get as getPerpetualAccessApi,
} from '../apiRequest';

/**
 * Retrieves perpetual access information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which perpetual access information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getPerpetualAccess = async (companyId) => {
    const apiResponse = await getPerpetualAccessApi('api/perpetualAccess/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}

/**
 * Retrieves the report log for perpetual access of a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which the report log is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getPerpetualAccessReportLog = async (companyId) => {
    const apiResponse = await getPerpetualAccessApi('api/perpetualAccess/report/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}