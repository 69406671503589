import {
    get as getBimAccessApi
} from '../apiRequest';

/**
 * Retrieves BIM access information for a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which BIM access information is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getBimAccess = async (companyId) => {
    const apiResponse = await getBimAccessApi('api/bimAccess/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}

/**
 * Retrieves the report log for BIM access of a specific company by sending a GET request to the specified API endpoint.
 * @param {number} companyId - The ID of the company for which the report log is requested.
 * @returns {Promise} - A promise that resolves with the API response data.
 */
export const getBimAccessReportLog = async (companyId) => {
    const apiResponse = await getBimAccessApi('api/bimAccess/report/' + companyId).then(res => {
        return res;
    });

    return apiResponse;
}