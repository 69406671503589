var feature =
    [
        {
            featureid: "2D0",
            description: "PDS 2D PID"
        },
        {
            featureid: "2D1",
            description: "PDS 2D"
        },
        {
            featureid: "2D2",
            description: "PDS 2D Graphics Only"
        },
        {
            featureid: "2D3",
            description: "PDS IDM"
        },
        {
            featureid: "3D",
            description: "PDS 3D"
        },
        {
            featureid: "40100ARCHDESK_3_3F",
            description: "Architectural Desktop R3.3"
        },
        {
            featureid: "40200ACDLAND_3_0F",
            description: "Land Development Desktop R3"
        },
        {
            featureid: "40300MAP_5_0F",
            description: "Map R5"
        },
        {
            featureid: "40400AMECH_PP_6_0F",
            description: "AutoCAD Mechanical R6"
        },
        {
            featureid: "40500A-CIVDES_3_0F",
            description: "Civil Design R3"
        },
        {
            featureid: "40600A-SURV_3_0F",
            description: "Autodesk Survey R3"
        },
        {
            featureid: "40700CADOV_2002_0F",
            description: "CAD Overlay 2002"
        },
        {
            featureid: "408003DSMAX_4_3F",
            description: "Autodesk 3ds max R4.3"
        },
        {
            featureid: "40900INVBUN_5_0F",
            description: "Inventor Series 5"
        },
        {
            featureid: "40900MDT_PP_6_0F",
            description: "Mechanical Desktop R6"
        },
        {
            featureid: "41100ACD_2002_0F",
            description: "AutoCAD 2002"
        },
        {
            featureid: "41300BLDSYS_3_0F",
            description: "Building Systems R3+B38"
        },
        {
            featureid: "41400BLDGEL_1_0F",
            description: "Building Services Electrical R1 and R2"
        },
        {
            featureid: "41500BLDGME_1_0F",
            description: "Building Services Mechanical R1 and R2"
        },
        {
            featureid: "41700INVNTOR_5_3F",
            description: "Inventor 5.3"
        },
        {
            featureid: "41800DES_SER_7_0F",
            description: "Autodesk GIS Design Server R7"
        },
        {
            featureid: "41900ARDES_3_0F",
            description: "AutoCAD Raster Design R3"
        },
        {
            featureid: "42000GEN_UDS_2_0F",
            description: "Autodesk Utility Design R2"
        },
        {
            featureid: "421003DSMAX_5_0F",
            description: "Autodesk 3ds max R5"
        },
        {
            featureid: "42200CIVSER_3_0F",
            description: "Civil Series R3"
        },
        {
            featureid: "42300DES_SER_8_0F",
            description: "Autodesk GIS Design Server R8"
        },
        {
            featureid: "42400ARDES_2004_0F",
            description: "AutoCAD Raster Design 2004"
        },
        {
            featureid: "42500MAPSER_5_0F",
            description: "Map Series 5"
        },
        {
            featureid: "42600ACD_2004_0F",
            description: "AutoCAD 2004"
        },
        {
            featureid: "42700OS_AUTH_6_0F",
            description: "Onsite R6"
        },
        {
            featureid: "42800INVBUN_6_0F",
            description: "Inventor Series 6"
        },
        {
            featureid: "42900CIVSER_2004_0F",
            description: "Civil Series 2004"
        },
        {
            featureid: "43100MAP_6_0F",
            description: "Map R6"
        },
        {
            featureid: "43200ARCHDESK_2004_0F",
            description: "Architectural Desktop 2004 and Pre-Release"
        },
        {
            featureid: "43300ACDLAND_2004_0F",
            description: "Land Desktop 2004"
        },
        {
            featureid: "43400AMECH_PP_2004_0F",
            description: "AutoCAD Mechanical 2004"
        },
        {
            featureid: "43500INVBUN_7_0F",
            description: "Inventor Series 7"
        },
        {
            featureid: "43600MAP_2004_0F",
            description: "Map 2004"
        },
        {
            featureid: "43800OS_AUTH_7_0F",
            description: "Onsite R7"
        },
        {
            featureid: "43900MAPSER_6_0F",
            description: "Map Series 6"
        },
        {
            featureid: "44200ADTRPVS_10_1F",
            description: "CAiCE Visual Survey 10.1"
        },
        {
            featureid: "44200VISPE_10_0F",
            description: "44200VISPE_10_0F"
        },
        {
            featureid: "44300ADTRPVR_10_1F",
            description: "Visual Roads 10.1"
        },
        {
            featureid: "44300VISPE_10_0F",
            description: "44300VISPE_10_0F"
        },
        {
            featureid: "44400ADTRPVD_10_1F",
            description: "Visual Drainage 10.1"
        },
        {
            featureid: "44500ADTRPVC_10_1F",
            description: "Visual Construction 10.1"
        },
        {
            featureid: "44600ADTRPVSR_10_1F",
            description: "Visual Survey and Roads 10.1"
        },
        {
            featureid: "44600VISPE_10_0F",
            description: "44600VISPE_10_0F"
        },
        {
            featureid: "44700ADTRPVB_10_1F",
            description: "Visual Bridge 10.1"
        },
        {
            featureid: "44800ADTRPVL_10_1F",
            description: "Visual Landscape 10.1"
        },
        {
            featureid: "45000BLDSYS_2004_0F",
            description: "Building Systems 2004"
        },
        {
            featureid: "45100OS_AUTH_8_0F",
            description: "Envision R8"
        },
        {
            featureid: "45200MAPSER_2004_0F",
            description: "Map Series 2004"
        },
        {
            featureid: "45300A-SURV_2004_0F",
            description: "Autodesk Survey 2004"
        },
        {
            featureid: "45400A-CIVDES_2004_0F",
            description: "Civil Design 2004"
        },
        {
            featureid: "455003DSMAX_6_0F",
            description: "Autodesk 3ds max R6"
        },
        {
            featureid: "45600INVPRO_7_0F",
            description: "Inventor Professional 7"
        },
        {
            featureid: "45900AMECH_PP_2004DX_0F",
            description: "AutoCAD Mechanical 2004 DX"
        },
        {
            featureid: "46000INVPRO_8_0F",
            description: "Inventor Professional 8"
        },
        {
            featureid: "46100INVBUN_8_0F",
            description: "Inventor Series 8"
        },
        {
            featureid: "46300ACD_2005_0F",
            description: "AutoCAD 2005"
        },
        {
            featureid: "46400INVBUN_9_0F",
            description: "Inventor Series 9"
        },
        {
            featureid: "46500CIVSER_2005_0F",
            description: "Civil Series 2005"
        },
        {
            featureid: "46600MAP_2005_0F",
            description: "Map 3D 2005"
        },
        {
            featureid: "46700ARCHDESK_2005_0F",
            description: "Architectural Desktop 2005"
        },
        {
            featureid: "46800ACDLAND_2005_0F",
            description: "Land Desktop 2005"
        },
        {
            featureid: "46900AMECH_PP_2005_0F",
            description: "AutoCAD Mechanical 2005"
        },
        {
            featureid: "47000MAPSER_2005_0F",
            description: "Map Series 2005"
        },
        {
            featureid: "47100BLDSYS_2005_0F",
            description: "Building Systems 2005"
        },
        {
            featureid: "47200INVPRO_9_0F",
            description: "Inventor Professional 9"
        },
        {
            featureid: "47300ACAD_E_2004_0F",
            description: "AutoCAD Electrical 2004"
        },
        {
            featureid: "47400ACAD_E_2005_0F",
            description: "AutoCAD Electrical 2005"
        },
        {
            featureid: "47600REVITS_1_0F",
            description: "AutoCAD Revit Series R1"
        },
        {
            featureid: "477003DSMAX_7_0F",
            description: "Autodesk 3ds max R7"
        },
        {
            featureid: "47800VIZ_2005_0F",
            description: "VIZ 2005"
        },
        {
            featureid: "48000A-SURV_2005_0F",
            description: "Autodesk Survey 2005"
        },
        {
            featureid: "48100A-CIVDES_2005_0F",
            description: "Civil Design 2005"
        },
        {
            featureid: "48200ARDES_2005_0F",
            description: "AutoCAD Raster Design 2005"
        },
        {
            featureid: "48300CIV3D_2004_0F",
            description: "Autodesk Civil 3D 2004"
        },
        {
            featureid: "48400CIV3D_2005_0F",
            description: "Autodesk Civil 3D 2005"
        },
        {
            featureid: "48400REVITS_2_0F",
            description: "Revit 6.1"
        },
        {
            featureid: "48500REVITS_2_0F",
            description: "AutoCAD Revit Series R2"
        },
        {
            featureid: "48600GEN_UDS_2005_0F",
            description: "Autodesk Utility Design 2005"
        },
        {
            featureid: "48700REVIT_7_0F",
            description: "Revit 7.0"
        },
        {
            featureid: "48700VIZ_2006_0F",
            description: "VIZ 2006"
        },
        {
            featureid: "48800ACD_2006_0F",
            description: "AutoCAD 2006"
        },
        {
            featureid: "48900INVBUN_10_0F",
            description: "Inventor Series 10"
        },
        {
            featureid: "49000INVPRO_10_0F",
            description: "Inventor Professional 10"
        },
        {
            featureid: "49100ARCHDESK_2006_0F",
            description: "Architectural Desktop 2006"
        },
        {
            featureid: "49200ACDLAND_2006_0F",
            description: "Land Desktop 2006"
        },
        {
            featureid: "49300MAP_2006_0F",
            description: "AutoCAD Map 3D 2006"
        },
        {
            featureid: "49400AMECH_PP_2006_0F",
            description: "AutoCAD Mechanical 2006"
        },
        {
            featureid: "49500BLDSYS_2006_0F",
            description: "Building Systems 2006"
        },
        {
            featureid: "49600ACAD_E_2006_0F",
            description: "AutoCAD Electrical 2006"
        },
        {
            featureid: "49700CIV3D_2006_0F",
            description: "Autodesk Civil 3D 2006"
        },
        {
            featureid: "49800REVITS_7_0F",
            description: "AutoCAD Revit Series 7.0"
        },
        {
            featureid: "49900COMBUST_4_0F",
            description: "Combustion R4"
        },
        {
            featureid: "4D",
            description: "ISOGEN - Batch"
        },
        {
            featureid: "50100REVIT_8_0F",
            description: "Revit Building 8.0"
        },
        {
            featureid: "50200REVITST_1_0F",
            description: "Revit Structural 1.0"
        },
        {
            featureid: "50300REVITS_8_0F",
            description: "AutoCAD Revit Series 8.0"
        },
        {
            featureid: "50400A-SURV_2006_0F",
            description: "Autodesk Survey 2006"
        },
        {
            featureid: "50500ARDES_2006_0F",
            description: "AutoCAD Raster Design 2006"
        },
        {
            featureid: "506003DSMAX_8_0F",
            description: "Autodesk 3ds max R8"
        },
        {
            featureid: "50700REVITS_8_1F",
            description: "AutoCAD Revit Series 8.1"
        },
        {
            featureid: "50800UTLDESN_2006_0F",
            description: "Autodesk Utility Design 2006"
        },
        {
            featureid: "50900REVIT_8_1F",
            description: "Revit Building 8.1"
        },
        {
            featureid: "51000REVITST_2_0F",
            description: "Revit Structural 2.0"
        },
        {
            featureid: "51100REVITSYS_0_5F",
            description: "Revit Systems 1"
        },
        {
            featureid: "51200ACD_2007_0F",
            description: "AutoCAD 2007"
        },
        {
            featureid: "51300INVBUN_11_0F",
            description: "Inventor Series 11"
        },
        {
            featureid: "51400INVPRO_11_0F",
            description: "Inventor Professional 11"
        },
        {
            featureid: "51500ARCHDESK_2007_0F",
            description: "Architectural Desktop 2007"
        },
        {
            featureid: "51600ACDLAND_2007_0F",
            description: "Land Desktop 2007"
        },
        {
            featureid: "51700MAP_2007_0F",
            description: "AutoCAD Map 3D 2007"
        },
        {
            featureid: "51800BLDSYS_2007_0F",
            description: "Building Systems 2007"
        },
        {
            featureid: "51900AMECH_PP_2007_0F",
            description: "AutoCAD Mechanical 2007"
        },
        {
            featureid: "52100ACAD_E_2007_0F",
            description: "AutoCAD Electrical 2007"
        },
        {
            featureid: "52200CIV3D_2007_0F",
            description: "Autodesk Civil 3D 2007"
        },
        {
            featureid: "52300UTLDESN_2007_0F",
            description: "Autodesk Utility Design 2007"
        },
        {
            featureid: "52500REVITS_9_0F",
            description: "AutoCAD Revit Series Building 9"
        },
        {
            featureid: "52600REVIT_9_0F",
            description: "Revit Building 9"
        },
        {
            featureid: "52700A-SURV_2007_0F",
            description: "Autodesk Survey 2007"
        },
        {
            featureid: "52800ARDES_2007_0F",
            description: "AutoCAD Raster Design 2007"
        },
        {
            featureid: "52900INVPRORS_11_0F",
            description: "Inventor Professional 11 Routed Systems"
        },
        {
            featureid: "53000INVPROSIM_11_0F",
            description: "Inventor Professional 11 Simulation"
        },
        {
            featureid: "53100REVSYP_1_0F",
            description: "AutoCAD Revit Series - Systems Plus 1"
        },
        {
            featureid: "533003DSMAX_9_0F",
            description: "Autodesk 3ds max R9"
        },
        {
            featureid: "53400REVITST_3_0F",
            description: "Revit Structure 3"
        },
        {
            featureid: "53500VIZ_2007_0F",
            description: "VIZ 2007"
        },
        {
            featureid: "53700REVSU_3_0F",
            description: "AutoCAD Revit Series - Structure 3"
        },
        {
            featureid: "53900PNID_2007_0F",
            description: "AutoCAD P&ID 2007"
        },
        {
            featureid: "54100REVITS_9_1F",
            description: "AutoCAD Revit Series Building 9.1"
        },
        {
            featureid: "54200REVSU_4_0F",
            description: "AutoCAD Revit Series - Structure 4"
        },
        {
            featureid: "54300REVIT_9_1F",
            description: "Revit Building 9.1"
        },
        {
            featureid: "54500REVSYP_2_0F",
            description: "AutoCAD Revit Series Systems Plus 2"
        },
        {
            featureid: "54600ACD_2008_0F",
            description: "AutoCAD 2008"
        },
        {
            featureid: "54700REVITS_2008_0F",
            description: "AutoCAD Revit Architecture Suite 2008"
        },
        {
            featureid: "54800REVSU_2008_0F",
            description: "AutoCAD Revit Structure Suite 2008"
        },
        {
            featureid: "54900AMECH_PP_2008_0F",
            description: "AutoCAD Mechanical 2008"
        },
        {
            featureid: "55000INVBUN_2008_0F",
            description: "Autodesk Inventor Suite 2008"
        },
        {
            featureid: "55100INVPRORS_2008_0F",
            description: "Autodesk Inventor Routed Systems Suite 2008"
        },
        {
            featureid: "55200INVPROSIM_2008_0F",
            description: "Autodesk Inventor Simulation Suite 2008"
        },
        {
            featureid: "55300INVPRO_2008_0F",
            description: "Inventor Professional 2008"
        },
        {
            featureid: "55400CIV3D_2008_0F",
            description: "AutoCAD Civil 3D 2008"
        },
        {
            featureid: "55500ACDLAND_2008_0F",
            description: "AutoCAD Land Desktop 2008"
        },
        {
            featureid: "55600BLDSYS_2008_0F",
            description: "AutoCAD MEP 2008"
        },
        {
            featureid: "55700REVSYP_2008_0F",
            description: "AutoCAD Revit MEP Suite 2008"
        },
        {
            featureid: "55800ARCHDESK_2008_0F",
            description: "AutoCAD Architecture 2008"
        },
        {
            featureid: "55900MAP_2008_0F",
            description: "AutoCAD Map 3D 2008"
        },
        {
            featureid: "56000ACAD_E_2008_0F",
            description: "AutoCAD Electrical 2008"
        },
        {
            featureid: "56100UTLDESN_2008_0F",
            description: "AutoCAD Utility Design 2008"
        },
        {
            featureid: "56200PNID_2008_0F",
            description: "AutoCAD P&ID 2008"
        },
        {
            featureid: "568003DSMAX_2008_0F",
            description: "Autodesk 3ds Max 2008"
        },
        {
            featureid: "56900VIZ_2008_0F",
            description: "VIZ 2008"
        },
        {
            featureid: "57000ARDES_2008_0F",
            description: "AutoCAD Raster Design 2008"
        },
        {
            featureid: "57100REVIT_2008_0F",
            description: "Revit Architecture 2008"
        },
        {
            featureid: "57200REVITST_2008_0F",
            description: "Revit Structure 2008"
        },
        {
            featureid: "57600ACD_2009_0F",
            description: "AutoCAD 2009"
        },
        {
            featureid: "5810IDSU_T_F",
            description: "Autodesk Infrastructure Design Suite Ultimate Package Term"
        },
        {
            featureid: "59200AMECH_PP_2009_0F",
            description: "AutoCAD Mechanical 2009"
        },
        {
            featureid: "59300INVBUN_2009_0F",
            description: "Autodesk Inventor Suite 2009"
        },
        {
            featureid: "59400INVPRORS_2009_0F",
            description: "Autodesk Inventor Routed Systems Suite 2009"
        },
        {
            featureid: "59500INVPROSIM_2009_0F",
            description: "Autodesk Inventor Simulation Suite 2009"
        },
        {
            featureid: "59600INVPRO_2009_0F",
            description: "Inventor Professional 2009"
        },
        {
            featureid: "59700CIV3D_2009_0F",
            description: "AutoCAD Civil 3D 2009"
        },
        {
            featureid: "59800ACDLAND_2009_0F",
            description: "AutoCAD Land Desktop 2009"
        },
        {
            featureid: "59900BLDSYS_2009_0F",
            description: "AutoCAD MEP 2009"
        },
        {
            featureid: "5D",
            description: "ISOGEN - Interactive"
        },
        {
            featureid: "60000REVSYP_2009_0F",
            description: "AutoCAD Revit MEP Suite 2009"
        },
        {
            featureid: "60100ARCHDESK_2009_0F",
            description: "AutoCAD Architecture 2009"
        },
        {
            featureid: "60200MAP_2009_0F",
            description: "AutoCAD Map 3D 2009"
        },
        {
            featureid: "60300ACAD_E_2009_0F",
            description: "AutoCAD Electrical 2009"
        },
        {
            featureid: "60400UTLDESN_2009_0F",
            description: "Autodesk Utility Design 2009"
        },
        {
            featureid: "60500PNID_2009_0F",
            description: "AutoCAD P&ID 2009"
        },
        {
            featureid: "60800COMBUST_2008_0F",
            description: "Autodesk Combustion"
        },
        {
            featureid: "60900REVITS_2009_0F",
            description: "AutoCAD Revit Architecture Suite 2009"
        },
        {
            featureid: "61000REVSU_2009_0F",
            description: "AutoCAD Revit Structure Suite 2009"
        },
        {
            featureid: "61100INVLT_2009_0F",
            description: "Autodesk Inventor LT 2009"
        },
        {
            featureid: "612003DSMAX_2009_0F",
            description: "Autodesk 3ds Max 2009"
        },
        {
            featureid: "61300ARDES_2009_0F",
            description: "AutoCAD Raster Design 2009"
        },
        {
            featureid: "61400MAPGE_2009_0F",
            description: "Autodesk MapGuide Enterprise 2009"
        },
        {
            featureid: "61500PLNT3D_2009_0F",
            description: "AutoCAD Plant 3D 2009"
        },
        {
            featureid: "61700INVMFG_2009_0F",
            description: "AutoCAD Inventor Tooling Suite 2009"
        },
        {
            featureid: "62100REVIT_2009_0F",
            description: "Revit Architecture 2009"
        },
        {
            featureid: "62200REVITST_2009_0F",
            description: "Revit Structure 2009"
        },
        {
            featureid: "62700NAVREV_2009_0F",
            description: "Autodesk NavisWorks Review 2009"
        },
        {
            featureid: "62800NAVSIM_2009_0F",
            description: "Autodesk NavisWorks Simulate 2009"
        },
        {
            featureid: "62900NAVMAN_2009_0F",
            description: "Autodesk NavisWorks Manage 2009"
        },
        {
            featureid: "64300ACD_F",
            description: "AutoCAD Package"
        },
        {
            featureid: "64300ACD_T_F",
            description: "Autodesk AutoCAD Package Term"
        },
        {
            featureid: "64400AMECH_PP_F",
            description: "AutoCAD Mechanical Package"
        },
        {
            featureid: "64400AMECH_PP_T_F",
            description: "Autodesk AutoCAD Mechanical Package Term"
        },
        {
            featureid: "64500INVBUN_F",
            description: "Inventor Series Package"
        },
        {
            featureid: "64600INVPRORS_F",
            description: "Autodesk Inventor Routed Systems Suite Package"
        },
        {
            featureid: "64700INVPROSIM_F",
            description: "Autodesk Inventor Simulation Suite Package"
        },
        {
            featureid: "64800INVPRO_F",
            description: "Inventor Professional Package"
        },
        {
            featureid: "64801INVPROSA_F",
            description: "Autodesk Inventor Professional Package"
        },
        {
            featureid: "64801INVPROSA_T_F",
            description: "Autodesk Inventor Professional Package Term"
        },
        {
            featureid: "64900CIV3D_F",
            description: "Autodesk Civil 3D Package"
        },
        {
            featureid: "64900CIV3D_T_F",
            description: "Autodesk AutoCAD Civil 3D Package Term"
        },
        {
            featureid: "65000ACDLAND_F",
            description: "AutoCAD Land Desktop Package"
        },
        {
            featureid: "65100BLDSYS_F",
            description: "AutoCAD MEP Package"
        },
        {
            featureid: "65100BLDSYS_T_F",
            description: "Autodesk AutoCAD MEP Package Term"
        },
        {
            featureid: "65200REVSYP_F",
            description: "AutoCAD Revit MEP Suite Package"
        },
        {
            featureid: "65300ARCHDESK_F",
            description: "AutoCAD Architecture Package"
        },
        {
            featureid: "65300ARCHDESK_T_F",
            description: "Autodesk AutoCAD Architecture Package Term"
        },
        {
            featureid: "65400MAP_F",
            description: "AutoCAD Map 3D Package"
        },
        {
            featureid: "65400MAP_T_F",
            description: "Autodesk AutoCAD Map 3D Package Term"
        },
        {
            featureid: "65500ACAD_E_F",
            description: "AutoCAD Electrical Package"
        },
        {
            featureid: "65500ACAD_E_T_F",
            description: "Autodesk AutoCAD Electrical Package Term"
        },
        {
            featureid: "65600UTLDESN_F",
            description: "AutoCAD Utility Design Package"
        },
        {
            featureid: "65600UTLDESN_T_F",
            description: "Autodesk AutoCAD Utility Design Package Term"
        },
        {
            featureid: "65700PLNT3D_F",
            description: "AutoCAD Plant 3D Package"
        },
        {
            featureid: "65700PLNT3D_T_F",
            description: "Autodesk AutoCAD Plant 3D Package Term"
        },
        {
            featureid: "65800TOPOBSCLNT_F",
            description: "Autodesk Topobase Client Package"
        },
        {
            featureid: "65900REVITS_F",
            description: "AutoCAD Revit Architecture Suite Package"
        },
        {
            featureid: "66000REVSU_F",
            description: "AutoCAD Revit Series - Structure Package"
        },
        {
            featureid: "66100INVLT_F",
            description: "Autodesk Inventor LT Package"
        },
        {
            featureid: "66100INVLT_T_F",
            description: "Autodesk Inventor LT Package Term"
        },
        {
            featureid: "662003DSMAX_F",
            description: "Autodesk 3ds Max Package"
        },
        {
            featureid: "662003DSMAX_T_F",
            description: "Autodesk 3ds Max Package Term"
        },
        {
            featureid: "6624MBXPRO_2017_0F",
            description: "Autodesk Mudbox 2017"
        },
        {
            featureid: "66300ARDES_F",
            description: "AutoCAD Raster Design Package"
        },
        {
            featureid: "66300ARDES_T_F",
            description: "Autodesk AutoCAD Raster Design Package Term"
        },
        {
            featureid: "66400MAPGE_F",
            description: "Autodesk MapGuide Enterprise Package"
        },
        {
            featureid: "66500PCPRO_F",
            description: "Autodesk Productstream Professional Package"
        },
        {
            featureid: "66600PCEASY_F",
            description: "Autodesk Productstream Professional Easy Package"
        },
        {
            featureid: "66700PCOFFI_F",
            description: "Autodesk 360 Vault Office Package"
        },
        {
            featureid: "66700PCOFFI_T_F",
            description: "Autodesk Vault Office Package Term"
        },
        {
            featureid: "66800REVIT_F",
            description: "Revit Architecture Package"
        },
        {
            featureid: "66900REVITST_F",
            description: "Autodesk Revit Structure Package"
        },
        {
            featureid: "67000MAYACP_F",
            description: "Autodesk Maya Complete Package"
        },
        {
            featureid: "67100MAYAUL_F",
            description: "Autodesk Maya Unlimited Package"
        },
        {
            featureid: "67200TOXIK_F",
            description: "Autodesk Toxik Package"
        },
        {
            featureid: "67300TOPOBSWEB_F",
            description: "Autodesk Topobase Web Package"
        },
        {
            featureid: "67400NAVREV_F",
            description: "Autodesk NavisWorks Review Package"
        },
        {
            featureid: "67500NAVSIM_F",
            description: "Autodesk NavisWorks Simulate Package"
        },
        {
            featureid: "67600NAVMAN_F",
            description: "Autodesk Navisworks Manage Package"
        },
        {
            featureid: "67600NAVMAN_T_F",
            description: "Autodesk Navisworks Manage Package Term"
        },
        {
            featureid: "67700PNID_F",
            description: "AutoCAD P&ID Package"
        },
        {
            featureid: "67700PNID_T_F",
            description: "Autodesk AutoCAD P&ID Package Term"
        },
        {
            featureid: "67800RVTMPJ_2009_0F",
            description: "Autodesk Revit MEP 2009"
        },
        {
            featureid: "68300T1MF_2_0F",
            description: "Package: T1 Enterprise Multi-Flex 2"
        },
        {
            featureid: "68600AQTO_2010_0F",
            description: "Autodesk Quantity Takeoff 2010"
        },
        {
            featureid: "68800CIVIL_2009_0F",
            description: "AutoCAD Civil 2009"
        },
        {
            featureid: "68900NAVREV_2009_1F",
            description: "Autodesk NavisWorks Review 2009.1"
        },
        {
            featureid: "68900NAVREV_2009_1P",
            description: "Autodesk NavisWorks Review 2009.1 Pre-Release"
        },
        {
            featureid: "69000NAVSIM_2009_1F",
            description: "Autodesk NavisWorks Simulate 2009.1"
        },
        {
            featureid: "69000NAVSIM_2009_1P",
            description: "Autodesk NavisWorks Simulate 2009.1 Pre-Release"
        },
        {
            featureid: "69100NAVMAN_2009_1F",
            description: "Autodesk NavisWorks Manage 2009.1"
        },
        {
            featureid: "69100NAVMAN_2009_1P",
            description: "Autodesk NavisWorks Manage 2009.1 Pre-Release"
        },
        {
            featureid: "69200CIVIL_F",
            description: "AutoCAD Civil Package"
        },
        {
            featureid: "69300RSA_2009_0F",
            description: "Autodesk Robot Structural Analysis 2009"
        },
        {
            featureid: "69400RSAPRO_2009_0F",
            description: "Autodesk Robot Structural Analysis Professional 2009"
        },
        {
            featureid: "69500RSA_F",
            description: "Autodesk Robot Structural Analysis Package"
        },
        {
            featureid: "69600RSAPRO_F",
            description: "Autodesk Robot Structural Analysis Professional Package"
        },
        {
            featureid: "69600RSAPRO_T_F",
            description: "Autodesk Robot Structural Analysis Professional Package Term"
        },
        {
            featureid: "69800MB_F",
            description: "Autodesk MotionBuilder Package"
        },
        {
            featureid: "69800MB_T_F",
            description: "Autodesk MotionBuilder Term"
        },
        {
            featureid: "699003DSMAX_2010_0F",
            description: "Autodesk 3ds Max 2010"
        },
        {
            featureid: "70000MAXDES_2010_0F",
            description: "Autodesk 3ds Max Design 2010"
        },
        {
            featureid: "70100MAXDES_F",
            description: "Autodesk 3ds Max Design Package"
        },
        {
            featureid: "70200MBXPRO_2009_0F",
            description: "Autodesk Mudbox 2009"
        },
        {
            featureid: "70300MBXPRO_F",
            description: "Autodesk Mudbox Package"
        },
        {
            featureid: "70300MBXPRO_T_F",
            description: "Autodesk Mudbox Term"
        },
        {
            featureid: "70400INVBUN_2010_0F",
            description: "AutoCAD Inventor Suite 2010"
        },
        {
            featureid: "70500INVPRO_2010_0F",
            description: "AutoCAD Inventor Professional Suite 2010"
        },
        {
            featureid: "70600INVPRORS_2010_0F",
            description: "AutoCAD Inventor Routed Systems Suite 2010"
        },
        {
            featureid: "70700INVPROSIM_2010_0F",
            description: "AutoCAD Inventor Simulation Suite 2010"
        },
        {
            featureid: "70800VLTM_2010_0F",
            description: "Autodesk Vault Manufacturing 2010"
        },
        {
            featureid: "71200ACD_2010_0F",
            description: "AutoCAD 2010"
        },
        {
            featureid: "71400NAVMAN_2010_0F",
            description: "Autodesk Navisworks Manage 2010"
        },
        {
            featureid: "71500NAVSIM_2010_0F",
            description: "Autodesk Navisworks Simulate 2010"
        },
        {
            featureid: "71600NAVREV_2010_0F",
            description: "Autodesk Navisworks Review 2010"
        },
        {
            featureid: "71700ACAD_E_2010_0F",
            description: "AutoCAD Electrical 2010"
        },
        {
            featureid: "71800AMECH_PP_2010_0F",
            description: "AutoCAD Mechanical 2010"
        },
        {
            featureid: "71900PCEASY_2010_0F",
            description: "Autodesk Productstream Professional Easy 2011"
        },
        {
            featureid: "72000PCOFFI_2010_0F",
            description: "Autodesk Productstream Professional 2010 Office"
        },
        {
            featureid: "72100PCPRO_2010_0F",
            description: "Autodesk Productstream Professional 2011"
        },
        {
            featureid: "72200ARCHDESK_2010_0F",
            description: "AutoCAD Architecture 2010"
        },
        {
            featureid: "72300PLNT3D_2010_0F",
            description: "AutoCAD Plant 3D 2010"
        },
        {
            featureid: "72400VLTM_F",
            description: "Vault Professional Package"
        },
        {
            featureid: "72400VLTM_T_F",
            description: "Autodesk Vault Professional Package Term"
        },
        {
            featureid: "72500MAP_2010_0F",
            description: "AutoCAD Map 3D 2010"
        },
        {
            featureid: "72600ARDES_2010_0F",
            description: "AutoCAD Raster Design 2010"
        },
        {
            featureid: "72700MAPGE_2010_0F",
            description: "Autodesk MapGuide Enterprise 2010"
        },
        {
            featureid: "72800TOPOBSCLNT_2010_0F",
            description: "Autodesk Topobase Client 2010"
        },
        {
            featureid: "72900TOPOBSWEB_2010_0F",
            description: "Autodesk Topobase Web 2010"
        },
        {
            featureid: "73000BLDSYS_2010_0F",
            description: "AutoCAD MEP 2010"
        },
        {
            featureid: "73100REVSYP_2010_0F",
            description: "AutoCAD Revit MEP Suite 2010"
        },
        {
            featureid: "73200INVMFG_2010_0F",
            description: "AutoCAD Inventor Tooling Suite 2010"
        },
        {
            featureid: "73400PNID_2010_0F",
            description: "Autodesk Plant P&ID 2010"
        },
        {
            featureid: "73500UTLDESN_2010_0F",
            description: "Autodesk Utility Design 2010"
        },
        {
            featureid: "73700CIV3D_2010_0F",
            description: "AutoCAD Civil 3D 2010"
        },
        {
            featureid: "73800RVTMPJ_2010_0F",
            description: "Autodesk Revit MEP 2010"
        },
        {
            featureid: "73900REVITST_2010_0F",
            description: "Autodesk Revit Structure 2010"
        },
        {
            featureid: "74000REVSU_2010_0F",
            description: "AutoCAD Revit Structure Suite 2010"
        },
        {
            featureid: "74100REVIT_2010_0F",
            description: "Autodesk Revit Architecture 2010"
        },
        {
            featureid: "74200REVITS_2010_0F",
            description: "AutoCAD Revit Architecture Suite 2010"
        },
        {
            featureid: "74300CIVIL_2010_0F",
            description: "AutoCAD Civil 2010"
        },
        {
            featureid: "74400INVAS_2010_0F",
            description: "Autodesk Inventor Automation Professional 2010"
        },
        {
            featureid: "74500VLTC_2010_0F",
            description: "Autodesk Vault Collaboration 2010"
        },
        {
            featureid: "74600VLTC_F",
            description: "Autodesk 360 Vault Collaboration Package"
        },
        {
            featureid: "74700VLTWG_2010_0F",
            description: "Autodesk Vault Workgroup 2010"
        },
        {
            featureid: "74800VLTWG_F",
            description: "Autodesk 360 Vault Workgroup Package"
        },
        {
            featureid: "74800VLTWG_T_F",
            description: "Autodesk Vault Workgroup Package Term"
        },
        {
            featureid: "74900RVTMPJ_F",
            description: "Autodesk Revit MEP Package"
        },
        {
            featureid: "75100TOXIK_2010_0F",
            description: "Autodesk Toxik 2010"
        },
        {
            featureid: "75200RSA_2010_0F",
            description: "Autodesk Robot Structural Analysis 2010"
        },
        {
            featureid: "75300RSAPRO_2010_0F",
            description: "Autodesk Robot Structural Analysis Professional 2010"
        },
        {
            featureid: "75400ECOTECT_2009_0F",
            description: "Autodesk Ecotect 2009"
        },
        {
            featureid: "75500ECSCAD_F",
            description: "AutoCAD ecscad Package"
        },
        {
            featureid: "75600RSTR_2010_0F",
            description: "AutoCAD Raster 2010"
        },
        {
            featureid: "75700MFDLC_2010_0F",
            description: "Autodesk Moldflow Design Link for CATIA V5 2010"
        },
        {
            featureid: "75800MFDLC_F",
            description: "Autodesk Moldflow Design Link for CATIA Package"
        },
        {
            featureid: "75900MFDLPE_2010_0F",
            description: "Autodesk Moldflow Design Link for Pro/ENGINEER 2010"
        },
        {
            featureid: "76000MFDLPE_F",
            description: "Autodesk Moldflow Design Link for Pro/ENGINEER Package"
        },
        {
            featureid: "76100MFDLP_2010_0F",
            description: "Autodesk Moldflow Design Link for Parasolid 2010"
        },
        {
            featureid: "76200MFDLP_F",
            description: "Autodesk Moldflow Design Link for Parasolid Package"
        },
        {
            featureid: "76300MFAD_2010_0F",
            description: "Autodesk Moldflow Adviser Design 2010"
        },
        {
            featureid: "76400MFAD_F",
            description: "Autodesk Simulation Moldflow Adviser Standard Package"
        },
        {
            featureid: "76400MFAD_T_F",
            description: "Autodesk Moldflow Adviser Standard Package Term"
        },
        {
            featureid: "76500MFAM_2010_0F",
            description: "Autodesk Moldflow Adviser Manufacturing 2010"
        },
        {
            featureid: "76600MFAM_F",
            description: "Autodesk Simulation Moldflow Adviser Premium Package"
        },
        {
            featureid: "76600MFAM_T_F",
            description: "Autodesk Moldflow Adviser Premium Package Term"
        },
        {
            featureid: "76700MFAA_2010_0F",
            description: "Autodesk Moldflow Adviser Advanced 2010"
        },
        {
            featureid: "76800MFAA_F",
            description: "Autodesk Simulation Moldflow Adviser Ultimate Package"
        },
        {
            featureid: "76800MFAA_T_F",
            description: "Autodesk Moldflow Adviser Ultimate Package Term"
        },
        {
            featureid: "76900MFIB_2010_0F",
            description: "Autodesk Moldflow Insight Basic 2010"
        },
        {
            featureid: "77000MFIB_F",
            description: "Autodesk Moldflow Insight Basic Package"
        },
        {
            featureid: "77100MFIP_2010_0F",
            description: "Autodesk Moldflow Insight Performance 2010"
        },
        {
            featureid: "77200MFIP_F",
            description: "Autodesk Moldflow Insight Performance Package"
        },
        {
            featureid: "77300MFIA_2010_0F",
            description: "Autodesk Moldflow Insight Advanced 2010"
        },
        {
            featureid: "77400MFIA_F",
            description: "Autodesk Simulation Moldflow Insight Ultimate Package"
        },
        {
            featureid: "77400MFIA_T_F",
            description: "Autodesk Moldflow Insight Ultimate Package Term"
        },
        {
            featureid: "77500MFCD_2010_0F",
            description: "Autodesk Moldflow CAD Doctor 2010"
        },
        {
            featureid: "77600MFCD_F",
            description: "Autodesk Simulation Moldflow CAD Doctor Package"
        },
        {
            featureid: "77600MFCD_T_F",
            description: "CADdoctor for Autodesk Simulation Package Term"
        },
        {
            featureid: "77700MFS_2010_0F",
            description: "Autodesk Moldflow Synergy 2010"
        },
        {
            featureid: "77800MFS_F",
            description: "Autodesk Simulation Moldflow Synergy Package"
        },
        {
            featureid: "77800MFS_T_F",
            description: "Autodesk Moldflow Synergy Package Term"
        },
        {
            featureid: "77900RSTR_F",
            description: "AutoCAD Raster Package"
        },
        {
            featureid: "78000ECOA_2010_0F",
            description: "Autodesk Ecotect Analysis 2010"
        },
        {
            featureid: "78100ECOA_F",
            description: "Autodesk Ecotect Analysis Package"
        },
        {
            featureid: "78200RAV-S_2009_0F",
            description: "AutoCAD Revit Architecture Visualization Suite 2009"
        },
        {
            featureid: "78300RAV-S_F",
            description: "AutoCAD Revit Architecture Visualization Suite Package"
        },
        {
            featureid: "78500ACDDS_F",
            description: "AutoCAD Visualization Suite Package"
        },
        {
            featureid: "78600SFTIM_7_5F",
            description: "Autodesk Softimage 7.5"
        },
        {
            featureid: "78700SFTIM_F",
            description: "Autodesk Softimage Package"
        },
        {
            featureid: "78800SFTIMA_7_5F",
            description: "Autodesk Softimage Advanced 7.5"
        },
        {
            featureid: "78800SFTIMASIB1_7_5F",
            description: "Autodesk Softimage Advanced 7.5 SIBatch1"
        },
        {
            featureid: "78800SFTIMASIB2_7_5F",
            description: "Autodesk Softimage Advanced 7.5 SIBatch2"
        },
        {
            featureid: "78800SFTIMASIB3_7_5F",
            description: "Autodesk Softimage Advanced 7.5 SIBatch3"
        },
        {
            featureid: "78800SFTIMASIB4_7_5F",
            description: "Autodesk Softimage Advanced 7.5 SIBatch4"
        },
        {
            featureid: "78800SFTIMASIB5_7_5F",
            description: "Autodesk Softimage Advanced 7.5 SIBatch5"
        },
        {
            featureid: "78900SFTIMA_F",
            description: "Autodesk Softimage Advanced Package"
        },
        {
            featureid: "79000SFTIMASIB1_F",
            description: "Autodesk Softimage SIBatch1 Package"
        },
        {
            featureid: "79100SFTIMASIB1_F",
            description: "Autodesk Softimage SIBatch1 Package"
        },
        {
            featureid: "79100SFTIMASIB2_F",
            description: "Autodesk Softimage SIBatch2 Package"
        },
        {
            featureid: "79200SFTIMASIB3_F",
            description: "Autodesk Softimage SIBatch3 Package"
        },
        {
            featureid: "79300SFTIMASIB4_F",
            description: "Autodesk Softimage SIBatch4 Package"
        },
        {
            featureid: "79400SFTIMASIB5_F",
            description: "Autodesk Softimage SIBatch5 Package"
        },
        {
            featureid: "79500MOBPRO_2010_0F",
            description: "Autodesk MotionBuilder 2010"
        },
        {
            featureid: "79600EMS_2010_0F",
            description: "Autodesk Education Master Suite 2010"
        },
        {
            featureid: "79700EMS_F",
            description: "Autodesk Education Master Suite Package"
        },
        {
            featureid: "79800ESAE_2010_0F",
            description: "Autodesk Education Suite for Architecture and Engineering 2010"
        },
        {
            featureid: "79900ESAE_F",
            description: "Autodesk Education Suite for Architecture and Engineering Package"
        },
        {
            featureid: "80000ACDDS_2010_0F",
            description: "AutoCAD Visualization Suite 2010"
        },
        {
            featureid: "80100ACDDS_F",
            description: "AutoCAD Visualization Suite Package"
        },
        {
            featureid: "80200DES_ACA_2010_0F",
            description: "Autodesk Design Academy 2010"
        },
        {
            featureid: "80300DES_ACA_F",
            description: "Autodesk Design Academy Package"
        },
        {
            featureid: "80400ESID_2010_0F",
            description: "Autodesk Education Suite for Industrial Design 2010"
        },
        {
            featureid: "80500ESID_F",
            description: "Autodesk Education Suite for Industrial Design Package"
        },
        {
            featureid: "80700ESME_2010_0F",
            description: "Autodesk Education Suite for Mechanical Engineering 2010"
        },
        {
            featureid: "80800ESME_F",
            description: "Autodesk Product Design Suite for Education Package"
        },
        {
            featureid: "80900ESCSE_2010_0F",
            description: "Autodesk Education Suite for Civil and Structural Engineering 2010"
        },
        {
            featureid: "81000ESCSE_F",
            description: "Autodesk Education Suite for Civil and Structural Engineering Package"
        },
        {
            featureid: "81200ESSISD_F",
            description: "Education Solution Set Civil & Geospatial Package"
        },
        {
            featureid: "81400ESSMFG_F",
            description: "Education Solution Set Mechanical Engineering Package"
        },
        {
            featureid: "81500RAVS_2010_0F",
            description: "AutoCAD Revit Architecture Visualization Suite 2010"
        },
        {
            featureid: "81600RAVS_F",
            description: "AutoCAD Revit Architecture Visualization Suite Package"
        },
        {
            featureid: "81700MAYACP_2010_0F",
            description: "Autodesk Maya Complete 2010"
        },
        {
            featureid: "81700MAYACPMR_2010_0F",
            description: "Autodesk Maya Complete 2010 Mental Ray"
        },
        {
            featureid: "81800MAYAUL_2010_0F",
            description: "Autodesk Maya Unlimited 2010"
        },
        {
            featureid: "81800MAYAULFS_2010_0F",
            description: "Autodesk Maya Unlimited 2010 Fluid Simulation"
        },
        {
            featureid: "81800MAYAULMR_2010_0F",
            description: "Autodesk Maya Unlimited 2010 Mental Ray"
        },
        {
            featureid: "81900ACDLTC_F",
            description: "AutoCAD LT Civil Suite Package"
        },
        {
            featureid: "81900ACDLTC_T_F",
            description: "Autodesk AutoCAD LT Civil Suite Package Term"
        },
        {
            featureid: "82000BDFBS_2010_0F",
            description: "Autodesk Building Fabrication Suite 2010"
        },
        {
            featureid: "82100BDFBS_F",
            description: "Autodesk Building Fabrication Suite Package"
        },
        {
            featureid: "82200STRDET_2010_0F",
            description: "AutoCAD Structural Detailing 2010"
        },
        {
            featureid: "82300STRDET_F",
            description: "AutoCAD Structural Detailing Package"
        },
        {
            featureid: "82400RVTMPB_2010_0F",
            description: "Autodesk Revit MEP-B 2010"
        },
        {
            featureid: "82500RVTMPB_F",
            description: "Autodesk Revit MEP-B Package"
        },
        {
            featureid: "82700NWMMAN_2010_0F",
            description: "Autodesk Navisworks Manufacturing Manage 2010"
        },
        {
            featureid: "82800NWMMAN_F",
            description: "Autodesk Navisworks Manufacturing Manage Package"
        },
        {
            featureid: "82900NWMSIM_2010_0F",
            description: "Autodesk Navisworks Manufacturing Simulate 2010"
        },
        {
            featureid: "83000NWMSIM_F",
            description: "Autodesk Navisworks Manufacturing Simulate Package"
        },
        {
            featureid: "83100NWMREV_2010_0F",
            description: "Autodesk Navisworks Manufacturing Review 2010"
        },
        {
            featureid: "83200NWMREV_F",
            description: "Autodesk Navisworks Manufacturing Review Package"
        },
        {
            featureid: "83300INVAR_2010_0F",
            description: "Autodesk Inventor Automation Runtime 2010"
        },
        {
            featureid: "83400T1MF_2010_0F",
            description: "T1 Enterprise Multi-flex 2010"
        },
        {
            featureid: "83600INVAR_F",
            description: "Autodesk Inventor Automation Runtime Package"
        },
        {
            featureid: "83800HKMF_F",
            description: "Architecture Multi-flex Package"
        },
        {
            featureid: "83900MBXPRO_2010_0F",
            description: "Autodesk Mudbox 2010"
        },
        {
            featureid: "84000SFTIM_2010_0F",
            description: "Autodesk Softimage 2010"
        },
        {
            featureid: "84100SFTIMASIB1_2010_0F",
            description: "Autodesk Softimage 2010 SIBatch1"
        },
        {
            featureid: "84100SFTIMASIB2_2010_0F",
            description: "Autodesk Softimage 2010 SIBatch2"
        },
        {
            featureid: "84100SFTIMASIB3_2010_0F",
            description: "Autodesk Softimage 2010 SIBatch3"
        },
        {
            featureid: "84100SFTIMASIB4_2010_0F",
            description: "Autodesk Softimage 2010 SIBatch4"
        },
        {
            featureid: "84100SFTIMASIB5_2010_0F",
            description: "Autodesk Softimage 2010 SIBatch5"
        },
        {
            featureid: "84300FRDES_F",
            description: "Autodesk Face Robot Designer Package"
        },
        {
            featureid: "84500FRANIM_F",
            description: "Autodesk Face Robot Animator Package"
        },
        {
            featureid: "84600ACDLT_2010_0F",
            description: "AutoCAD LT 2010"
        },
        {
            featureid: "84700ACDLT_F",
            description: "AutoCAD LT Package"
        },
        {
            featureid: "84700ACDLT_T_F",
            description: "Autodesk AutoCAD LT Package Term"
        },
        {
            featureid: "84800AUTOST_2011_0F",
            description: "Autodesk Alias Automotive 2011"
        },
        {
            featureid: "84900AUTOST_F",
            description: "Autodesk Alias Automotive Package"
        },
        {
            featureid: "85000DESNST_2011_0F",
            description: "Autodesk Alias Design 2011"
        },
        {
            featureid: "85100DESNST_F",
            description: "Autodesk Alias Design Package"
        },
        {
            featureid: "85100DESNST_T_F",
            description: "Autodesk Alias Design Package Term"
        },
        {
            featureid: "85200SURFST_2011_0F",
            description: "Autodesk Alias Surface 2011"
        },
        {
            featureid: "85300SURFST_F",
            description: "Autodesk Alias Surface Package"
        },
        {
            featureid: "85300SURFST_T_F",
            description: "Autodesk Alias Surface Package Term"
        },
        {
            featureid: "85400MAYA_2010_0F",
            description: "Maya 2010"
        },
        {
            featureid: "85400MAYAMFS_2010_0F",
            description: "Autodesk Maya 2010 Fluid Simulation"
        },
        {
            featureid: "85400MAYAMMR_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray"
        },
        {
            featureid: "85400MAYAMMR1_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray 1"
        },
        {
            featureid: "85400MAYAMMR2_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray 2"
        },
        {
            featureid: "85400MAYAMMR3_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray 3"
        },
        {
            featureid: "85400MAYAMMR4_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray 4"
        },
        {
            featureid: "85400MAYAMMR5_2010_0F",
            description: "Autodesk Maya 2010 Mental Ray 5"
        },
        {
            featureid: "85500MXECS_2010_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite 2010"
        },
        {
            featureid: "855013DSMAX_2011_0F",
            description: "Autodesk 3ds Max 2011"
        },
        {
            featureid: "855023DSMAX_F",
            description: "Autodesk 3ds Max Package"
        },
        {
            featureid: "85503MAXDES_2011_0F",
            description: "Autodesk 3ds Max Design 2011"
        },
        {
            featureid: "85504MAXDES_F",
            description: "Autodesk 3ds Max Design Package"
        },
        {
            featureid: "85507ASMES_2010_0F",
            description: "Autodesk Algor Simulation MES 2010"
        },
        {
            featureid: "85508ASPRO_2010_0F",
            description: "Autodesk Algor Simulation Professional 2010"
        },
        {
            featureid: "85510MYRTAS_F",
            description: "Autodesk Maya Real-Time Animation Suite Package"
        },
        {
            featureid: "85511MYECS_2010_0F",
            description: "Autodesk Maya Entertainment Creation Suite 2010"
        },
        {
            featureid: "85512MYECS_F",
            description: "Autodesk Maya Entertainment Creation Suite Standard"
        },
        {
            featureid: "85512MYECS_T_F",
            description: "Autodesk Maya Entertainment Creation Suite Standard Package Term"
        },
        {
            featureid: "85514MXRTAS_F",
            description: "Autodesk 3ds Max Real-Time Animation Suite Package"
        },
        {
            featureid: "85516SIRTAS_F",
            description: "Autodesk Softimage Real-Time Animation Suite Package"
        },
        {
            featureid: "85518SIECS_F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard Package"
        },
        {
            featureid: "85520ESEC_F",
            description: "Autodesk Education Suite for Entertainment Creation Package"
        },
        {
            featureid: "85522AAA_F",
            description: "Autodesk Animation Academy Package"
        },
        {
            featureid: "85523ALGSIM_F",
            description: "Autodesk Simulation Mechanical Express Package"
        },
        {
            featureid: "85524ASCFD_F",
            description: "Autodesk Algor Simulation CFD Package"
        },
        {
            featureid: "85525ASMES_F",
            description: "Autodesk Algor Simulation MES Package"
        },
        {
            featureid: "85526ASPRO_F",
            description: "Autodesk Algor Simulation Professional Package"
        },
        {
            featureid: "85527MAYA_F",
            description: "Autodesk Maya Package"
        },
        {
            featureid: "85527MAYA_T_F",
            description: "Autodesk Maya Term"
        },
        {
            featureid: "85529MAYAMFS_F",
            description: "Autodesk Maya Fluid Simulation Package"
        },
        {
            featureid: "85530MAYAMMR_F",
            description: "Autodesk Maya Mental Ray Package"
        },
        {
            featureid: "85531MAYAMMR1_F",
            description: "Autodesk Maya Mental Ray 1 Package"
        },
        {
            featureid: "85536ACD_2011_0F",
            description: "AutoCAD 2011"
        },
        {
            featureid: "85536ACD_2011_0P",
            description: "AutoCAD 2011 Pre-Release"
        },
        {
            featureid: "85537MAYA_2011_0F",
            description: "Autodesk Maya 2011"
        },
        {
            featureid: "85537MAYAMFS_2011_0F",
            description: "Autodesk Maya 2011 Fluid Simulation"
        },
        {
            featureid: "85537MAYAMFS_F",
            description: "Autodesk Maya Fluid Simulation"
        },
        {
            featureid: "85537MAYAMMR_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray"
        },
        {
            featureid: "85537MAYAMMR_F",
            description: "Autodesk Maya Mental Ray Package"
        },
        {
            featureid: "85537MAYAMMR1_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray 1"
        },
        {
            featureid: "85537MAYAMMR1_F",
            description: "Autodesk Maya Mental Ray 1 Package"
        },
        {
            featureid: "85537MAYAMMR2_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray 2"
        },
        {
            featureid: "85537MAYAMMR2_F",
            description: "Autodesk Maya Mental Ray 2 Package"
        },
        {
            featureid: "85537MAYAMMR3_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray 3"
        },
        {
            featureid: "85537MAYAMMR3_F",
            description: "Autodesk Maya Mental Ray 3 Package"
        },
        {
            featureid: "85537MAYAMMR4_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray 4"
        },
        {
            featureid: "85537MAYAMMR4_F",
            description: "Autodesk Maya Mental Ray 4 Package"
        },
        {
            featureid: "85537MAYAMMR5_2011_0F",
            description: "Autodesk Maya 2011 Mental Ray 5"
        },
        {
            featureid: "85537MAYAMMR5_F",
            description: "Autodesk Maya Mental Ray 5 Package"
        },
        {
            featureid: "85538ACAD_E_2011_0F",
            description: "AutoCAD Electrical 2011"
        },
        {
            featureid: "85541RVTMPB_2011_0F",
            description: "Autodesk Revit MEP-B 2011"
        },
        {
            featureid: "85543REVITST_2011_0F",
            description: "Autodesk Revit Structure 2011"
        },
        {
            featureid: "85545AMECH_PP_2011_0F",
            description: "AutoCAD Mechanical 2011"
        },
        {
            featureid: "85550PCOFFI_2011_0F",
            description: "Autodesk Productstream Professional Office 2011"
        },
        {
            featureid: "85552MAPGE_2011_0F",
            description: "Autodesk MapGuide Enterprise 2011"
        },
        {
            featureid: "85554MOBPRO_2011_0F",
            description: "Autodesk MotionBuilder 2011"
        },
        {
            featureid: "85555ACDLT_2011_0F",
            description: "AutoCAD LT 2011"
        },
        {
            featureid: "85557MAP_2011_0F",
            description: "AutoCAD Map 3D 2011"
        },
        {
            featureid: "85559TOPOBSCLNT_2011_0F",
            description: "Autodesk Topobase Client 2011"
        },
        {
            featureid: "85560TOPOBSWEB_2011_0F",
            description: "Autodesk Topobase Web 2011"
        },
        {
            featureid: "85561ARCHDESK_2011_0F",
            description: "AutoCAD Architecture 2011"
        },
        {
            featureid: "85562BLDSYS_2011_0F",
            description: "AutoCAD MEP 2011"
        },
        {
            featureid: "85563SFTIM_2011_0F",
            description: "Autodesk Softimage 2011"
        },
        {
            featureid: "85563SFTIMSIB1_2011_0F",
            description: "Autodesk Softimage 2011 SIBatch1"
        },
        {
            featureid: "85563SFTIMSIB2_2011_0F",
            description: "Autodesk Softimage 2011 SIBatch2"
        },
        {
            featureid: "85563SFTIMSIB3_2011_0F",
            description: "Autodesk Softimage 2011 SIBatch3"
        },
        {
            featureid: "85563SFTIMSIB4_2011_0F",
            description: "Autodesk Softimage 2011 SIBatch4"
        },
        {
            featureid: "85563SFTIMSIB5_2011_0F",
            description: "Autodesk Softimage 2011 SIBatch5"
        },
        {
            featureid: "85567INVPRO_2011_0F",
            description: "AutoCAD Inventor Professional Suite 2011"
        },
        {
            featureid: "85569INVPRORS_2011_0F",
            description: "AutoCAD Inventor Routed Systems Suite 2011"
        },
        {
            featureid: "85571INVPROSIM_2011_0F",
            description: "AutoCAD Inventor Simulation Suite 2011"
        },
        {
            featureid: "85573INVBUN_2011_0F",
            description: "AutoCAD Inventor Suite 2011"
        },
        {
            featureid: "85575INVMFG_2011_0F",
            description: "AutoCAD Inventor Tooling Suite 2011"
        },
        {
            featureid: "85576INVMFG_F",
            description: "AutoCAD Inventor Tooling Suite Package"
        },
        {
            featureid: "85577INVNTOR_2010_0F",
            description: "Autodesk Inventor 2010"
        },
        {
            featureid: "85578INVNTOR_F",
            description: "Autodesk Inventor Package"
        },
        {
            featureid: "85578INVNTOR_T_F",
            description: "Autodesk Inventor Package Term"
        },
        {
            featureid: "85579INVNTOR_2011_0F",
            description: "Autodesk Inventor 2011"
        },
        {
            featureid: "85580MBXPRO_2011_0F",
            description: "Autodesk Mudbox 2011"
        },
        {
            featureid: "85581ECSCAD_2011_0F",
            description: "AutoCAD ecscad 2011"
        },
        {
            featureid: "85583LDXSP_2011_0F",
            description: "Autodesk LandXplorer Studio Professional 2011"
        },
        {
            featureid: "85584LDXSP_F",
            description: "Autodesk Infrastructure Modeler Package"
        },
        {
            featureid: "85585RSA_2011_0F",
            description: "Autodesk Robot Structural Analysis 2011"
        },
        {
            featureid: "85586RSAPRO_2011_0F",
            description: "Autodesk Robot Structural Analysis Professional 2011"
        },
        {
            featureid: "85587REVIT_2011_0F",
            description: "Autodesk Revit Architecture 2011"
        },
        {
            featureid: "85588VLTM_2011_0F",
            description: "Autodesk Vault Professional 2011"
        },
        {
            featureid: "85589VLTM_F",
            description: "Autodesk Vault Professional Package"
        },
        {
            featureid: "85590ALGSIM_2011_0F",
            description: "Autodesk Algor Simulation 2011"
        },
        {
            featureid: "85591ASCFD_2011_0F",
            description: "Autodesk Algor Simulation CFD 2011"
        },
        {
            featureid: "85592ASMES_2011_0F",
            description: "Autodesk Algor Simulation MES 2011"
        },
        {
            featureid: "85593ASPRO_2011_0F",
            description: "Autodesk Algor Simulation Professional 2011"
        },
        {
            featureid: "85594REVSU_2011_0F",
            description: "AutoCAD Revit Structure Suite 2011"
        },
        {
            featureid: "85595STRDET_2011_0F",
            description: "AutoCAD Structural Detailing 2011"
        },
        {
            featureid: "85596VLTWG_2011_0F",
            description: "Autodesk Vault Workgroup 2011"
        },
        {
            featureid: "85597VLTWG_F",
            description: "Autodesk Vault Workgroup Package"
        },
        {
            featureid: "85598VLTC_2011_0F",
            description: "Autodesk Vault Collaboration 2011"
        },
        {
            featureid: "85599VLTC_F",
            description: "Autodesk Vault Collaboration Package"
        },
        {
            featureid: "85600MXECS_F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard Package"
        },
        {
            featureid: "85600MXECS_T_F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard Package Term"
        },
        {
            featureid: "85600NAVMAN_2011_0F",
            description: "Autodesk Navisworks Manage 2011"
        },
        {
            featureid: "85601NAVSIM_2011_0F",
            description: "Autodesk Navisworks Simulate 2011"
        },
        {
            featureid: "85602PLNT3D_2011_0F",
            description: "AutoCAD Plant 3D 2011"
        },
        {
            featureid: "85603PNID_2011_0F",
            description: "AutoCAD P&ID 2011"
        },
        {
            featureid: "85604CIV3D_2011_0F",
            description: "AutoCAD Civil 3D 2011"
        },
        {
            featureid: "85606SHOWPRO_2011_0F",
            description: "Autodesk Showcase Professional 2011"
        },
        {
            featureid: "85607SHOWCASE_2011_0F",
            description: "Autodesk Showcase 2011"
        },
        {
            featureid: "85608SHCSPR_2011_0F",
            description: "Autodesk Showcase Presenter 2011"
        },
        {
            featureid: "85609DC_JT_2011_0F",
            description: "Autodesk DirectConnect JT 2011"
        },
        {
            featureid: "85610DC_JT_F",
            description: "Autodesk DirectConnect JT Package"
        },
        {
            featureid: "85611DC_UG_2011_0F",
            description: "Autodesk DirectConnect NX 2011"
        },
        {
            featureid: "85612DC_UG_F",
            description: "Autodesk DirectConnect NX Package"
        },
        {
            featureid: "85614RVTMPJ_2011_0F",
            description: "Autodesk Revit MEP 2011"
        },
        {
            featureid: "85615ALSK_2011_0F",
            description: "Autodesk Alias Sketch 2011"
        },
        {
            featureid: "85617MXECS_2011_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite 2011"
        },
        {
            featureid: "85618AAA_2011_0F",
            description: "Autodesk Animation Academy 2011"
        },
        {
            featureid: "85619ESEC_2011_0F",
            description: "Autodesk Education Suite for Entertainment Creation 2011"
        },
        {
            featureid: "85620MYECS_2011_0F",
            description: "Autodesk Maya Entertainment Creation Suite 2011"
        },
        {
            featureid: "85621SIECS_2011_0F",
            description: "Autodesk Softimage Entertainment Creation Suite 2011"
        },
        {
            featureid: "85622INVLTS_F",
            description: "AutoCAD Inventor LT Suite Package"
        },
        {
            featureid: "85622INVLTS_T_F",
            description: "Autodesk AutoCAD Inventor LT Suite Package Term"
        },
        {
            featureid: "85623REVITS_2011_0F",
            description: "AutoCAD Revit Architecture Suite 2011"
        },
        {
            featureid: "85624REVSYP_2011_0F",
            description: "AutoCAD Revit MEP Suite 2011"
        },
        {
            featureid: "85625DES_ACA_2011_0F",
            description: "Autodesk Design Academy 2011"
        },
        {
            featureid: "85626EMS_2011_0F",
            description: "Autodesk Education Master Suite 2011"
        },
        {
            featureid: "85627ESAE_2011_0F",
            description: "Autodesk Education Suite for Architecture and Engineering 2011"
        },
        {
            featureid: "85628ESCSE_2011_0F",
            description: "Autodesk Education Suite for Civil and Structural Engineering 2011"
        },
        {
            featureid: "85629ESID_2011_0F",
            description: "Autodesk Education Suite for Industrial Design 2011"
        },
        {
            featureid: "85630ESME_2011_0F",
            description: "Autodesk Education Suite for Mechanical Engineering 2011"
        },
        {
            featureid: "85631INVFPR_2011_0F",
            description: "Autodesk Inventor Factory Premium 2011"
        },
        {
            featureid: "85632INVFAD_2011_0F",
            description: "Autodesk Inventor Factory Advanced 2011"
        },
        {
            featureid: "85633NWFPR_2011_0F",
            description: "Autodesk Navisworks Factory Premium 2011"
        },
        {
            featureid: "85634NWFAD_2011_0F",
            description: "Autodesk Navisworks Factory Advanced 2011"
        },
        {
            featureid: "85635FDSPRM_2011_0F",
            description: "Autodesk Factory Design Suite Premium 2011"
        },
        {
            featureid: "85636FDSADV_2011_0F",
            description: "Autodesk Factory Design Suite Advanced 2011"
        },
        {
            featureid: "85637MFAA_2011_0F",
            description: "Autodesk Moldflow Adviser Advanced 2011"
        },
        {
            featureid: "85638MFAD_2011_0F",
            description: "Autodesk Moldflow Adviser Design 2011"
        },
        {
            featureid: "85639MFAM_2011_0F",
            description: "Autodesk Moldflow Adviser Manufacturing 2011"
        },
        {
            featureid: "85640MFCD_2011_0F",
            description: "Autodesk Moldflow CAD Doctor 2011"
        },
        {
            featureid: "85641MFDLC_2011_0F",
            description: "Autodesk Moldflow Design Link for CATIA V5 2011"
        },
        {
            featureid: "85642MFDLP_2011_0F",
            description: "Autodesk Moldflow Design Link for Parasolid 2011"
        },
        {
            featureid: "85643MFDLPE_2011_0F",
            description: "Autodesk Moldflow Design Link for Pro/ENGINEER 2011"
        },
        {
            featureid: "85644MFIA_2011_0F",
            description: "Autodesk Moldflow Insight Advanced 2011"
        },
        {
            featureid: "85645MFIB_2011_0F",
            description: "Autodesk Moldflow Insight Basic 2011"
        },
        {
            featureid: "85646MFIP_2011_0F",
            description: "Autodesk Moldflow Insight Performance 2011"
        },
        {
            featureid: "85647MFS_2011_0F",
            description: "Autodesk Moldflow Synergy 2011"
        },
        {
            featureid: "85648INVRT_2011_0F",
            description: "Autodesk Inventor Runtime 2011"
        },
        {
            featureid: "85649INVLT_2011_0F",
            description: "Autodesk Inventor LT 2011"
        },
        {
            featureid: "85650INVLTS_2011_0F",
            description: "AutoCAD Inventor LT Suite 2011"
        },
        {
            featureid: "85651BDSADV_2011_0F",
            description: "Autodesk Building Design Suite Advanced 2011"
        },
        {
            featureid: "85652BDSPRM_2011_0F",
            description: "Autodesk Building Design Suite Premium 2011"
        },
        {
            featureid: "85653DSSTD_2011_0F",
            description: "Autodesk Design Suite Standard 2011"
        },
        {
            featureid: "85654DSADV_2011_0F",
            description: "Autodesk Design Suite Advanced 2011"
        },
        {
            featureid: "85655DSPRM_2011_0F",
            description: "Autodesk Design Suite Premium 2011"
        },
        {
            featureid: "85656RAVS_2011_0F",
            description: "AutoCAD Revit Architecture Visualization Suite 2011"
        },
        {
            featureid: "85657ACDDS_2011_0F",
            description: "AutoCAD Visualization Suite 2011"
        },
        {
            featureid: "85658PDSADV_2011_0F",
            description: "Autodesk Plant Design Suite Advanced 2011"
        },
        {
            featureid: "85659PDSPRM_2011_0F",
            description: "Autodesk Plant Design Suite Premium 2011"
        },
        {
            featureid: "85660PDSPRM_F",
            description: "Autodesk Plant Design Suite Premium Package"
        },
        {
            featureid: "85660PDSPRM_T_F",
            description: "Autodesk Plant Design Suite Premium Package Term"
        },
        {
            featureid: "85662ACDLTC_2011_0F",
            description: "AutoCAD LT Civil Suite 2011"
        },
        {
            featureid: "85663GMCADC_2011_0F",
            description: "Autodesk GM CAD Convertors 2011"
        },
        {
            featureid: "85664GMCADC_F",
            description: "Autodesk GM CAD Convertors Package"
        },
        {
            featureid: "85664GMCADC_T_F",
            description: "Autodesk GM CAD Convertors Package Term"
        },
        {
            featureid: "85666ATCIGM_F",
            description: "Autodesk Alias TC Integrator for GM Package"
        },
        {
            featureid: "85666ATCIGM_T_F",
            description: "Autodesk Alias TC Integrator for GM Package Term"
        },
        {
            featureid: "85667INVPUB_2011_0F",
            description: "Autodesk Inventor Publisher 2011"
        },
        {
            featureid: "85668INVAS_2011_0F",
            description: "Autodesk Intent Professional 2011"
        },
        {
            featureid: "85669INVAS_F",
            description: "Autodesk Intent Professional Package"
        },
        {
            featureid: "85670INVAR_2011_0F",
            description: "Autodesk Intent Runtime 2011"
        },
        {
            featureid: "85671INTSER_2011_0F",
            description: "Autodesk Intent Server 2011"
        },
        {
            featureid: "85672ECOA_2011_0F",
            description: "Autodesk Ecotect Analysis 2011"
        },
        {
            featureid: "85673HKMF_2011_0F",
            description: "Architecture Multi-flex 2011"
        },
        {
            featureid: "85674T1MF_2011_0F",
            description: "T1 Enterprise Multi-flex 2011"
        },
        {
            featureid: "85675MRSTND_F",
            description: "mental ray Standalone Package"
        },
        {
            featureid: "85676INTSER_F",
            description: "Autodesk Inventor Engineer-to-Order Server Package"
        },
        {
            featureid: "85676INTSER_T_F",
            description: "Autodesk Inventor Engineer-to-Order Server Package Term"
        },
        {
            featureid: "85677ECSCAD_2012_0F",
            description: "AutoCAD ecscad 2012"
        },
        {
            featureid: "85677FDSADV_F",
            description: "Autodesk Factory Design Suite Ultimate Package"
        },
        {
            featureid: "85677FDSADV_T_F",
            description: "Autodesk Factory Design Suite Ultimate Package Term"
        },
        {
            featureid: "85677FDSPRM_F",
            description: "Autodesk Factory Design Suite Premium Package"
        },
        {
            featureid: "85677FDSPRM_T_F",
            description: "Autodesk Factory Design Suite Premium Package Term"
        },
        {
            featureid: "85678ACAD_E_2012_0F",
            description: "AutoCAD Electrical 2012"
        },
        {
            featureid: "85679INVFAD_F",
            description: "Autodesk Inventor Factory Ultimate Package"
        },
        {
            featureid: "85680INVFPR_F",
            description: "Autodesk Inventor Factory Premium Package"
        },
        {
            featureid: "85681T1MFPV_2011_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2011"
        },
        {
            featureid: "85683ARDES_2011_0F",
            description: "AutoCAD Raster Design 2011"
        },
        {
            featureid: "85685MXECSP_2011_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium 2011"
        },
        {
            featureid: "85686MXECSP_F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium Package"
        },
        {
            featureid: "85687MYECSP_2011_0F",
            description: "Autodesk Maya Entertainment Creation Suite Premium 2011"
        },
        {
            featureid: "85688MYECSP_F",
            description: "Autodesk Maya Entertainment Creation Suite Premium Package"
        },
        {
            featureid: "85689DSSTD_F",
            description: "Autodesk Design Suite Standard Package"
        },
        {
            featureid: "85689DSSTD_T_F",
            description: "Autodesk AutoCAD Design Suite Standard Package Term"
        },
        {
            featureid: "85690DSPRM_F",
            description: "Autodesk Design Suite Premium Package"
        },
        {
            featureid: "85690DSPRM_T_F",
            description: "Autodesk AutoCAD Design Suite Premium Package Term"
        },
        {
            featureid: "85691DSADV_F",
            description: "Autodesk Design Suite Ultimate Package"
        },
        {
            featureid: "85691DSADV_T_F",
            description: "Autodesk AutoCAD Design Suite Ultimate Package Term"
        },
        {
            featureid: "85692PDSADV_F",
            description: "Autodesk Plant Design Suite Ultimate Package"
        },
        {
            featureid: "85692PDSADV_T_F",
            description: "Autodesk Plant Design Suite Ultimate Package Term"
        },
        {
            featureid: "85693SMKMAC_2011_0F",
            description: "Autodesk Smoke 2011 for Mac OS X"
        },
        {
            featureid: "85693SMKMAC_2011_0P",
            description: "Autodesk Smoke 2011 for Mac OS X"
        },
        {
            featureid: "85693SMKMACBRN_2011_0F",
            description: "Autodesk Smoke Burn 2011"
        },
        {
            featureid: "85693SMKMACWG1_2011_0F",
            description: "Autodesk Smoke Wiretap Gateway1 2011"
        },
        {
            featureid: "85693SMKMACWG2_2011_0F",
            description: "Autodesk Smoke Wiretap Gateway2 2011"
        },
        {
            featureid: "85694MAYA_2012_0F",
            description: "Autodesk Maya 2012"
        },
        {
            featureid: "85694MAYAMMR1_2012_0F",
            description: "Autodesk Maya 2012 Mental Ray 1"
        },
        {
            featureid: "85694MAYAMMR1_F",
            description: "Autodesk Maya Mental Ray 1 Package"
        },
        {
            featureid: "85696MBXPRO_2012_0F",
            description: "Autodesk Mudbox 2012"
        },
        {
            featureid: "856983DSMAX_2012_0F",
            description: "Autodesk 3ds Max 2012"
        },
        {
            featureid: "85700MAXDES_2012_0F",
            description: "Autodesk 3ds Max Design 2012"
        },
        {
            featureid: "85702MOBPRO_2012_0F",
            description: "Autodesk MotionBuilder 2012"
        },
        {
            featureid: "85704SHOWCASE_2012_0F",
            description: "Autodesk Showcase 2012"
        },
        {
            featureid: "85705SFTIM_2012_0F",
            description: "Autodesk Softimage 2012"
        },
        {
            featureid: "85705SFTIMASIB_F",
            description: "Autodesk Softimage 2013 Batch Package"
        },
        {
            featureid: "85705SFTIMSIB_2012_0F",
            description: "Autodesk Softimage 2012 SIBatch"
        },
        {
            featureid: "85708MXECS_2012_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2012"
        },
        {
            featureid: "85710MXECSP_2012_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium 2012"
        },
        {
            featureid: "85712AAA_2012_0F",
            description: "Autodesk Animation Academy 2012"
        },
        {
            featureid: "85713AAA_F",
            description: "Autodesk Animation Academy Package"
        },
        {
            featureid: "85714ESEC_2012_0F",
            description: "Autodesk Entertainment Creation Suite for Education 2012"
        },
        {
            featureid: "85716MYECS_2012_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2012"
        },
        {
            featureid: "85718MYECSP_2012_0F",
            description: "Autodesk Maya Entertainment Creation Suite Premium 2012"
        },
        {
            featureid: "85720SIECS_2012_0F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard 2012"
        },
        {
            featureid: "85726ASMES_2012_0F",
            description: "Autodesk Simulation Mechanical 2012"
        },
        {
            featureid: "85728ASPRO_2012_0F",
            description: "Autodesk Simulation Multiphysics 2012"
        },
        {
            featureid: "85730ACD_2012_0F",
            description: "AutoCAD 2012"
        },
        {
            featureid: "85731ARCHDESK_2012_0F",
            description: "AutoCAD Architecture 2012"
        },
        {
            featureid: "85732ACDLT_2012_0F",
            description: "AutoCAD LT 2012"
        },
        {
            featureid: "85733AMECH_PP_2012_0F",
            description: "AutoCAD Mechanical 2012"
        },
        {
            featureid: "85734BLDSYS_2012_0F",
            description: "AutoCAD MEP 2012"
        },
        {
            featureid: "85737SMKMAC_2012_0F",
            description: "Autodesk Smoke 2012 for Mac OS X"
        },
        {
            featureid: "85737SMKMACBRN_2012_0F",
            description: "Autodesk Smoke Burn 2012"
        },
        {
            featureid: "85737SMKMACBRN_F",
            description: "Autodesk Smoke Burn Package"
        },
        {
            featureid: "85737SMKMACWG_2012_0F",
            description: "Autodesk Smoke Wiretap Gateway 2012"
        },
        {
            featureid: "85737SMKMACWG_F",
            description: "Autodesk Smoke Wiretap Gateway Package"
        },
        {
            featureid: "85738SMKMAC_F",
            description: "Autodesk Smoke for Mac OS X Package"
        },
        {
            featureid: "85738SMKMACWG1_F",
            description: "Autodesk Smoke Wiretap Gateway1 Package"
        },
        {
            featureid: "85738SMKMACWG2_F",
            description: "Autodesk Smoke Wiretap Gateway2 Package"
        },
        {
            featureid: "85740AUTOST_2012_0F",
            description: "Autodesk Alias Automotive 2012"
        },
        {
            featureid: "85741DESNST_2012_0F",
            description: "Autodesk Alias Design 2012"
        },
        {
            featureid: "85742SURFST_2012_0F",
            description: "Autodesk Alias Surface 2012"
        },
        {
            featureid: "85744STRDET_2012_0F",
            description: "AutoCAD Structural Detailing 2012"
        },
        {
            featureid: "85747REVIT_2012_0F",
            description: "Autodesk Revit Architecture 2012"
        },
        {
            featureid: "85748RVTMPB_2012_0F",
            description: "Autodesk Revit MEP 2012"
        },
        {
            featureid: "85749REVITST_2012_0F",
            description: "Autodesk Revit Structure 2012"
        },
        {
            featureid: "85750VLTC_2012_0F",
            description: "Autodesk Vault Collaboration 2012"
        },
        {
            featureid: "85752VLTWG_2012_0F",
            description: "Autodesk Vault Workgroup 2012"
        },
        {
            featureid: "85753VLTM_2012_0F",
            description: "Autodesk Vault Professional 2012"
        },
        {
            featureid: "85754DC_JT_2012_0F",
            description: "Autodesk DirectConnect JT 2012"
        },
        {
            featureid: "85755DC_UG_2012_0F",
            description: "Autodesk DirectConnect NX 2012"
        },
        {
            featureid: "85758ATCIGM_2012_0F",
            description: "Autodesk Alias TC Integrator for GM 2012"
        },
        {
            featureid: "85759INVLTS_2012_0F",
            description: "AutoCAD Inventor LT Suite 2012"
        },
        {
            featureid: "85762INVPRORS_2012_0F",
            description: "AutoCAD Inventor Routed Systems Suite 2012"
        },
        {
            featureid: "85765INVLT_2012_0F",
            description: "Autodesk Inventor LT 2012"
        },
        {
            featureid: "85766INVFPR_2012_0F",
            description: "Autodesk Inventor Factory Premium 2012"
        },
        {
            featureid: "85767INVFAD_2012_0F",
            description: "Autodesk Inventor Factory Ultimate 2012"
        },
        {
            featureid: "85768INVNTOR_2012_0F",
            description: "Autodesk Inventor 2012"
        },
        {
            featureid: "85769EMS_2012_0F",
            description: "Autodesk Education Master Suite 2012"
        },
        {
            featureid: "85770ESAE_2012_0F",
            description: "Autodesk Building Design Suite for Education 2012"
        },
        {
            featureid: "85771ESCSE_2012_0F",
            description: "Autodesk Infrastructure Design Suite for Education 2012"
        },
        {
            featureid: "85773ESME_2012_0F",
            description: "Autodesk Product Design Suite for Education 2012"
        },
        {
            featureid: "85774DES_ACA_2012_0F",
            description: "Autodesk Design Academy 2012"
        },
        {
            featureid: "85776MAP_2012_0F",
            description: "AutoCAD Map 3D 2012"
        },
        {
            featureid: "85777RSA_2012_0F",
            description: "Autodesk Robot Structural Analysis 2012"
        },
        {
            featureid: "85778RSAPRO_2012_0F",
            description: "Autodesk Robot Structural Analysis Professional 2012"
        },
        {
            featureid: "85779PLNT3D_2012_0F",
            description: "AutoCAD Plant 3D 2012"
        },
        {
            featureid: "85780PNID_2012_0F",
            description: "AutoCAD P&ID 2012"
        },
        {
            featureid: "85781INVPUB_2012_0F",
            description: "Autodesk Inventor Publisher 2012"
        },
        {
            featureid: "85782INVPUB_F",
            description: "Autodesk Inventor Publisher Package"
        },
        {
            featureid: "85783DSSTD_2012_0F",
            description: "Autodesk Design Suite Standard 2012"
        },
        {
            featureid: "85784DSPRM_2012_0F",
            description: "Autodesk Design Suite Premium 2012"
        },
        {
            featureid: "85785PDSPRM_2012_0F",
            description: "Autodesk Plant Design Suite Premium 2012"
        },
        {
            featureid: "85786PDSADV_2012_0F",
            description: "Autodesk Plant Design Suite Ultimate 2012"
        },
        {
            featureid: "85787BDSPRM_2012_0F",
            description: "Autodesk Building Design Suite Premium 2012"
        },
        {
            featureid: "85787BDSPRM_F",
            description: "Autodesk Building Design Suite Premium Package"
        },
        {
            featureid: "85787BDSPRM_T_F",
            description: "Autodesk Building Design Suite Premium Package Term"
        },
        {
            featureid: "85788BDSADV_2012_0F",
            description: "Autodesk Building Design Suite Ultimate 2012"
        },
        {
            featureid: "85788BDSADV_F",
            description: "Autodesk Building Design Suite Ultimate Package"
        },
        {
            featureid: "85788BDSADV_T_F",
            description: "Autodesk Building Design Suite Ultimate Package Term"
        },
        {
            featureid: "85789FDSPRM_2012_0F",
            description: "Autodesk Factory Design Suite Premium 2012"
        },
        {
            featureid: "85790FDSADV_2012_0F",
            description: "Autodesk Factory Design Suite Ultimate 2012"
        },
        {
            featureid: "85791REVITS_2012_0F",
            description: "AutoCAD Revit Architecture Suite 2012"
        },
        {
            featureid: "85792REVSU_2012_0F",
            description: "AutoCAD Revit Structure Suite 2012"
        },
        {
            featureid: "85793REVSYP_2012_0F",
            description: "AutoCAD Revit MEP Suite 2012"
        },
        {
            featureid: "85794PDSS_2012_0F",
            description: "Autodesk Product Design Suite Standard 2012"
        },
        {
            featureid: "85795PDSP_2012_0F",
            description: "Autodesk Product Design Suite Premium 2012"
        },
        {
            featureid: "85796PDSU_2012_0F",
            description: "Autodesk Product Design Suite Ultimate 2012"
        },
        {
            featureid: "85797BDSS_2012_0F",
            description: "Autodesk Building Design Suite Standard 2012"
        },
        {
            featureid: "85797BDSS_F",
            description: "Autodesk Building Design Suite Standard Package"
        },
        {
            featureid: "85797BDSS_T_F",
            description: "Autodesk Building Design Suite Standard Package Term"
        },
        {
            featureid: "85798IDSU_2012_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2012"
        },
        {
            featureid: "85799IDSP_2012_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2012"
        },
        {
            featureid: "85800IDSS_2012_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2012"
        },
        {
            featureid: "85801DSADV_2012_0F",
            description: "Autodesk Design Suite Ultimate 2012"
        },
        {
            featureid: "85802PLTDSS_2012_0F",
            description: "Autodesk Plant Design Suite Standard 2012"
        },
        {
            featureid: "85803PLTDSS_F",
            description: "Autodesk Plant Design Suite Standard Package"
        },
        {
            featureid: "85803PLTDSS_T_F",
            description: "Autodesk Plant Design Suite Standard Package Term"
        },
        {
            featureid: "85804FDSS_2012_0F",
            description: "Autodesk Factory Design Suite Standard 2012"
        },
        {
            featureid: "85805FDSS_F",
            description: "Autodesk Factory Design Suite Standard Package"
        },
        {
            featureid: "85805FDSS_T_F",
            description: "Autodesk Factory Design Suite Standard Package Term"
        },
        {
            featureid: "85806ENCSU_2012_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2012"
        },
        {
            featureid: "85807ENCSU_F",
            description: "Autodesk Entertainment Creation Suite Ultimate Package"
        },
        {
            featureid: "85807ENCSU_T_F",
            description: "Autodesk Entertainment Creation Suite Ultimate Package Term"
        },
        {
            featureid: "85808ACDLTC_2012_0F",
            description: "AutoCAD LT Civil Suite 2012"
        },
        {
            featureid: "85810IDSU_F",
            description: "Autodesk Infrastructure Design Suite Ultimate Package"
        },
        {
            featureid: "85810IDSU_T_F",
            description: "Autodesk Infrastructure Design Suite Ultimate Package Term"
        },
        {
            featureid: "85811IDSS_F",
            description: "Autodesk Infrastructure Design Suite Standard Package"
        },
        {
            featureid: "85811IDSS_T_F",
            description: "Autodesk Infrastructure Design Suite Standard Package Term"
        },
        {
            featureid: "85812IDSP_F",
            description: "Autodesk Infrastructure Design Suite Premium Package"
        },
        {
            featureid: "85812IDSP_T_F",
            description: "Autodesk Infrastructure Design Suite Premium Package Term"
        },
        {
            featureid: "85813PDSS_F",
            description: "Autodesk Product Design Suite Standard Package"
        },
        {
            featureid: "85814PDSP_F",
            description: "Autodesk Product Design Suite Premium Package"
        },
        {
            featureid: "85814PDSP_T_F",
            description: "Autodesk Product Design Suite Premium Package Term"
        },
        {
            featureid: "85815PDSU_F",
            description: "Autodesk Product Design Suite Ultimate  Package"
        },
        {
            featureid: "85815PDSU_T_F",
            description: "Autodesk Product Design Suite Ultimate Package Term"
        },
        {
            featureid: "85816MFAA_2012_0F",
            description: "Autodesk Moldflow Adviser Advanced 2012"
        },
        {
            featureid: "85817MFCD_2012_0F",
            description: "Autodesk Moldflow CAD Doctor 2012"
        },
        {
            featureid: "85818MFAD_2012_0F",
            description: "Autodesk Moldflow Adviser Design 2012"
        },
        {
            featureid: "85819MFAM_2012_0F",
            description: "Autodesk Moldflow Adviser Manufacturing 2012"
        },
        {
            featureid: "85820MFDLC_2012_0F",
            description: "Autodesk Moldflow Design Link for CATIA V5 2012"
        },
        {
            featureid: "85821MFDLP_2012_0F",
            description: "Autodesk Moldflow Design Link for Parasolid 2012"
        },
        {
            featureid: "85822MFDLPE_2012_0F",
            description: "Autodesk Moldflow Design Link for Pro/ENGINEER 2012"
        },
        {
            featureid: "85823MFIA_2012_0F",
            description: "Autodesk Moldflow Insight Advanced 2012"
        },
        {
            featureid: "85824MFIP_2012_0F",
            description: "Autodesk Moldflow Insight Performance 2012"
        },
        {
            featureid: "85825MFIB_2012_0F",
            description: "Autodesk Moldflow Insight Basic 2012"
        },
        {
            featureid: "85826MFS_2012_0F",
            description: "Autodesk Moldflow Synergy 2012"
        },
        {
            featureid: "85827UTLDESN_2012_0F",
            description: "Autodesk Utility Design 2012"
        },
        {
            featureid: "85828ALSK_2012_0F",
            description: "Autodesk SketchBook Designer 2012"
        },
        {
            featureid: "85829ALSK_F",
            description: "Autodesk SketchBook Designer Package"
        },
        {
            featureid: "85830CIV3D_2012_0F",
            description: "AutoCAD Civil 3D 2012"
        },
        {
            featureid: "85831MRSTND_2012_0F",
            description: "mental ray Standalone 2012"
        },
        {
            featureid: "85832MRSTND_F",
            description: "mental ray Standalone Package"
        },
        {
            featureid: "85833AQTO_2012_0F",
            description: "Autodesk Quantity Takeoff 2012"
        },
        {
            featureid: "85834AQTO_F",
            description: "Autodesk Quantity Takeoff Package"
        },
        {
            featureid: "85835SHOWCASE_F",
            description: "Autodesk Showcase Package"
        },
        {
            featureid: "85835SHOWCASE_T_F",
            description: "Autodesk Showcase Package Term"
        },
        {
            featureid: "85836AMECH_PP_F",
            description: "AutoCAD Mechanical Package"
        },
        {
            featureid: "85837NAVSIM_2012_0F",
            description: "Autodesk Navisworks Simulate 2012"
        },
        {
            featureid: "85838NAVSIM_F",
            description: "Autodesk NavisWorks Simulate Package"
        },
        {
            featureid: "85838NAVSIM_T_F",
            description: "Autodesk Navisworks Simulate Package Term"
        },
        {
            featureid: "85839NAVMAN_2012_0F",
            description: "Autodesk Navisworks Manage 2012"
        },
        {
            featureid: "85840ECOA_2012_0F",
            description: "Autodesk Ecotect Analysis 2012"
        },
        {
            featureid: "85841ARDES_2012_0F",
            description: "AutoCAD Raster Design 2012"
        },
        {
            featureid: "85842GMCADC_2012_0F",
            description: "Autodesk GM CAD Convertors 2012"
        },
        {
            featureid: "85843PCOFFI_2012_0F",
            description: "Autodesk Vault Office 2012"
        },
        {
            featureid: "85844IMS_2012_0F",
            description: "Autodesk Infrastructure Map Server 2012"
        },
        {
            featureid: "85845IMS_F",
            description: "Autodesk Infrastructure Map Server Package"
        },
        {
            featureid: "85845IMS_T_F",
            description: "Autodesk Infrastructure Map Server Package Term"
        },
        {
            featureid: "85846INVPROSA_2012_0F",
            description: "Autodesk Inventor Professional 2012"
        },
        {
            featureid: "85847SHOWPRO_2012_0F",
            description: "Autodesk Showcase Professional 2012"
        },
        {
            featureid: "85849INVOEM_2012_0F",
            description: "Autodesk Inventor OEM 2012"
        },
        {
            featureid: "85850MAP3DE_2012_0F",
            description: "AutoCAD Map 3D Enterprise 2012"
        },
        {
            featureid: "85851MAP3DE_F",
            description: "AutoCAD Map 3D Enterprise Package"
        },
        {
            featureid: "85852RTRTCL_2012_0F",
            description: "Autodesk Real-Time Ray Tracing Cluster 2012"
        },
        {
            featureid: "85852RTRTCL_F",
            description: "Autodesk Real-Time Ray Tracing Cluster Package"
        },
        {
            featureid: "85853ACD_2012_0F",
            description: "Autodesk Vault Base Server 2012"
        },
        {
            featureid: "85854OSPRO_2012_0F",
            description: "Autodesk Opticore Studio Professional 2012"
        },
        {
            featureid: "85855OSPRO_F",
            description: "Autodesk Opticore Studio Professional Package"
        },
        {
            featureid: "85856VTCAEC_2012_0F",
            description: "Autodesk Vault Collaboration AEC 2012"
        },
        {
            featureid: "85857MAYA_2013_0F",
            description: "Autodesk Maya 2013"
        },
        {
            featureid: "85857MAYAMMR1_2013_0F",
            description: "Autodesk Maya 2013 Mental Ray 1"
        },
        {
            featureid: "85859TRTLS_2012_0F",
            description: "Turtle For Maya Standard 2012"
        },
        {
            featureid: "85860TRTLP_2012_0F",
            description: "Turtle For Maya Premium 2012"
        },
        {
            featureid: "85860TRTLPB_2012_0F",
            description: "Turtle For Maya Premium 2012"
        },
        {
            featureid: "85860TRTLPB_F",
            description: "Turtle For Maya Premium Package"
        },
        {
            featureid: "85862REVIT_2013_0F",
            description: "Autodesk Revit Architecture 2013"
        },
        {
            featureid: "85864RVTMPB_2013_0F",
            description: "Autodesk Revit MEP 2013"
        },
        {
            featureid: "85866REVITST_2013_0F",
            description: "Autodesk Revit Structure 2013"
        },
        {
            featureid: "85868KYNGMS_2012_0F",
            description: "Autodesk Kynapse 2012"
        },
        {
            featureid: "85869MBXPRO_2013_0F",
            description: "Autodesk Mudbox 2013"
        },
        {
            featureid: "85871ACD_2013_0F",
            description: "AutoCAD 2013"
        },
        {
            featureid: "85872ACDLT_2013_0F",
            description: "AutoCAD LT 2013"
        },
        {
            featureid: "85873INVETO_2012_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2012"
        },
        {
            featureid: "85874INVETO_2021_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package"
        },
        {
            featureid: "85874INVETO_2021_T_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package Term"
        },
        {
            featureid: "85874INVETO_2022_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package"
        },
        {
            featureid: "85874INVETO_2022_T_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package Term"
        },
        {
            featureid: "85874INVETO_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package"
        },
        {
            featureid: "85874INVETO_T_F",
            description: "Autodesk Inventor Engineer-to-Order Series Package Term"
        },
        {
            featureid: "85875ACDMAC_2012_0F",
            description: "AutoCAD for Mac 2012"
        },
        {
            featureid: "85876ACDMAC_F",
            description: "AutoCAD for Mac Package"
        },
        {
            featureid: "85876ACDMAC_T_F",
            description: "Autodesk AutoCAD for Mac Term"
        },
        {
            featureid: "85877INTSER_2012_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2012"
        },
        {
            featureid: "85881AUTOST_2013_0F",
            description: "Autodesk Alias Automotive 2013"
        },
        {
            featureid: "85882INFMDR_2012_0F",
            description: "Autodesk Infrastructure Modeler 2012"
        },
        {
            featureid: "85883ARCHDESK_2013_0F",
            description: "AutoCAD Architecture 2013"
        },
        {
            featureid: "85885AMECH_PP_2013_0F",
            description: "AutoCAD Mechanical 2013"
        },
        {
            featureid: "85887BLDSYS_2013_0F",
            description: "AutoCAD MEP 2013"
        },
        {
            featureid: "85889ECSCAD_2013_0F",
            description: "AutoCAD ecscad 2013"
        },
        {
            featureid: "85890ACAD_E_2013_0F",
            description: "AutoCAD Electrical 2013"
        },
        {
            featureid: "858923DSMAX_2013_0F",
            description: "Autodesk 3ds Max 2013"
        },
        {
            featureid: "85893MAXDES_2013_0F",
            description: "Autodesk 3ds Max Design 2013"
        },
        {
            featureid: "85895SURFST_2013_0F",
            description: "Autodesk Alias Surface 2013"
        },
        {
            featureid: "85896DESNST_2013_0F",
            description: "Autodesk Alias Design 2013"
        },
        {
            featureid: "85897T1MF_2012_0F",
            description: "T1 Enterprise Multi-flex 2012"
        },
        {
            featureid: "85899T1MFPV_2012_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2012"
        },
        {
            featureid: "85902SCFD_2012_0F",
            description: "Autodesk Simulation CFD 2012"
        },
        {
            featureid: "85903SCFDA_2012_0F",
            description: "Autodesk Simulation CFD 2012 Advanced"
        },
        {
            featureid: "85904SCACIS_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for ACIS"
        },
        {
            featureid: "85905SCCV5_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Catia V5"
        },
        {
            featureid: "85906SCCOC_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for CoCreate"
        },
        {
            featureid: "85907SCFDCD_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Discrete"
        },
        {
            featureid: "85908SCFDCI_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Inventor"
        },
        {
            featureid: "85909SCFDNX_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for NX"
        },
        {
            featureid: "85910SCFDP_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Parasolid"
        },
        {
            featureid: "85911SCPROE_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Pro/ENGINEER"
        },
        {
            featureid: "85912SCFDCR_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for Revit"
        },
        {
            featureid: "85913SCFDSE_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for SolidEdge"
        },
        {
            featureid: "85914SCFDSW_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for SolidWorks"
        },
        {
            featureid: "85915SCFDSC_2012_0F",
            description: "Autodesk Simulation CFD 2012 Connection for SpaceClaim"
        },
        {
            featureid: "85916SCDSE_2012_0F",
            description: "Autodesk Simulation CFD 2012 Design Study Environment"
        },
        {
            featureid: "85917SCFDM_2012_0F",
            description: "Autodesk Simulation CFD 2012 Motion"
        },
        {
            featureid: "85918MXECSP_2013_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium 2013"
        },
        {
            featureid: "85920MXECS_2013_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2013"
        },
        {
            featureid: "85922AAA_2013_0F",
            description: "Autodesk Animation Academy 2013"
        },
        {
            featureid: "85926ENCSU_2013_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2013"
        },
        {
            featureid: "85928MYECSP_2013_0F",
            description: "Autodesk Maya Entertainment Creation Suite Premium 2013"
        },
        {
            featureid: "85930MYECS_2013_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2013"
        },
        {
            featureid: "85932SIECS_2013_0F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard 2013"
        },
        {
            featureid: "85934SFTIM_2013_0F",
            description: "Autodesk Softimage 2013"
        },
        {
            featureid: "85934SFTIMSIB_2013_0F",
            description: "Autodesk Softimage 2013 SIBatch"
        },
        {
            featureid: "85936MOBPRO_2013_0F",
            description: "Autodesk MotionBuilder 2013"
        },
        {
            featureid: "85937SMKMAC_2013_0F",
            description: "Autodesk Smoke 2013 for Mac OS X"
        },
        {
            featureid: "85937SMKMACBRN_2013_0F",
            description: "Autodesk Smoke 2013 for Mac OS X Burn"
        },
        {
            featureid: "85937SMKMACWG_2013_0F",
            description: "Autodesk Smoke 2013 for Mac OS X Wiretap Gateway"
        },
        {
            featureid: "85940ASMES_2013_0F",
            description: "Autodesk Simulation Mechanical 2013"
        },
        {
            featureid: "85942ASPRO_2013_0F",
            description: "Autodesk Simulation Multiphysics 2013"
        },
        {
            featureid: "85943STRDET_2013_0F",
            description: "AutoCAD Structural Detailing 2013"
        },
        {
            featureid: "85946BDSPRM_2013_0F",
            description: "Autodesk Building Design Suite Premium 2013"
        },
        {
            featureid: "85947BDSS_2013_0F",
            description: "Autodesk Building Design Suite Standard 2013"
        },
        {
            featureid: "85948BDSADV_2013_0F",
            description: "Autodesk Building Design Suite Ultimate 2013"
        },
        {
            featureid: "85949SHOWPRO_2013_0F",
            description: "Autodesk Showcase Professional 2013"
        },
        {
            featureid: "85950RVT_2013_0F",
            description: "Autodesk Revit 2013"
        },
        {
            featureid: "85950RVT_F",
            description: "Autodesk Revit Package"
        },
        {
            featureid: "85950RVT_T_F",
            description: "Autodesk Revit Package Term"
        },
        {
            featureid: "85951RVTLT_2013_0F",
            description: "Autodesk Revit LT 2013"
        },
        {
            featureid: "85951RVTLT_F",
            description: "Autodesk Revit LT Package"
        },
        {
            featureid: "85951RVTLT_T_F",
            description: "Autodesk Revit LT Package Term"
        },
        {
            featureid: "85952ARDES_2013_0F",
            description: "AutoCAD Raster Design 2013"
        },
        {
            featureid: "85954REVITS_2013_0F",
            description: "AutoCAD Revit Architecture Suite 2013"
        },
        {
            featureid: "85955REVSYP_2013_0F",
            description: "AutoCAD Revit MEP Suite 2013"
        },
        {
            featureid: "85956SHOWCASE_2013_0F",
            description: "Autodesk Showcase 2013"
        },
        {
            featureid: "85957REVSU_2013_0F",
            description: "AutoCAD Revit Structure Suite 2013"
        },
        {
            featureid: "85958INVLTS_2013_0F",
            description: "AutoCAD Inventor LT Suite 2013"
        },
        {
            featureid: "85959INVETO_2013_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2013"
        },
        {
            featureid: "85961ESME_2013_0F",
            description: "Autodesk Product Design Suite for Education 2013"
        },
        {
            featureid: "85962PDSP_2013_0F",
            description: "Autodesk Product Design Suite Premium 2013"
        },
        {
            featureid: "85963PDSU_2013_0F",
            description: "Autodesk Product Design Suite Ultimate 2013"
        },
        {
            featureid: "85964PDSS_2013_0F",
            description: "Autodesk Product Design Suite Standard 2013"
        },
        {
            featureid: "85965FDSPRM_2013_0F",
            description: "Autodesk Factory Design Suite Premium 2013"
        },
        {
            featureid: "85966FDSS_2013_0F",
            description: "Autodesk Factory Design Suite Standard 2013"
        },
        {
            featureid: "85967FDSADV_2013_0F",
            description: "Autodesk Factory Design Suite Ultimate 2013"
        },
        {
            featureid: "85969DSPRM_2013_0F",
            description: "AutoCAD Design Suite Premium 2013"
        },
        {
            featureid: "85970DSSTD_2013_0F",
            description: "AutoCAD Design Suite Standard 2013"
        },
        {
            featureid: "85971DSADV_2013_0F",
            description: "AutoCAD Design Suite Ultimate 2013"
        },
        {
            featureid: "85972DES_ACA_2013_0F",
            description: "Autodesk Design Academy 2013"
        },
        {
            featureid: "85974ESCSE_2013_0F",
            description: "Autodesk Infrastructure Design Suite for Education 2013"
        },
        {
            featureid: "85975RSAPRO_2013_0F",
            description: "Autodesk Robot Structural Analysis Professional 2013"
        },
        {
            featureid: "85976CIV3D_2013_0F",
            description: "AutoCAD Civil 3D 2013"
        },
        {
            featureid: "85977PNID_2013_0F",
            description: "AutoCAD P&ID 2013"
        },
        {
            featureid: "85978PLNT3D_2013_0F",
            description: "AutoCAD Plant 3D 2013"
        },
        {
            featureid: "85979INVPUB_2013_0F",
            description: "Autodesk Inventor Publisher 2013"
        },
        {
            featureid: "85981MAP_2013_0F",
            description: "AutoCAD Map 3D 2013"
        },
        {
            featureid: "85982MAP3DE_2013_0F",
            description: "AutoCAD Map 3D Enterprise 2013"
        },
        {
            featureid: "85982MAP3DE_2013_0P",
            description: "AutoCAD Map 3D Enterprise 2013"
        },
        {
            featureid: "85983VLTC_2013_0F",
            description: "Autodesk 360 Vault Collaboration 2013"
        },
        {
            featureid: "85984VTCAEC_2013_0F",
            description: "Autodesk 360 Vault Collaboration AEC 2013"
        },
        {
            featureid: "85986PCOFFI_2013_0F",
            description: "Autodesk 360 Vault Office 2013"
        },
        {
            featureid: "85987VLTM_2013_0F",
            description: "Autodesk 360 Vault Professional 2013"
        },
        {
            featureid: "85988VLTWG_2013_0F",
            description: "Autodesk 360 Vault Workgroup 2013"
        },
        {
            featureid: "85989ACDMAC_2013_0F",
            description: "AutoCAD for Mac 2013"
        },
        {
            featureid: "85990ALSK_2013_0F",
            description: "Autodesk SketchBook Designer 2013"
        },
        {
            featureid: "85991ACDLTC_2013_0F",
            description: "AutoCAD LT Civil Suite 2013"
        },
        {
            featureid: "85992IDSP_2013_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2013"
        },
        {
            featureid: "85993IDSS_2013_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2013"
        },
        {
            featureid: "85994IDSU_2013_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2013"
        },
        {
            featureid: "85995PDSPRM_2013_0F",
            description: "Autodesk Plant Design Suite Premium 2013"
        },
        {
            featureid: "85996PLTDSS_2013_0F",
            description: "Autodesk Plant Design Suite Standard 2013"
        },
        {
            featureid: "85997PDSADV_2013_0F",
            description: "Autodesk Plant Design Suite Ultimate 2013"
        },
        {
            featureid: "85998INVNTOR_2013_0F",
            description: "Autodesk Inventor 2013"
        },
        {
            featureid: "85999INVFPR_2013_0F",
            description: "Autodesk Inventor Factory Premium 2013"
        },
        {
            featureid: "86000INVFAD_2013_0F",
            description: "Autodesk Inventor Factory Ultimate 2013"
        },
        {
            featureid: "86001INVPROSA_2013_0F",
            description: "Autodesk Inventor Professional 2013"
        },
        {
            featureid: "86002INVOEM_2013_0F",
            description: "Autodesk Inventor OEM 2013"
        },
        {
            featureid: "86003INVOEM_F",
            description: "Autodesk Inventor OEM Package"
        },
        {
            featureid: "86003INVOEM_T_F",
            description: "Autodesk Inventor OEM Package Term"
        },
        {
            featureid: "86004INVLT_2013_0F",
            description: "Autodesk Inventor LT 2013"
        },
        {
            featureid: "86005RSA_2013_0F",
            description: "Autodesk Robot Structural Analysis 2013"
        },
        {
            featureid: "86006MFAM_2013_0F",
            description: "Autodesk Simulation Moldflow Adviser Premium 2013"
        },
        {
            featureid: "86007MFAA_2013_0F",
            description: "Autodesk Simulation Moldflow Adviser Ultimate 2013"
        },
        {
            featureid: "86008MFAD_2013_0F",
            description: "Autodesk Simulation Moldflow Adviser Standard 2013"
        },
        {
            featureid: "86009MFCD_2013_0F",
            description: "Autodesk Simulation Moldflow CAD Doctor 2013"
        },
        {
            featureid: "86010MFDL_2013_0F",
            description: "Autodesk Simulation Moldflow Design Link 2013"
        },
        {
            featureid: "86011MFDL_F",
            description: "Autodesk Simulation Moldflow Design Link Package"
        },
        {
            featureid: "86012MFS_2013_0F",
            description: "Autodesk Simulation Moldflow Synergy 2013"
        },
        {
            featureid: "86013INFMDR_2013_0F",
            description: "Autodesk Infrastructure Modeler 2013"
        },
        {
            featureid: "86014IMS_2013_0F",
            description: "Autodesk Infrastructure Map Server 2013"
        },
        {
            featureid: "86017RVTLTS_F",
            description: "AutoCAD Revit LT Suite Package"
        },
        {
            featureid: "86017RVTLTS_T_F",
            description: "Autodesk AutoCAD Revit LT Suite Package Term"
        },
        {
            featureid: "86019MRSTND_2013_0F",
            description: "mental ray Standalone 2013"
        },
        {
            featureid: "86020ACDLTM_F",
            description: "AutoCAD LT for Mac Package"
        },
        {
            featureid: "86020ACDLTM_T_F",
            description: "Autodesk AutoCAD LT for Mac Package Term"
        },
        {
            featureid: "86021ACDLTM_2013_0F",
            description: "AutoCAD LT for Mac 2013"
        },
        {
            featureid: "86022NAVMAN_2013_0F",
            description: "Autodesk Navisworks Manage 2013"
        },
        {
            featureid: "86023NAVSIM_2013_0F",
            description: "Autodesk Navisworks Simulate 2013"
        },
        {
            featureid: "86024AQTO_2013_0F",
            description: "Autodesk Quantity Takeoff 2013"
        },
        {
            featureid: "86025SCFD_2013_0F",
            description: "Autodesk Simulation CFD 2013"
        },
        {
            featureid: "86026SCFD_F",
            description: "Autodesk Simulation CFD Package"
        },
        {
            featureid: "86026SCFD_T_F",
            description: "Autodesk CFD Package Term"
        },
        {
            featureid: "86027UTLDESN_2013_0F",
            description: "AutoCAD Utility Design 2013"
        },
        {
            featureid: "86028SCFDA_2013_0F",
            description: "Autodesk Simulation CFD 2013 Advanced"
        },
        {
            featureid: "86029SCFDA_F",
            description: "Autodesk Simulation CFD Advanced Package"
        },
        {
            featureid: "86029SCFDA_T_F",
            description: "Autodesk CFD Advanced Package Term"
        },
        {
            featureid: "86030SCFDNX_2013_0F",
            description: "Autodesk Simulation CFD 2013 Connection for NX"
        },
        {
            featureid: "86031SCFDNX_F",
            description: "Autodesk Simulation CFD Connection for NX Package"
        },
        {
            featureid: "86032SCPROE_2013_0F",
            description: "Autodesk Simulation CFD 2013 Connection for Pro/E"
        },
        {
            featureid: "86033SCPROE_F",
            description: "Autodesk Simulation CFD Connection for Pro/E Package"
        },
        {
            featureid: "86034SCDSE_2013_0F",
            description: "Autodesk Simulation CFD 2013 Design Study Environment"
        },
        {
            featureid: "86035SCDSE_F",
            description: "Autodesk Simulation CFD Design Study Environment Package"
        },
        {
            featureid: "86035SCDSE_T_F",
            description: "Autodesk CFD Design Study Environment Package Term"
        },
        {
            featureid: "86036SCFDM_2013_0F",
            description: "Autodesk Simulation CFD 2013 Motion"
        },
        {
            featureid: "86037SCFDM_F",
            description: "Autodesk Simulation CFD Motion Package"
        },
        {
            featureid: "86037SCFDM_T_F",
            description: "Autodesk CFD Motion Package Term"
        },
        {
            featureid: "86038MFIP_2013_0F",
            description: "Autodesk Simulation Moldflow Insight Premium 2013"
        },
        {
            featureid: "86040MFIA_2013_0F",
            description: "Autodesk Simulation Moldflow Insight Ultimate 2013"
        },
        {
            featureid: "86042MFIB_2013_0F",
            description: "Autodesk Simulation Moldflow Insight Standard 2013"
        },
        {
            featureid: "86043ECSPE_2013_0F",
            description: "Autodesk Entertainment Creation Suite Premium 2013 Exclusives"
        },
        {
            featureid: "86043TRTLPB_2013_0F",
            description: "Turtle For Maya Premium 2013"
        },
        {
            featureid: "86044ECSSE_2013_0F",
            description: "Autodesk Entertainment Creation Suite Standard 2013 Exclusives"
        },
        {
            featureid: "86045SCACIS_2013_0F",
            description: "Autodesk Simulation CFD 2013 Connection for ACIS"
        },
        {
            featureid: "86046SCACIS_F",
            description: "Autodesk Simulation CFD Connection for ACIS Package"
        },
        {
            featureid: "86047SCFDP_2013_0F",
            description: "Autodesk Simulation CFD 2013 Connection for Parasolid"
        },
        {
            featureid: "86048SCFDP_F",
            description: "Autodesk Simulation CFD Connection for Parasolid Package"
        },
        {
            featureid: "86049CADMEP_2013_0F",
            description: "Autodesk Fabrication CADmep 2013"
        },
        {
            featureid: "86050CAMDCT_2013_0F",
            description: "Autodesk Fabrication CAMduct 2013"
        },
        {
            featureid: "86052ESTMEP_2013_0F",
            description: "Autodesk Fabrication ESTmep 2013"
        },
        {
            featureid: "86056SIMDFM_2013_0F",
            description: "Autodesk Simulation DFM 2013"
        },
        {
            featureid: "86058MAYA_2014_0F",
            description: "Autodesk Maya 2014"
        },
        {
            featureid: "86058MAYAMMR1_2014_0F",
            description: "Autodesk Maya Mental Ray 1 2014"
        },
        {
            featureid: "86059T1MF_2013_0F",
            description: "T1 Enterprise Multi-flex 2013"
        },
        {
            featureid: "86061T1MFPV_2013_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2013"
        },
        {
            featureid: "86063ACD_2014_0F",
            description: "AutoCAD 2014"
        },
        {
            featureid: "86064ACDLT_2014_0F",
            description: "AutoCAD LT 2014"
        },
        {
            featureid: "86065OSPRO_2013_0F",
            description: "Autodesk Opticore Studio Professional 2013"
        },
        {
            featureid: "86067REVITS_2014_0F",
            description: "AutoCAD Revit Architecture Suite 2014"
        },
        {
            featureid: "86068RVTLTS_2014_0F",
            description: "AutoCAD Revit LT Suite 2014"
        },
        {
            featureid: "86069REVSYP_2014_0F",
            description: "AutoCAD Revit MEP Suite 2014"
        },
        {
            featureid: "86070REVSU_2014_0F",
            description: "AutoCAD Revit Structure Suite 2014"
        },
        {
            featureid: "86071BDSPRM_2014_0F",
            description: "Autodesk Building Design Suite Premium 2014"
        },
        {
            featureid: "86072BDSS_2014_0F",
            description: "Autodesk Building Design Suite Standard 2014"
        },
        {
            featureid: "86073BDSADV_2014_0F",
            description: "Autodesk Building Design Suite Ultimate 2014"
        },
        {
            featureid: "86074STRDET_2014_0F",
            description: "AutoCAD Structural Detailing 2014"
        },
        {
            featureid: "86075RVT_2014_0F",
            description: "Autodesk Revit 2014"
        },
        {
            featureid: "86076REVIT_2014_0F",
            description: "Autodesk Revit Architecture 2014"
        },
        {
            featureid: "86077RVTLT_2014_0F",
            description: "Autodesk Revit LT 2014"
        },
        {
            featureid: "86078RVTMPB_2014_0F",
            description: "Autodesk Revit MEP 2014"
        },
        {
            featureid: "86079REVITST_2014_0F",
            description: "Autodesk Revit Structure 2014"
        },
        {
            featureid: "860803DSMAX_2014_0F",
            description: "Autodesk 3ds Max 2014"
        },
        {
            featureid: "86081MAXDES_2014_0F",
            description: "Autodesk 3ds Max Design 2014"
        },
        {
            featureid: "86082AUTOST_2014_0F",
            description: "Autodesk Alias Automotive 2014"
        },
        {
            featureid: "86083DESNST_2014_0F",
            description: "Autodesk Alias Design 2014"
        },
        {
            featureid: "86084SURFST_2014_0F",
            description: "Autodesk Alias Surface 2014"
        },
        {
            featureid: "86085ATCIGM_2014_0F",
            description: "Autodesk Alias TC Integrator for GM 2014"
        },
        {
            featureid: "86086RSAPRO_2014_0F",
            description: "Autodesk Robot Structural Analysis Professional 2014"
        },
        {
            featureid: "86087ENCSU_2014_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2014"
        },
        {
            featureid: "86088ACDMAC_2014_0F",
            description: "AutoCAD for Mac 2014"
        },
        {
            featureid: "86089ACDLTM_2014_0F",
            description: "AutoCAD LT for Mac 2014"
        },
        {
            featureid: "86090MXECSP_2014_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium 2014"
        },
        {
            featureid: "86090SFTIMSIB_2014_0F",
            description: "Autodesk Softimage 2014 SIBatch"
        },
        {
            featureid: "86091MXECS_2014_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2014"
        },
        {
            featureid: "86092MYECS_2014_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2014"
        },
        {
            featureid: "86093MYECSP_2014_0F",
            description: "Autodesk Maya Entertainment Creation Suite Premium 2014"
        },
        {
            featureid: "86094SIECS_2014_0F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard 2014"
        },
        {
            featureid: "86095ECSPE_2014_0F",
            description: "Autodesk Entertainment Creation Suite Premium 2014 Exclusives"
        },
        {
            featureid: "86095TRTLPB_2014_0F",
            description: "Turtle For Maya Premium 2014"
        },
        {
            featureid: "86096ECSPE_F",
            description: "Autodesk Entertainment Creation Suite Premium Exclusives Package"
        },
        {
            featureid: "86097ECSSE_2014_0F",
            description: "Autodesk Entertainment Creation Suite Standard 2014 Exclusives"
        },
        {
            featureid: "86098ECSSE_F",
            description: "Autodesk Entertainment Creation Suite Standard Exclusives Package"
        },
        {
            featureid: "86099MOBPRO_2014_0F",
            description: "Autodesk MotionBuilder 2014"
        },
        {
            featureid: "86100MBXPRO_2014_0F",
            description: "Autodesk Mudbox 2014"
        },
        {
            featureid: "86101MRSTND_2014_0F",
            description: "mental ray Standalone 2014"
        },
        {
            featureid: "86102SFTIM_2014_0F",
            description: "Autodesk Softimage 2014"
        },
        {
            featureid: "86103AAA_2014_0F",
            description: "Autodesk Animation Academy 2014"
        },
        {
            featureid: "86104DES_ACA_2014_0F",
            description: "Autodesk Design Academy 2014"
        },
        {
            featureid: "86105ESEC_2014_0F",
            description: "Autodesk Entertainment Creation Suite For Education 2014"
        },
        {
            featureid: "86106PLTDSS_2014_0F",
            description: "Autodesk Plant Design Suite Standard 2014"
        },
        {
            featureid: "86107PDSADV_2014_0F",
            description: "Autodesk Plant Design Suite Ultimate 2014"
        },
        {
            featureid: "86108PDSPRM_2014_0F",
            description: "Autodesk Plant Design Suite Premium 2014"
        },
        {
            featureid: "86109PNID_2014_0F",
            description: "AutoCAD P&ID 2014"
        },
        {
            featureid: "86110PLNT3D_2014_0F",
            description: "AutoCAD Plant 3D 2014"
        },
        {
            featureid: "86111SIMDFM_F",
            description: "Autodesk Simulation DFM Package"
        },
        {
            featureid: "86111SIMDFM_T_F",
            description: "Autodesk Moldflow Design Package Term"
        },
        {
            featureid: "86112EMS_2014_0F",
            description: "Autodesk Education Master Suite 2014"
        },
        {
            featureid: "86113ESME_2014_0F",
            description: "Autodesk Product Design Suite for Education 2014"
        },
        {
            featureid: "86114ASPRO_2014_0F",
            description: "Autodesk Simulation Multiphysics 2014"
        },
        {
            featureid: "86115ASMES_2014_0F",
            description: "Autodesk Simulation Mechanical 2014"
        },
        {
            featureid: "86116ASMES_F",
            description: "Autodesk Simulation Mechanical Package"
        },
        {
            featureid: "86116ASMES_T_F",
            description: "Autodesk Simulation Mechanical Package Term"
        },
        {
            featureid: "86117SHOWCASE_2014_0F",
            description: "Autodesk Showcase 2014"
        },
        {
            featureid: "86118SHOWPRO_2014_0F",
            description: "Autodesk Showcase Professional 2014"
        },
        {
            featureid: "86119DSPRM_2014_0F",
            description: "AutoCAD Design Suite Premium 2014"
        },
        {
            featureid: "86120DSSTD_2014_0F",
            description: "AutoCAD Design Suite Standard 2014"
        },
        {
            featureid: "86121DSADV_2014_0F",
            description: "AutoCAD Design Suite Ultimate 2014"
        },
        {
            featureid: "86122ALSK_2014_0F",
            description: "Autodesk Alias Sketch 2014"
        },
        {
            featureid: "86123IDSP_2014_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2014"
        },
        {
            featureid: "86124IDSS_2014_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2014"
        },
        {
            featureid: "86125IDSU_2014_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2014"
        },
        {
            featureid: "86126UTLDESN_2014_0F",
            description: "Autodesk AutoCAD Utility Design 2014"
        },
        {
            featureid: "86127INVLTS_2014_0F",
            description: "AutoCAD Inventor LT Suite 2014"
        },
        {
            featureid: "86128FDSPRM_2014_0F",
            description: "Autodesk Factory Design Suite Premium 2014"
        },
        {
            featureid: "86129FDSS_2014_0F",
            description: "Autodesk Factory Design Suite Standard 2014"
        },
        {
            featureid: "86130FDSADV_2014_0F",
            description: "Autodesk Factory Design Suite Ultimate 2014"
        },
        {
            featureid: "86131PDSP_2014_0F",
            description: "Autodesk Product Design Suite Premium 2014"
        },
        {
            featureid: "86132PDSS_2014_0F",
            description: "Autodesk Product Design Suite Standard 2014"
        },
        {
            featureid: "86133PDSU_2014_0F",
            description: "Autodesk Product Design Suite Ultimate 2014"
        },
        {
            featureid: "86134ACDLTC_2014_0F",
            description: "AutoCAD LT Civil Suite 2014"
        },
        {
            featureid: "86135INVETO_2014_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2014"
        },
        {
            featureid: "86136INTSER_2014_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2014"
        },
        {
            featureid: "86137ARCHDESK_2014_0F",
            description: "AutoCAD Architecture 2014"
        },
        {
            featureid: "86138AMECH_PP_2014_0F",
            description: "AutoCAD Mechanical 2014"
        },
        {
            featureid: "86139BLDSYS_2014_0F",
            description: "AutoCAD MEP 2014"
        },
        {
            featureid: "86140ARDES_2014_0F",
            description: "AutoCAD Raster Design 2014"
        },
        {
            featureid: "86141SCFD_2014_0F",
            description: "Autodesk Simulation CFD 2014"
        },
        {
            featureid: "86142SCFDA_2014_0F",
            description: "Autodesk Simulation CFD 2014 Advanced"
        },
        {
            featureid: "86143SCACIS_2014_0F",
            description: "Autodesk Simulation CFD 2014 Connection for ACIS"
        },
        {
            featureid: "86144SCFDNX_2014_0F",
            description: "Autodesk Simulation CFD 2014 Connection for NX"
        },
        {
            featureid: "86145SCFDP_2014_0F",
            description: "Autodesk Simulation CFD 2014 Connection for Parasolid"
        },
        {
            featureid: "86146SCPROE_2014_0F",
            description: "Autodesk Simulation CFD 2014 Connection for Pro/E"
        },
        {
            featureid: "86147SCDSE_2014_0F",
            description: "Autodesk Simulation CFD 2014 Design Study Environment"
        },
        {
            featureid: "86148SCFDM_2014_0F",
            description: "Autodesk Simulation CFD 2014 Motion"
        },
        {
            featureid: "86149ECSCAD_2014_0F",
            description: "AutoCAD ecscad 2014"
        },
        {
            featureid: "86150ACAD_E_2014_0F",
            description: "AutoCAD Electrical 2014"
        },
        {
            featureid: "86151SIMDFM_2014_0F",
            description: "Autodesk Simulation DFM 2014"
        },
        {
            featureid: "86152MFAA_2014_0F",
            description: "Autodesk Simulation Moldflow Adviser Ultimate 2014"
        },
        {
            featureid: "86153MFAM_2014_0F",
            description: "Autodesk Simulation Moldflow Adviser Premium 2014"
        },
        {
            featureid: "86154MFAD_2014_0F",
            description: "Autodesk Simulation Moldflow Adviser Standard 2014"
        },
        {
            featureid: "86155MFIP_2014_0F",
            description: "Autodesk Simulation Moldflow Insight Premium 2014"
        },
        {
            featureid: "86156MFIA_2014_0F",
            description: "Autodesk Simulation Moldflow Insight Ultimate 2014"
        },
        {
            featureid: "86157MFIB_2014_0F",
            description: "Autodesk Simulation Moldflow Insight Standard 2014"
        },
        {
            featureid: "86158MFS_2014_0F",
            description: "Autodesk Simulation Moldflow Synergy 2014"
        },
        {
            featureid: "86159INVNTOR_2014_0F",
            description: "Autodesk Inventor 2014"
        },
        {
            featureid: "86161INVLT_2014_0F",
            description: "Autodesk Inventor LT 2014"
        },
        {
            featureid: "86162INVOEM_2014_0F",
            description: "Autodesk Inventor OEM 2014"
        },
        {
            featureid: "86163INVPROSA_2014_0F",
            description: "Autodesk Inventor Professional 2014"
        },
        {
            featureid: "86165GMCADC_2014_0F",
            description: "Autodesk GM CAD Convertors 2014"
        },
        {
            featureid: "86166NAVMAN_2014_0F",
            description: "Autodesk Navisworks Manage 2014"
        },
        {
            featureid: "86167VLTC_2014_0F",
            description: "Autodesk Vault Collaboration 2014"
        },
        {
            featureid: "86168VTCAEC_2014_0F",
            description: "Autodesk Vault Collaboration AEC 2014"
        },
        {
            featureid: "86169PCOFFI_2014_0F",
            description: "Autodesk Vault Office 2014"
        },
        {
            featureid: "86170VLTM_2014_0F",
            description: "Autodesk Vault Professional 2014"
        },
        {
            featureid: "86171VLTWG_2014_0F",
            description: "Autodesk Vault Workgroup 2014"
        },
        {
            featureid: "86172NAVSIM_2014_0F",
            description: "Autodesk Navisworks Simulate 2014"
        },
        {
            featureid: "86173CIV3D_2014_0F",
            description: "AutoCAD Civil 3D 2014"
        },
        {
            featureid: "86174MAP_2014_0F",
            description: "AutoCAD Map 3D 2014"
        },
        {
            featureid: "86175INFMDR_2014_0F",
            description: "Autodesk InfraWorks 2014"
        },
        {
            featureid: "86176IMS_2014_0F",
            description: "Autodesk Infrastructure Map Server 2014"
        },
        {
            featureid: "86177MFCD_2014_0F",
            description: "CADdoctor for Autodesk Simulation 2014"
        },
        {
            featureid: "86178CAMDCT_2014_0F",
            description: "Autodesk Fabrication CAMduct 2014"
        },
        {
            featureid: "86179CAMDCT_F",
            description: "Autodesk Fabrication CAMduct Package"
        },
        {
            featureid: "86179CAMDCT_T_F",
            description: "Autodesk Fabrication CAMduct Package Term"
        },
        {
            featureid: "86180INVPUB_2014_0F",
            description: "Autodesk Inventor Publisher 2014"
        },
        {
            featureid: "86181CAMLTE_2014_0F",
            description: "Autodesk Fabrication CAMduct Components 2014"
        },
        {
            featureid: "86182CAMLTE_F",
            description: "Autodesk Fabrication CAMduct Components Package"
        },
        {
            featureid: "86184CADMEP_2014_0F",
            description: "Autodesk Fabrication CADmep 2014"
        },
        {
            featureid: "86185ESTMEP_2014_0F",
            description: "Autodesk Fabrication ESTmep 2014"
        },
        {
            featureid: "86186ESTMEP_F",
            description: "Autodesk Fabrication ESTmep Package"
        },
        {
            featureid: "86186ESTMEP_T_F",
            description: "Autodesk Fabrication ESTmep Package Term"
        },
        {
            featureid: "86187RMNTRY_2014_0F",
            description: "Autodesk Fabrication RemoteEntry 2014"
        },
        {
            featureid: "86188RMNTRY_F",
            description: "Autodesk Fabrication RemoteEntry Package"
        },
        {
            featureid: "86189TRCKIT_2014_0F",
            description: "Autodesk Fabrication Tracker 2014"
        },
        {
            featureid: "86190TRCKIT_F",
            description: "Autodesk Fabrication Tracker Package"
        },
        {
            featureid: "86191T1MF_2014_0F",
            description: "T1 Enterprise Multi-flex 2014"
        },
        {
            featureid: "86193T1MFPV_2014_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2014"
        },
        {
            featureid: "86195OSPRO_2014_0F",
            description: "Autodesk Opticore Studio Professional 2014"
        },
        {
            featureid: "86196RTRTCL_2014_0F",
            description: "Autodesk Real-Time Ray Tracing Cluster 2014"
        },
        {
            featureid: "86197IMRH_2014_0F",
            description: "Autodesk InfraWorks Roads & Highways 2014"
        },
        {
            featureid: "86198FDU_2014_0F",
            description: "Autodesk Factory Design Utilities 2014"
        },
        {
            featureid: "86199FDU_F",
            description: "Autodesk Factory Design Utilities Package"
        },
        {
            featureid: "86199FDU_T_F",
            description: "Autodesk Factory Design Utilities Package Term"
        },
        {
            featureid: "86200SMKMAC_2014_0F",
            description: "Autodesk Smoke 2014 for Mac OS X"
        },
        {
            featureid: "86200SMKMACBRN_2014_0F",
            description: "Autodesk Smoke 2014 for Mac OS X Burn"
        },
        {
            featureid: "86200SMKMACWG_2014_0F",
            description: "Autodesk Smoke Wiretap Gateway 2014"
        },
        {
            featureid: "86201INVPUB_2013_1F",
            description: "Autodesk Inventor Publisher 2013 R1"
        },
        {
            featureid: "86202INVPUB_F",
            description: "Autodesk Inventor Publisher Package"
        },
        {
            featureid: "86203SBPNL_2014_0F",
            description: "Autodesk SketchBook Pro for Enterprise 2014"
        },
        {
            featureid: "86204SBPNL_F",
            description: "Autodesk SketchBook Pro for Enterprise Package"
        },
        {
            featureid: "86204SBPNL_T_F",
            description: "Autodesk SketchBook for Enterprise Package Term"
        },
        {
            featureid: "86205IMS5A_2014_0F",
            description: "Autodesk Infrastructure Map Server 5 Activations 2014"
        },
        {
            featureid: "86206ADKVRD_2014_0F",
            description: "Autodesk VRED 2014"
        },
        {
            featureid: "86207VRDDES_2014_0F",
            description: "Autodesk VRED Design 2014"
        },
        {
            featureid: "86208VRDPRO_2014_0F",
            description: "Autodesk VRED Professional 2014"
        },
        {
            featureid: "86209VRDSRV_2014_0F",
            description: "Autodesk VRED Server 2014"
        },
        {
            featureid: "86210VRDPRS_2014_0F",
            description: "Autodesk VRED Presenter 2014"
        },
        {
            featureid: "86211DCMVRD_2014_0F",
            description: "Autodesk Display Cluster Module for Autodesk VRED Design 2014"
        },
        {
            featureid: "86212RCMVRD_2014_0F",
            description: "Autodesk Raytracing Cluster Module for Autodesk VRED 2014"
        },
        {
            featureid: "86213MAYA_2015_0F",
            description: "Autodesk Maya 2015"
        },
        {
            featureid: "86213MAYAMMR1_2015_0F",
            description: "Autodesk Maya 2015 Mental Ray 1"
        },
        {
            featureid: "86214MAYA_F",
            description: "Maya Package"
        },
        {
            featureid: "86214TRTLPB_2015_0F",
            description: "Turtle for Maya 2015"
        },
        {
            featureid: "86216T1MFSB_2014_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2014"
        },
        {
            featureid: "86219ATCIGM_2015_0F",
            description: "Autodesk Alias TC Integrator for GM 2015"
        },
        {
            featureid: "86220GMCADC_2015_0F",
            description: "Autodesk GM CAD Convertors 2015"
        },
        {
            featureid: "86221AUTOST_2015_0F",
            description: "Autodesk Alias Automotive 2015"
        },
        {
            featureid: "86222DESNST_2015_0F",
            description: "Autodesk Alias Design 2015"
        },
        {
            featureid: "86223SURFST_2015_0F",
            description: "Autodesk Alias Surface 2015"
        },
        {
            featureid: "86226FRMITP_2014_0F",
            description: "Autodesk Vasari"
        },
        {
            featureid: "86227DSPRM_2015_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2015"
        },
        {
            featureid: "86228DSSTD_2015_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2015"
        },
        {
            featureid: "86229DSADV_2015_0F",
            description: "Autodesk AutoCAD Design Suite Ultimate 2015"
        },
        {
            featureid: "86232ACDLTC_2015_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2015"
        },
        {
            featureid: "86233INVLTS_2015_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2015"
        },
        {
            featureid: "86234REVITS_2015_0F",
            description: "Autodesk AutoCAD Revit Architecture Suite 2015"
        },
        {
            featureid: "86235RVTLTS_2015_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2015"
        },
        {
            featureid: "86236REVSYP_2015_0F",
            description: "Autodesk AutoCAD Revit MEP Suite 2015"
        },
        {
            featureid: "86237REVSU_2015_0F",
            description: "Autodesk AutoCAD Revit Structure Suite 2015"
        },
        {
            featureid: "86238BDSPRM_2015_0F",
            description: "Autodesk Building Design Suite Premium 2015"
        },
        {
            featureid: "86240T1MFPB_F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle Package"
        },
        {
            featureid: "86241MXECSP_2015_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Premium 2015"
        },
        {
            featureid: "86242MXECS_2015_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2015"
        },
        {
            featureid: "86243ENCSU_2015_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2015"
        },
        {
            featureid: "86244BDSS_2015_0F",
            description: "Autodesk Building Design Suite Standard 2015"
        },
        {
            featureid: "86245BDSADV_2015_0F",
            description: "Autodesk Building Design Suite Ultimate 2015"
        },
        {
            featureid: "86246DES_ACA_2015_0F",
            description: "Autodesk Design Academy 2015"
        },
        {
            featureid: "86247EMS_2015_0F",
            description: "Autodesk Education Master Suite 2015"
        },
        {
            featureid: "86248ESEC_2015_0F",
            description: "Autodesk Entertainment Creation Suite For Education 2015"
        },
        {
            featureid: "86249FDSPRM_2015_0F",
            description: "Autodesk Factory Design Suite Premium 2015"
        },
        {
            featureid: "86250FDSS_2015_0F",
            description: "Autodesk Factory Design Suite Standard 2015"
        },
        {
            featureid: "86251FDSADV_2015_0F",
            description: "Autodesk Factory Design Suite Ultimate 2015"
        },
        {
            featureid: "86252IDSP_2015_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2015"
        },
        {
            featureid: "86253IDSS_2015_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2015"
        },
        {
            featureid: "86254IDSU_2015_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2015"
        },
        {
            featureid: "86255INVETO_2015_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2015"
        },
        {
            featureid: "86256MYECSP_2015_0F",
            description: "Autodesk Maya Entertainment Creation Suite Premium 2015"
        },
        {
            featureid: "86257MYECS_2015_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2015"
        },
        {
            featureid: "86258PDSPRM_2015_0F",
            description: "Autodesk Plant Design Suite Premium 2015"
        },
        {
            featureid: "86259PLTDSS_2015_0F",
            description: "Autodesk Plant Design Suite Standard 2015"
        },
        {
            featureid: "86260PDSADV_2015_0F",
            description: "Autodesk Plant Design Suite Ultimate 2015"
        },
        {
            featureid: "86261ESME_2015_0F",
            description: "Autodesk Product Design Suite for Education 2015"
        },
        {
            featureid: "862623DSMAX_2015_0F",
            description: "Autodesk 3ds Max 2015"
        },
        {
            featureid: "86263MAXDES_2015_0F",
            description: "Autodesk 3ds Max Design 2015"
        },
        {
            featureid: "86264MOBPRO_2015_0F",
            description: "Autodesk MotionBuilder 2015"
        },
        {
            featureid: "86265MBXPRO_2015_0F",
            description: "Autodesk Mudbox 2015"
        },
        {
            featureid: "86266PDSP_2015_0F",
            description: "Autodesk Product Design Suite Premium 2015"
        },
        {
            featureid: "86267PDSS_2015_0F",
            description: "Autodesk Product Design Suite Standard 2015"
        },
        {
            featureid: "86268PDSU_2015_0F",
            description: "Autodesk Product Design Suite Ultimate 2015"
        },
        {
            featureid: "86269SFTIM_2015_0F",
            description: "Autodesk Softimage 2015"
        },
        {
            featureid: "86269SFTIMSIB_2015_0F",
            description: "Autodesk Softimage 2015 SIBatch"
        },
        {
            featureid: "86270SIECS_2015_0F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard 2015"
        },
        {
            featureid: "86271MRSTND_2015_0F",
            description: "mental ray Standalone 2015"
        },
        {
            featureid: "86272AAA_2015_0F",
            description: "Autodesk Animation Academy 2015"
        },
        {
            featureid: "86273RVT_2015_0F",
            description: "Autodesk Revit 2015"
        },
        {
            featureid: "86274REVIT_2015_0F",
            description: "Autodesk Revit Architecture 2015"
        },
        {
            featureid: "86275RVTLT_2015_0F",
            description: "Autodesk Revit LT 2015"
        },
        {
            featureid: "86276RVTMPB_2015_0F",
            description: "Autodesk Revit MEP 2015"
        },
        {
            featureid: "86277REVITST_2015_0F",
            description: "Autodesk Revit Structure 2015"
        },
        {
            featureid: "86279AMECH_PP_2015_0F",
            description: "Autodesk AutoCAD Mechanical 2015"
        },
        {
            featureid: "86280STRDET_2015_0F",
            description: "Autodesk AutoCAD Structural Detailing 2015"
        },
        {
            featureid: "86281CADMEP_2015_0F",
            description: "Autodesk Fabrication CADmep 2015"
        },
        {
            featureid: "86282CADMEP_F",
            description: "Autodesk Fabrication CADmep Package"
        },
        {
            featureid: "86282CADMEP_T_F",
            description: "Autodesk Fabrication CADmep Package Term"
        },
        {
            featureid: "86283CAMDCT_2015_0F",
            description: "Autodesk Fabrication CAMduct 2015"
        },
        {
            featureid: "86284CAMLTE_2015_0F",
            description: "Autodesk Fabrication CAMduct Components 2015"
        },
        {
            featureid: "86285ESTMEP_2015_0F",
            description: "Autodesk Fabrication ESTmep 2015"
        },
        {
            featureid: "86286RMNTRY_2015_0F",
            description: "Autodesk Fabrication RemoteEntry 2015"
        },
        {
            featureid: "86287TRCKIT_2015_0F",
            description: "Autodesk Fabrication Tracker 2015"
        },
        {
            featureid: "86288ARCHDESK_2015_0F",
            description: "Autodesk AutoCAD Architecture 2015"
        },
        {
            featureid: "86289ECSCAD_2015_0F",
            description: "Autodesk AutoCAD ecscad 2015"
        },
        {
            featureid: "86290ACAD_E_2015_0F",
            description: "Autodesk AutoCAD Electrical 2015"
        },
        {
            featureid: "86291ACDLTM_2015_0F",
            description: "Autodesk AutoCAD LT 2015 for Mac"
        },
        {
            featureid: "86292BLDSYS_2015_0F",
            description: "Autodesk AutoCAD MEP 2015"
        },
        {
            featureid: "86293ACDLT_2015_0F",
            description: "Autodesk AutoCAD LT 2015"
        },
        {
            featureid: "86294ARDES_2015_0F",
            description: "Autodesk AutoCAD Raster Design 2015"
        },
        {
            featureid: "86295ACD_2015_0F",
            description: "Autodesk AutoCAD 2015"
        },
        {
            featureid: "86296PLNT3D_2015_0F",
            description: "Autodesk AutoCAD Plant 3D 2015"
        },
        {
            featureid: "86297RSAPRO_2015_0F",
            description: "Autodesk Robot Structural Analysis Professional 2015"
        },
        {
            featureid: "86298PNTLAY_2014_0F",
            description: "Autodesk Point Layout 2014"
        },
        {
            featureid: "86299IW360P_2014_0F",
            description: "Autodesk InfraWorks 360 2014"
        },
        {
            featureid: "86300ECSCAD_2014",
            description: "Autodesk AutoCAD ecscad 2014"
        },
        {
            featureid: "86300ECSCAD_2014_1F",
            description: "Autodesk AutoCAD ecscad 2014 R2"
        },
        {
            featureid: "86301PNID_2015_0F",
            description: "Autodesk AutoCAD P&ID 2015"
        },
        {
            featureid: "86303ACDMAC_2015_0F",
            description: "Autodesk AutoCAD 2015 for Mac"
        },
        {
            featureid: "86304PCOFFI_2015_0F",
            description: "Autodesk Vault Office 2015"
        },
        {
            featureid: "86305VLTM_2015_0F",
            description: "Autodesk Vault Professional 2015"
        },
        {
            featureid: "86306VLTWG_2015_0F",
            description: "Autodesk Vault Workgroup 2015"
        },
        {
            featureid: "86307VRDDES_F",
            description: "Autodesk VRED Design Package"
        },
        {
            featureid: "86307VRDDES_T_F",
            description: "Autodesk VRED Design Package Term"
        },
        {
            featureid: "86308DCMVRD_F",
            description: "Autodesk Display Cluster Module for Autodesk VRED Design Package"
        },
        {
            featureid: "86309RCMVRD_F",
            description: "Autodesk Raytracing Cluster Module for Autodesk VRED Package"
        },
        {
            featureid: "86309RCMVRD_T_F",
            description: "Autodesk VRED Render Node Package Term"
        },
        {
            featureid: "86310ADKVRD_F",
            description: "Autodesk VRED Package"
        },
        {
            featureid: "86310ADKVRD_T_F",
            description: "Autodesk VRED Package Term"
        },
        {
            featureid: "86311VRDPRS_F",
            description: "Autodesk VRED Presenter Package"
        },
        {
            featureid: "86311VRDPRS_T_F",
            description: "Autodesk VRED Presenter Package Term"
        },
        {
            featureid: "86312VRDPRO_F",
            description: "Autodesk VRED Professional Package"
        },
        {
            featureid: "86312VRDPRO_T_F",
            description: "Autodesk VRED Professional Package Term"
        },
        {
            featureid: "86313VRDSRV_F",
            description: "Autodesk VRED Server Package"
        },
        {
            featureid: "86313VRDSRV_T_F",
            description: "Autodesk VRED Server Package Term"
        },
        {
            featureid: "86314INVNTOR_2015_0F",
            description: "Autodesk Inventor 2015"
        },
        {
            featureid: "86315INTSER_2015_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2015"
        },
        {
            featureid: "86316INVLT_2015_0F",
            description: "Autodesk Inventor LT 2015"
        },
        {
            featureid: "86317INVOEM_2015_0F",
            description: "Autodesk Inventor OEM 2015"
        },
        {
            featureid: "86318INVPROSA_2015_0F",
            description: "Autodesk Inventor Professional 2015"
        },
        {
            featureid: "86319SIMDFM_2015_0F",
            description: "Autodesk Simulation DFM 2015"
        },
        {
            featureid: "86320MFAM_2015_0F",
            description: "Autodesk Simulation Moldflow Adviser Premium 2015"
        },
        {
            featureid: "86321MFAD_2015_0F",
            description: "Autodesk Simulation Moldflow Adviser Standard 2015"
        },
        {
            featureid: "86322MFAA_2015_0F",
            description: "Autodesk Simulation Moldflow Adviser Ultimate 2015"
        },
        {
            featureid: "86323MFIP_2015_0F",
            description: "Autodesk Simulation Moldflow Insight Premium 2015"
        },
        {
            featureid: "86324MFIB_2015_0F",
            description: "Autodesk Simulation Moldflow Insight Standard 2015"
        },
        {
            featureid: "86325MFIA_2015_0F",
            description: "Autodesk Simulation Moldflow Insight Ultimate 2015"
        },
        {
            featureid: "86326MFS_2015_0F",
            description: "Autodesk Simulation Moldflow Synergy 2015"
        },
        {
            featureid: "86327MFCD_2015_0F",
            description: "CADdoctor for Autodesk Simulation 2015"
        },
        {
            featureid: "86328SBRDES_2014_0F",
            description: "Autodesk Structural Bridge Design 2014"
        },
        {
            featureid: "86328SBRDES_2016_0F",
            description: "Autodesk Structural Bridge Design 2016"
        },
        {
            featureid: "86329SBRDES_F",
            description: "Autodesk Structural Bridge Design Package"
        },
        {
            featureid: "86329SBRDES_T_F",
            description: "Autodesk Structural Bridge Design Package Term"
        },
        {
            featureid: "86330TSPRHN_4_0F",
            description: "Autodesk T-Splines Plug-in 4.0 for Rhino 2015"
        },
        {
            featureid: "86332RDIW360P_F",
            description: "Autodesk Roadway Design for InfraWorks 360 Pro Package"
        },
        {
            featureid: "86333VEHTRK_2014_0F",
            description: "Autodesk Vehicle Tracking 2014"
        },
        {
            featureid: "86334VEHTRK_F",
            description: "Autodesk Vehicle Tracking Package"
        },
        {
            featureid: "86334VEHTRK_T_F",
            description: "Autodesk Vehicle Tracking Package Term"
        },
        {
            featureid: "86335FDU_2015_0F",
            description: "Autodesk Factory Design Utilities 2015"
        },
        {
            featureid: "86336SCFD_2015_0F",
            description: "Autodesk Simulation CFD 2015"
        },
        {
            featureid: "86337SCFDA_2015_0F",
            description: "Autodesk Simulation CFD 2015 Advanced"
        },
        {
            featureid: "86338SCACIS_2015_0F",
            description: "Autodesk Simulation CFD 2015 Connection for ACIS"
        },
        {
            featureid: "86339SCFDNX_2015_0F",
            description: "Autodesk Simulation CFD 2015 Connection for NX"
        },
        {
            featureid: "86340SCFDP_2015_0F",
            description: "Autodesk Simulation CFD 2015 Connection for Parasolid"
        },
        {
            featureid: "86341SCPROE_2015_0F",
            description: "Autodesk Simulation CFD 2015 Connection for Pro/E"
        },
        {
            featureid: "86342SCDSE_2015_0F",
            description: "Autodesk Simulation CFD 2015 Design Study Environment"
        },
        {
            featureid: "86343SCFDM_2015_0F",
            description: "Autodesk Simulation CFD 2015 Motion"
        },
        {
            featureid: "86344ACMPAN_2015_0F",
            description: "Autodesk Simulation Composite Analysis 2015"
        },
        {
            featureid: "86345ACMPAN_F",
            description: "Autodesk Simulation Composite Analysis Package"
        },
        {
            featureid: "86345ACMPAN_T_F",
            description: "Autodesk Helius PFA Package Term"
        },
        {
            featureid: "86346ACMPDS_2015_0F",
            description: "Autodesk Simulation Composite Design 2015"
        },
        {
            featureid: "86347ACMPDS_F",
            description: "Autodesk Simulation Composite Design Package"
        },
        {
            featureid: "86347ACMPDS_T_F",
            description: "Autodesk Helius Composite Package Term"
        },
        {
            featureid: "86348ASMES_2015_0F",
            description: "Autodesk Simulation Mechanical 2015"
        },
        {
            featureid: "86349ASPRO_2015_0F",
            description: "Autodesk Simulation Multiphysics 2015"
        },
        {
            featureid: "86350NAVMAN_2015_0F",
            description: "Autodesk Navisworks Manage 2015"
        },
        {
            featureid: "86351NAVSIM_2015_0F",
            description: "Autodesk Navisworks Simulate 2015"
        },
        {
            featureid: "86352SBPNL_2015_0F",
            description: "Autodesk SketchBook Pro 2015"
        },
        {
            featureid: "86354CIV3D_2015_0F",
            description: "Autodesk AutoCAD Civil 3D 2015"
        },
        {
            featureid: "86355MAP_2015_0F",
            description: "Autodesk AutoCAD Map 3D 2015"
        },
        {
            featureid: "86356UTLDESN_2015_0F",
            description: "Autodesk AutoCAD Utility Design 2015"
        },
        {
            featureid: "86357IMS_2015_0F",
            description: "Autodesk Infrastructure Map Server 2015"
        },
        {
            featureid: "86358IMS5A_2015_0F",
            description: "Autodesk Infrastructure Map Server 5 Activations 2015"
        },
        {
            featureid: "86359INFMDR_2015_0F",
            description: "Autodesk InfraWorks 2015"
        },
        {
            featureid: "86360IMRH_2015_0F",
            description: "Autodesk InfraWorks bundle -- Roads & Highways 2015"
        },
        {
            featureid: "86361IMRH_F",
            description: "Autodesk InfraWorks bundle -- Roads & Highways Package"
        },
        {
            featureid: "86362ACLTNL_2015_0F",
            description: "Autodesk AutoCAD LT 2015 Flexible License"
        },
        {
            featureid: "86363ACLTNL_F",
            description: "Autodesk AutoCAD LT Flexible License Package"
        },
        {
            featureid: "86366VRDPRS_2015_0F",
            description: "Autodesk VRED Presenter 2015"
        },
        {
            featureid: "86367VRDPRO_2015_0F",
            description: "Autodesk VRED Professional 2015"
        },
        {
            featureid: "86368CADMEP_F",
            description: "Autodesk Fabrication CADmep Package"
        },
        {
            featureid: "86369SHOWCASE_2015_0F",
            description: "Autodesk Showcase 2015"
        },
        {
            featureid: "86370SHOWPRO_2015_0F",
            description: "Autodesk Showcase Professional 2015"
        },
        {
            featureid: "86371ALAUST_2015_0F",
            description: "Autodesk Alias AutoStudio 2015"
        },
        {
            featureid: "86372ALAUST_F",
            description: "Autodesk Alias AutoStudio Package"
        },
        {
            featureid: "86372ALAUST_T_F",
            description: "Autodesk Alias AutoStudio Package Term"
        },
        {
            featureid: "86373ADSTCP_2015_0F",
            description: "Autodesk Advance Concrete 2015"
        },
        {
            featureid: "86374ADSTCP_F",
            description: "Autodesk Advance Concrete Package"
        },
        {
            featureid: "86374ADSTCP_T_F",
            description: "Autodesk Advance Concrete Package Term"
        },
        {
            featureid: "86375ADSTPR_2015_0F",
            description: "Autodesk Advance Steel 2015"
        },
        {
            featureid: "86376ADSTPR_F",
            description: "Autodesk Advance Steel Package"
        },
        {
            featureid: "86376ADSTPR_T_F",
            description: "Autodesk Advance Steel Package Term"
        },
        {
            featureid: "86377SMKMAC_2015_0F",
            description: "Autodesk Smoke 2015 for Mac OS X"
        },
        {
            featureid: "86377SMKMACBRN_2015_0F",
            description: "Autodesk Smoke 2015 for Mac OS X Burn"
        },
        {
            featureid: "86377SMKMACWG_2015_0F",
            description: "Autodesk Smoke Wiretap Gateway 2015"
        },
        {
            featureid: "86378VRDDES_2015_0F",
            description: "Autodesk VRED Design 2015"
        },
        {
            featureid: "86379ADKVRD_2015_0F",
            description: "Autodesk VRED 2015"
        },
        {
            featureid: "86380VRDSRV_2015_0F",
            description: "Autodesk VRED Server 2015"
        },
        {
            featureid: "86381ASPACD_2015_0F",
            description: "Autodesk Advance Steel 2015 with AutoCAD"
        },
        {
            featureid: "86382ASPACD_F",
            description: "Autodesk Advance Steel with AutoCAD Package"
        },
        {
            featureid: "86382ASPACD_T_F",
            description: "Autodesk Advance Steel with AutoCAD Package Term"
        },
        {
            featureid: "86383DCMVRD_2015_0F",
            description: "Autodesk Display Cluster Module for Autodesk VRED Design 2015"
        },
        {
            featureid: "86384PNTLAY_2015_0F",
            description: "Autodesk Point Layout 2015"
        },
        {
            featureid: "86385PNTLAY_F",
            description: "Autodesk Point Layout Bundle"
        },
        {
            featureid: "86385PNTLAY_T_F",
            description: "Autodesk Point Layout Package Term"
        },
        {
            featureid: "86386RCMVRD_2015_0F",
            description: "Autodesk Raytracing Cluster Module for Autodesk VRED 2015"
        },
        {
            featureid: "86387MFIP_F",
            description: "Autodesk Simulation Moldflow Insight Premium Package"
        },
        {
            featureid: "86387MFIP_T_F",
            description: "Autodesk Moldflow Insight Premium Package Term"
        },
        {
            featureid: "86388VEHTRK_2015_0F",
            description: "Autodesk Vehicle Tracking 2015"
        },
        {
            featureid: "86389ACMPAN_F",
            description: "Autodesk Simulation Composite Analysis Package"
        },
        {
            featureid: "86390IW360P_2015_0F",
            description: "Autodesk InfraWorks 360 2015"
        },
        {
            featureid: "86391IW360P_F",
            description: "Autodesk InfraWorks 360 Package"
        },
        {
            featureid: "86391IW360P_T_F",
            description: "Autodesk InfraWorks 360 Package Term"
        },
        {
            featureid: "86392INVHSM_2015_0F",
            description: "Autodesk Inventor HSM 2015"
        },
        {
            featureid: "86393INVHSM_F",
            description: "Autodesk Inventor HSM Package"
        },
        {
            featureid: "86393INVHSM_T_F",
            description: "Autodesk Inventor HSM Package Term"
        },
        {
            featureid: "86394INHSMP_2015_0F",
            description: "Autodesk Inventor HSM Pro 2015"
        },
        {
            featureid: "86395INHSMP_F",
            description: "Autodesk Inventor HSM Pro Package"
        },
        {
            featureid: "86395INHSMP_T_F",
            description: "Autodesk Inventor HSM Pro Package Term"
        },
        {
            featureid: "86396IW360C_2015_0F",
            description: "Autodesk InfraWorks 360 - companion 2015"
        },
        {
            featureid: "86397IW360C_F",
            description: "Autodesk InfraWorks 360 - companion Package"
        },
        {
            featureid: "863983DSMXS_2015_0F",
            description: "Autodesk 3ds Max with SoftImage 2015"
        },
        {
            featureid: "863993DSMXS_F",
            description: "Autodesk 3ds Max with SoftImage Package"
        },
        {
            featureid: "863993DSMXS_T_F",
            description: "Autodesk 3ds Max with SoftImage Package Term"
        },
        {
            featureid: "86400MAYAS_2015_0F",
            description: "Autodesk Maya with SoftImage 2015"
        },
        {
            featureid: "86401MAYAS_F",
            description: "Autodesk Maya with SoftImage Package"
        },
        {
            featureid: "86401MAYAS_T_F",
            description: "Autodesk Maya with Softimage Package Term"
        },
        {
            featureid: "86402RECAP_2015_0F",
            description: "Autodesk ReCap 2015"
        },
        {
            featureid: "86403RECAP_F",
            description: "Autodesk ReCap 360 Ultimate Package"
        },
        {
            featureid: "86403RECAP_T_F",
            description: "Autodesk ReCap 360 Pro Package Term"
        },
        {
            featureid: "86404HSMPRM_2015_0F",
            description: "Autodesk HSMWorks Premium 2015"
        },
        {
            featureid: "86405HSMPRM_F",
            description: "Autodesk HSMWorks Premium Package"
        },
        {
            featureid: "86405HSMPRM_T_F",
            description: "HSMWorks Ultimate Package Term"
        },
        {
            featureid: "86406HSMPRO_2015_0F",
            description: "Autodesk HSMWorks Professional 2015"
        },
        {
            featureid: "86407HSMPRO_F",
            description: "Autodesk HSMWorks Professional Package"
        },
        {
            featureid: "86407HSMPRO_T_F",
            description: "HSMWorks Premium Package Term"
        },
        {
            featureid: "86408T1MF_2015_0F",
            description: "T1 Enterprise Multi-flex 2015"
        },
        {
            featureid: "86409T1MF_F",
            description: "T1 Enterprise Multi-flex Package"
        },
        {
            featureid: "86410INVPUB_2015_0F",
            description: "Autodesk Inventor Publisher 2015"
        },
        {
            featureid: "86412T1MFPV_2015_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2015"
        },
        {
            featureid: "86413T1MFPV_F",
            description: "T1 Enterprise Multi-flex Prior Version Package"
        },
        {
            featureid: "86414T1MFSB_2015_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2015"
        },
        {
            featureid: "86415T1MFSB_F",
            description: "T1 Enterprise Multi-flex Standard Bundle Package"
        },
        {
            featureid: "86416T1MFPB_2015_0F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle 2015"
        },
        {
            featureid: "86417EMFEB_2015_0F",
            description: "Enterprise Multi-Flex Enhanced Bundle 2015"
        },
        {
            featureid: "86419BRDGMD_2015_0F",
            description: "Autodesk Bridge Module 2015"
        },
        {
            featureid: "86420BRDGMD_F",
            description: "Autodesk Bridge Module Package"
        },
        {
            featureid: "86421GEOMOD_2015_0F",
            description: "Autodesk Geotechnical Module 2015"
        },
        {
            featureid: "86422GEOMOD_F",
            description: "Autodesk Geotechnical Module Package"
        },
        {
            featureid: "86423RFAMOD_2015_0F",
            description: "Autodesk River and Flood Analysis Module 2015"
        },
        {
            featureid: "86424RFAMOD_F",
            description: "Autodesk River and Flood Analysis Module Package"
        },
        {
            featureid: "86425SMKDS_2015_0F",
            description: "Autodesk Smoke 2015 Desktop Rental"
        },
        {
            featureid: "86426SMKDS_F",
            description: "Autodesk Smoke Desktop Rental Package"
        },
        {
            featureid: "86426SMKDS_T_F",
            description: "Autodesk Smoke Desktop Rental Package Term"
        },
        {
            featureid: "86427ALAUST_2016_0F",
            description: "Autodesk Alias AutoStudio 2016"
        },
        {
            featureid: "86428DESNST_2016_0F",
            description: "Autodesk Alias Design 2016"
        },
        {
            featureid: "86429SURFST_2016_0F",
            description: "Autodesk Alias Surface 2016"
        },
        {
            featureid: "86430ATCIGM_2016_0F",
            description: "Autodesk Alias TC Integrator for GM 2016"
        },
        {
            featureid: "86431AAA_2016_0F",
            description: "Autodesk Animation Academy 2016"
        },
        {
            featureid: "86432DSADV_2016_0F",
            description: "Autodesk AutoCAD Design Suite Ultimate 2016"
        },
        {
            featureid: "86433EMS_2016_0F",
            description: "Autodesk Education Master Suite 2016"
        },
        {
            featureid: "86434ESEC_2016_0F",
            description: "Autodesk Education Suite for Entertainment Creation 2016"
        },
        {
            featureid: "86435ENCSU_2016_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2016"
        },
        {
            featureid: "86436GMCADC_2016_0F",
            description: "Autodesk GM CAD Convertors 2016"
        },
        {
            featureid: "86437MAYA_2016_0F",
            description: "Autodesk Maya 2016"
        },
        {
            featureid: "86437MAYAMMR1_2016_0F",
            description: "Autodesk Maya 2016 Mental Ray 1"
        },
        {
            featureid: "86437TRTLPB_2016_0F",
            description: "Turtle For Maya Premium 2016"
        },
        {
            featureid: "86438MYECS_2016_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2016"
        },
        {
            featureid: "86439RSAPRO_2016_0F",
            description: "Autodesk Robot Structural Analysis Professional 2016"
        },
        {
            featureid: "86440SBPNL_2016_0F",
            description: "Autodesk SketchBook Pro 2016"
        },
        {
            featureid: "86441MAYAS_2016_0F",
            description: "Autodesk Maya with SoftImage 2016"
        },
        {
            featureid: "86442IDSU_2016_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2016"
        },
        {
            featureid: "86443BDSADV_2016_0F",
            description: "Autodesk Building Design Suite Ultimate 2016"
        },
        {
            featureid: "86444DSPRM_2016_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2016"
        },
        {
            featureid: "86445ACD_2016_0F",
            description: "Autodesk AutoCAD 2016"
        },
        {
            featureid: "86446DSSTD_2016_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2016"
        },
        {
            featureid: "86447ACDLT_2016_0F",
            description: "Autodesk AutoCAD LT 2016"
        },
        {
            featureid: "86448ACLTNL_2016_0F",
            description: "Autodesk AutoCAD LT 2016 Flexible License"
        },
        {
            featureid: "86449RVTLTS_2016_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2016"
        },
        {
            featureid: "86450STRDET_2016_0F",
            description: "AutoCAD Structural Detailing 2016"
        },
        {
            featureid: "86451BDSPRM_2016_0F",
            description: "Autodesk Building Design Suite Premium 2016"
        },
        {
            featureid: "86452BDSS_2016_0F",
            description: "Autodesk Building Design Suite Standard 2016"
        },
        {
            featureid: "86453RVT_2016_0F",
            description: "Autodesk Revit 2016"
        },
        {
            featureid: "86454REVIT_2016_0F",
            description: "Autodesk Revit Architecture 2016"
        },
        {
            featureid: "86455RVTLT_2016_0F",
            description: "Autodesk Revit LT 2016"
        },
        {
            featureid: "86456RVTMPB_2016_0F",
            description: "Autodesk Revit MEP 2016"
        },
        {
            featureid: "86457REVITST_2016_0F",
            description: "Autodesk Revit Structure 2016"
        },
        {
            featureid: "86458SIECS_2016_0F",
            description: "Autodesk Softimage Entertainment Creation Suite Standard 2016"
        },
        {
            featureid: "86459MRSTND_2016_0F",
            description: "mental ray Standalone 2016"
        },
        {
            featureid: "86460MOBPRO_2016_0F",
            description: "Autodesk MotionBuilder 2016"
        },
        {
            featureid: "86461MBXPRO_2016_0F",
            description: "Autodesk Mudbox 2016"
        },
        {
            featureid: "86462PDSU_2016_0F",
            description: "Autodesk Product Design Suite Ultimate 2016"
        },
        {
            featureid: "86463DES_ACA_2016_0F",
            description: "Autodesk Design Academy 2016"
        },
        {
            featureid: "86464MXECS_2016_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2016"
        },
        {
            featureid: "86465REVITS_2016_0F",
            description: "Autodesk AutoCAD Revit Architecture Suite 2016"
        },
        {
            featureid: "86466REVSYP_2016_0F",
            description: "Autodesk AutoCAD Revit MEP Suite 2016"
        },
        {
            featureid: "86467REVSU_2016_0F",
            description: "Autodesk AutoCAD Revit Structure Suite 2016"
        },
        {
            featureid: "86468IDSP_2016_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2016"
        },
        {
            featureid: "86469PDSPRM_2016_0F",
            description: "Autodesk Plant Design Suite Premium 2016"
        },
        {
            featureid: "86470PDSADV_2016_0F",
            description: "Autodesk Plant Design Suite Ultimate 2016"
        },
        {
            featureid: "864713DSMAX_2016_0F",
            description: "Autodesk 3ds Max 2016"
        },
        {
            featureid: "86472MAXDES_2016_0F",
            description: "Autodesk 3ds Max Design 2016"
        },
        {
            featureid: "864733DSMXS_2016_0F",
            description: "Autodesk 3ds Max with SoftImage 2016"
        },
        {
            featureid: "86474ARDES_2016_0F",
            description: "Autodesk AutoCAD Raster Design 2016"
        },
        {
            featureid: "86475FDSPRM_2016_0F",
            description: "Autodesk Factory Design Suite Premium 2016"
        },
        {
            featureid: "86476FDSADV_2016_0F",
            description: "Autodesk Factory Design Suite Ultimate 2016"
        },
        {
            featureid: "86477PDSP_2016_0F",
            description: "Autodesk Product Design Suite Premium 2016"
        },
        {
            featureid: "86478AMECH_PP_2016_0F",
            description: "Autodesk AutoCAD Mechanical 2016"
        },
        {
            featureid: "86479BLDSYS_2016_0F",
            description: "Autodesk AutoCAD MEP 2016"
        },
        {
            featureid: "86480ARCHDESK_2016_0F",
            description: "Autodesk AutoCAD Architecture 2016"
        },
        {
            featureid: "86480ARCHDESK_2017_0F",
            description: "Autodesk AutoCAD Architecture 2017"
        },
        {
            featureid: "86481ACAD_E_2016_0F",
            description: "Autodesk AutoCAD Electrical 2016"
        },
        {
            featureid: "86485INVLTS_2016_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2016"
        },
        {
            featureid: "86486ACDLTF_2015_0F",
            description: "Autodesk AutoCAD LT 2015 French Edition"
        },
        {
            featureid: "86487INTSER_2016_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2016"
        },
        {
            featureid: "86488ASPACD_2015_1F",
            description: "Autodesk Advance Steel 2015_1 with AutoCAD"
        },
        {
            featureid: "86489ADSTCP_2015_1F",
            description: "Autodesk Advance Concrete 2015.1"
        },
        {
            featureid: "86490ADSTPR_2015_1F",
            description: "Autodesk Advance Steel 2015_1"
        },
        {
            featureid: "86491FDU_2016_0F",
            description: "Autodesk Factory Design Utilities 2016"
        },
        {
            featureid: "86492INVNTOR_2016_0F",
            description: "Autodesk Inventor 2016"
        },
        {
            featureid: "86493INVHSM_2016_0F",
            description: "Autodesk Inventor HSM 2016"
        },
        {
            featureid: "86494INHSMP_2016_0F",
            description: "Autodesk Inventor HSM Pro 2016"
        },
        {
            featureid: "86495INVOEM_2016_0F",
            description: "Autodesk Inventor OEM 2016"
        },
        {
            featureid: "86496INVLT_2016_0F",
            description: "Autodesk Inventor LT 2016"
        },
        {
            featureid: "86497INVPROSA_2016_0F",
            description: "Autodesk Inventor Professional 2016"
        },
        {
            featureid: "86498NSTRN_2015_0F",
            description: "Autodesk Nastran 2015"
        },
        {
            featureid: "86499NSTRN_F",
            description: "Autodesk Nastran Package"
        },
        {
            featureid: "86499NSTRN_T_F",
            description: "Autodesk Nastran Package Term"
        },
        {
            featureid: "86500NINCAD_2015_0F",
            description: "Autodesk Nastran In-CAD 2015"
        },
        {
            featureid: "86501NINCAD_F",
            description: "Autodesk Nastran In-CAD Package"
        },
        {
            featureid: "86501NINCAD_T_F",
            description: "Autodesk Nastran In-CAD Package Term"
        },
        {
            featureid: "86502FDSS_2016_0F",
            description: "Autodesk Factory Design Suite Standard 2016"
        },
        {
            featureid: "86503INVETO_2016_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2016"
        },
        {
            featureid: "86504SMKDS_2016_0F",
            description: "Autodesk Smoke 2016 Desktop Rental"
        },
        {
            featureid: "86505SFTIM_2016_0F",
            description: "Autodesk Softimage 2015 R2"
        },
        {
            featureid: "86506ASPACD_2016_0F",
            description: "Autodesk Advance Steel 2016 with AutoCAD"
        },
        {
            featureid: "86507ADSTPR_2016_0F",
            description: "Autodesk Advance Steel 2016"
        },
        {
            featureid: "86508IDSS_2016_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2016"
        },
        {
            featureid: "86509PLTDSS_2016_0F",
            description: "Autodesk Plant Design Suite Standard 2016"
        },
        {
            featureid: "86510PDSS_2016_0F",
            description: "Autodesk Product Design Suite Standard 2016"
        },
        {
            featureid: "86511ACDMAC_2016_0F",
            description: "Autodesk AutoCAD 2016 for Mac"
        },
        {
            featureid: "86512ACDLTM_2016_0F",
            description: "Autodesk AutoCAD LT 2016 for Mac"
        },
        {
            featureid: "86513ACDLTC_2016_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2016"
        },
        {
            featureid: "86514CIV3D_2016_0F",
            description: "Autodesk AutoCAD Civil 3D 2016"
        },
        {
            featureid: "86515ADSTCP_2016_0F",
            description: "Autodesk Advance Concrete 2016"
        },
        {
            featureid: "86515MFCD_2016_0F",
            description: "CADdoctor for Autodesk Simulation 2016"
        },
        {
            featureid: "86516ADKVRD_2016_0F",
            description: "Autodesk VRED 2016"
        },
        {
            featureid: "86517MAP_2016_0F",
            description: "Autodesk AutoCAD Map 3D 2016"
        },
        {
            featureid: "86517PLNT3D_2016_0F",
            description: "Autodesk AutoCAD Plant 3D 2016"
        },
        {
            featureid: "86517PNID_2016_0F",
            description: "Autodesk AutoCAD P&ID 2016"
        },
        {
            featureid: "86517UTLDESN_2016_0F",
            description: "Autodesk AutoCAD Utility Design 2016"
        },
        {
            featureid: "86518DCMVRD_2016_0F",
            description: "Autodesk Display Cluster Module for Autodesk VRED Design 2016"
        },
        {
            featureid: "86519CADMEP_2016_0F",
            description: "Autodesk Fabrication CADmep 2016"
        },
        {
            featureid: "86520CAMDCT_2016_0F",
            description: "Autodesk Fabrication CAMduct 2016"
        },
        {
            featureid: "86521CAMLTE_2016_0F",
            description: "Autodesk Fabrication CAMduct Components 2016"
        },
        {
            featureid: "86522ESTMEP_2016_0F",
            description: "Autodesk Fabrication ESTmep 2016"
        },
        {
            featureid: "86523RMNTRY_2016_0F",
            description: "Autodesk Fabrication RemoteEntry 2016"
        },
        {
            featureid: "86524TRCKIT_2016_0F",
            description: "Autodesk Fabrication Tracker 2016"
        },
        {
            featureid: "86525HSMPRM_2016_0F",
            description: "Autodesk HSMWorks Premium 2016"
        },
        {
            featureid: "86526HSMPRO_2016_0F",
            description: "Autodesk HSMWorks Professional 2016"
        },
        {
            featureid: "86527IMS_2016_0F",
            description: "Autodesk Infrastructure Map Server 2016"
        },
        {
            featureid: "86528IMS5A_2016_0F",
            description: "Autodesk Infrastructure Map Server 5 Activations 2016"
        },
        {
            featureid: "86529INFMDR_2016_0F",
            description: "Autodesk InfraWorks 2016"
        },
        {
            featureid: "86530IW360C_2016_0F",
            description: "Autodesk InfraWorks 360 - companion 2016"
        },
        {
            featureid: "86531IW360P_2016_0F",
            description: "Autodesk InfraWorks 360 2016"
        },
        {
            featureid: "86532IMRH_2016_0F",
            description: "Autodesk InfraWorks bundle – Roads & Highways 2016"
        },
        {
            featureid: "86533INVPUB_2016_0F",
            description: "Autodesk Inventor Publisher 2016"
        },
        {
            featureid: "86534NAVMAN_2016_0F",
            description: "Autodesk Navisworks Manage 2016"
        },
        {
            featureid: "86535PNTLAY_2016_0F",
            description: "Autodesk Point Layout 2016"
        },
        {
            featureid: "86536ESME_2016_0F",
            description: "Autodesk Product Design Suite for Education 2016"
        },
        {
            featureid: "86537RCMVRD_2016_0F",
            description: "Autodesk Raytracing Cluster Module for Autodesk VRED 2016"
        },
        {
            featureid: "86538RECAP_2016_0F",
            description: "Autodesk ReCap 2016"
        },
        {
            featureid: "86540SCFD_2016_0F",
            description: "Autodesk CFD 2016"
        },
        {
            featureid: "86541SCFDA_2016_0F",
            description: "Autodesk CFD 2016 Advanced"
        },
        {
            featureid: "86542SCACIS_2016_0F",
            description: "Autodesk Simulation CFD 2016 Connection for ACIS"
        },
        {
            featureid: "86543SCFDNX_2016_0F",
            description: "Autodesk CFD 2016 Connection for NX"
        },
        {
            featureid: "86544SCFDP_2016_0F",
            description: "Autodesk CFD 2016 Connection for Parasolid"
        },
        {
            featureid: "86545SCPROE_2016_0F",
            description: "Autodesk CFD 2016 Connection for Pro ENGINEER"
        },
        {
            featureid: "86546SCDSE_2016_0F",
            description: "Autodesk CFD 2016 Design Study Environment"
        },
        {
            featureid: "86547SCFDM_2016_0F",
            description: "Autodesk CFD 2016 Motion"
        },
        {
            featureid: "86548ACMPAN_2016_0F",
            description: "Autodesk Helius PFA 2016"
        },
        {
            featureid: "86549ACMPDS_2016_0F",
            description: "Autodesk Helius Composite 2016"
        },
        {
            featureid: "86550SIMDFM_2016_0F",
            description: "Autodesk Moldflow Design 2016"
        },
        {
            featureid: "86551ASMES_2016_0F",
            description: "Autodesk Simulation Mechanical 2016"
        },
        {
            featureid: "86552MFAM_2016_0F",
            description: "Autodesk Moldflow Adviser Premium 2016"
        },
        {
            featureid: "86553NAVSIM_2016_0F",
            description: "Autodesk Navisworks Simulate 2016"
        },
        {
            featureid: "86554MFAD_2016_0F",
            description: "Autodesk Moldflow Adviser Standard 2016"
        },
        {
            featureid: "86555MFIP_2016_0F",
            description: "Autodesk Moldflow Insight Premium 2016"
        },
        {
            featureid: "86556MFIB_2016_0F",
            description: "Autodesk Moldflow Insight Standard 2016"
        },
        {
            featureid: "86557MFIA_2016_0F",
            description: "Autodesk Moldflow Insight Ultimate 2016"
        },
        {
            featureid: "86558MFS_2016_0F",
            description: "Autodesk Moldflow Synergy 2016"
        },
        {
            featureid: "86561TSPRHN_2016_0F",
            description: "Autodesk T-Splines Plug-in for Rhino 2016"
        },
        {
            featureid: "86562PCOFFI_2016_0F",
            description: "Autodesk Vault Office 2016"
        },
        {
            featureid: "86563VLTM_2016_0F",
            description: "Autodesk Vault Professional 2016"
        },
        {
            featureid: "86564VLTWG_2016_0F",
            description: "Autodesk Vault Workgroup 2016"
        },
        {
            featureid: "86565VEHTRK_2016_0F",
            description: "Autodesk Vehicle Tracking 2016"
        },
        {
            featureid: "86566VRDDES_2016_0F",
            description: "Autodesk VRED Design 2016"
        },
        {
            featureid: "86567VRDPRS_2016_0F",
            description: "Autodesk VRED Presenter 2016"
        },
        {
            featureid: "86568VRDPRO_2016_0F",
            description: "Autodesk VRED Professional 2016"
        },
        {
            featureid: "86569EMFEB_2016_0F",
            description: "Enterprise Multi-Flex Enhanced Bundle 2016"
        },
        {
            featureid: "86570EMFEB_F",
            description: "Enterprise Multi-Flex Enhanced Bundle Package"
        },
        {
            featureid: "86571T1MF_2016_0F",
            description: "T1 Enterprise Multi-flex 2016"
        },
        {
            featureid: "86572VRDSRV_2016_0F",
            description: "Autodesk VRED Server 2016"
        },
        {
            featureid: "86573T1MFPV_2016_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2016"
        },
        {
            featureid: "86574T1MFPB_2016_0F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle 2016"
        },
        {
            featureid: "86575T1MFSB_2016_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2016"
        },
        {
            featureid: "86576MFAA_2016_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2016"
        },
        {
            featureid: "86578ACMPAN_F",
            description: "Autodesk Simulation Composite Analysis 2015_1 Package"
        },
        {
            featureid: "86579NSTRN_2016_0F",
            description: "Autodesk Nastran 2016"
        },
        {
            featureid: "86580NINCAD_2016_0F",
            description: "Autodesk Nastran In-CAD 2016"
        },
        {
            featureid: "86581ALSCPT_2016_0F",
            description: "Autodesk Alias Concept 2016"
        },
        {
            featureid: "86581ALSSF_2015_0F",
            description: "Autodesk Alias Speedform 2015"
        },
        {
            featureid: "86581ALSSF_2016_0F",
            description: "Autodesk Alias SpeedForm 2016"
        },
        {
            featureid: "86582ALSCPT_F",
            description: "Autodesk Alias Concept Package"
        },
        {
            featureid: "86582ALSCPT_T_F",
            description: "Autodesk Alias Concept Package Term"
        },
        {
            featureid: "86582ALSSF_F",
            description: "Autodesk Alias SpeedForm Package"
        },
        {
            featureid: "86582ALSSF_T_F",
            description: "Autodesk Alias SpeedForm Package Term"
        },
        {
            featureid: "86582SHOWCASE_2016_0F",
            description: "Autodesk Showcase 2016"
        },
        {
            featureid: "86583RETODP_2015_0F",
            description: "Autodesk Revit Engineer-to-Order 2015 - Distribution Package"
        },
        {
            featureid: "86584RETODP_F",
            description: "Autodesk Revit Engineer-to-Order - Distribution Package Package"
        },
        {
            featureid: "86586MFIB_F",
            description: "Autodesk Moldflow Insight Standard Package"
        },
        {
            featureid: "86586MFIB_T_F",
            description: "Autodesk Moldflow Insight Standard Package Term"
        },
        {
            featureid: "86589RETODP_2016_0F",
            description: "Autodesk Revit Engineer-to-Order 2016 - Distribution Package"
        },
        {
            featureid: "86590RETODP_F",
            description: "Autodesk Revit Engineer-to-Order - Distribution Package Package"
        },
        {
            featureid: "86591IETODEV_F",
            description: "Autodesk Inventor Engineer-to-Order - Developer Package"
        },
        {
            featureid: "86591IETODEV_T_F",
            description: "Autodesk Inventor Engineer-to-Order - Developer Package Term"
        },
        {
            featureid: "86592IETOSRV_F",
            description: "Autodesk Inventor Engineer-to-Order - Server Package"
        },
        {
            featureid: "86592IETOSRV_T_F",
            description: "Autodesk Inventor Engineer-to-Order - Server Package Term"
        },
        {
            featureid: "86592INTSER_T_F",
            description: "Autodesk Inventor Engineer-to-Order Server Package"
        },
        {
            featureid: "86593IETODIS_2016_0F",
            description: "Autodesk Inventor Engineer-to-Order 2016 - Distribution"
        },
        {
            featureid: "86594IETODIS_F",
            description: "Autodesk Inventor Engineer-to-Order - Distribution Package"
        },
        {
            featureid: "86594IETODIS_T_F",
            description: "Autodesk Inventor Engineer-to-Order - Distribution Package Term"
        },
        {
            featureid: "86595STUWAL_2016_0F",
            description: "Autodesk Studio Wall 2016"
        },
        {
            featureid: "86596CONCRD_2016_0F",
            description: "Autodesk Project Concord 2016"
        },
        {
            featureid: "86597CONCRD_F",
            description: "Autodesk Project Concord Package"
        },
        {
            featureid: "86600GEOMOD_2016_0F",
            description: "Autodesk Geotechnical Module 2016"
        },
        {
            featureid: "86600RFAMOD_2016_0F",
            description: "Autodesk River & Flood Analysis Module 2016"
        },
        {
            featureid: "86602BRDGMD_2016_0F",
            description: "Autodesk Bridge Module 2016"
        },
        {
            featureid: "86604ACD_2017_0F",
            description: "Autodesk AutoCAD 2017"
        },
        {
            featureid: "86604ACDLT_2017_0F",
            description: "Autodesk AutoCAD LT 2017"
        },
        {
            featureid: "86606ARDES_2017_0F",
            description: "Autodesk AutoCAD Raster Design 2017"
        },
        {
            featureid: "86608INVNTOR_2017_0F",
            description: "Autodesk Inventor 2017"
        },
        {
            featureid: "86609INVPROSA_2017_0F",
            description: "Autodesk Inventor Professional 2017"
        },
        {
            featureid: "86610INVLT_2017_0F",
            description: "Autodesk Inventor LT 2017"
        },
        {
            featureid: "86611INVOEM_2017_0F",
            description: "Autodesk Inventor OEM 2017"
        },
        {
            featureid: "86612ADSTPR_2017_0F",
            description: "Autodesk Advance Steel 2017"
        },
        {
            featureid: "86614ASPACD_2017_0F",
            description: "Autodesk Advance Steel 2017 with AutoCAD"
        },
        {
            featureid: "86616ADSTCP_2017_0F",
            description: "Autodesk Advance Concrete 2017"
        },
        {
            featureid: "86618MAYA_2017_0F",
            description: "Autodesk Maya 2017"
        },
        {
            featureid: "866223DSMXS_2017_0F",
            description: "Autodesk 3ds Max 2017 with SoftImage"
        },
        {
            featureid: "86623MOBPRO_2017_0F",
            description: "Autodesk MotionBuilder 2017"
        },
        {
            featureid: "86624MBXPRO_2017_0F",
            description: "Autodesk Mudbox 2017"
        },
        {
            featureid: "86627AMECH_PP_2017_0F",
            description: "Autodesk AutoCAD Mechanical 2017"
        },
        {
            featureid: "86631BLDSYS_2017_0F",
            description: "Autodesk AutoCAD MEP 2017"
        },
        {
            featureid: "866333DSMAX_2017_0F",
            description: "Autodesk 3ds Max 2017"
        },
        {
            featureid: "86635ACAD_E_2017_0F",
            description: "Autodesk AutoCAD Electrical 2017"
        },
        {
            featureid: "86635ACAD_E_2018_0F",
            description: "Autodesk AutoCAD Electrical 2018"
        },
        {
            featureid: "86637SURFST_2017_0F",
            description: "Autodesk Alias Surface 2017"
        },
        {
            featureid: "86638DESNST_2017_0F",
            description: "Autodesk Alias Design 2017"
        },
        {
            featureid: "86638DESNST_2018_0F",
            description: "Autodesk Alias Design 2018"
        },
        {
            featureid: "86639GMCADC_2017_0F",
            description: "Autodesk GM CAD Convertors 2017"
        },
        {
            featureid: "86640ATCIGM_2017_0F",
            description: "Autodesk Alias TC Integrator for GM 2017"
        },
        {
            featureid: "86641ALSCPT_2017_0F",
            description: "Autodesk Alias Concept 2017"
        },
        {
            featureid: "86642ALAUST_2017_0F",
            description: "Autodesk Alias AutoStudio 2017"
        },
        {
            featureid: "86643DYNSTD_2016_0F",
            description: "Autodesk Dynamo Studio 2016"
        },
        {
            featureid: "86644DYNSTD_F",
            description: "Autodesk Dynamo Studio"
        },
        {
            featureid: "86644DYNSTD_T_F",
            description: "Autodesk Dynamo Studio Term"
        },
        {
            featureid: "86645PCOFFI_2017_0F",
            description: "Autodesk Vault Office 2017"
        },
        {
            featureid: "86646VLTM_2017_0F",
            description: "Autodesk Vault Professional 2017"
        },
        {
            featureid: "86647VLTWG_2017_0F",
            description: "Autodesk Vault Workgroup 2017"
        },
        {
            featureid: "86648PNID_2017_0F",
            description: "Autodesk AutoCAD P&ID 2017"
        },
        {
            featureid: "86650PLNT3D_2017_0F",
            description: "Autodesk AutoCAD Plant 3D 2017"
        },
        {
            featureid: "86652PDSPRM_2017_0F",
            description: "Autodesk Plant Design Suite Premium 2017"
        },
        {
            featureid: "86654PLTDSS_2017_0F",
            description: "Autodesk Plant Design Suite Standard 2017"
        },
        {
            featureid: "86656PDSADV_2017_0F",
            description: "Autodesk Plant Design Suite Ultimate 2017"
        },
        {
            featureid: "86659NINCAD_2017_0F",
            description: "Autodesk Nastran In-CAD 2017"
        },
        {
            featureid: "86659NSTRN_2017_0F",
            description: "Autodesk Nastran 2017"
        },
        {
            featureid: "86659RSAPRO_2017_0F",
            description: "Autodesk Robot Structural Analysis Professional 2017"
        },
        {
            featureid: "86660ACMPAN_2017_0F",
            description: "Autodesk Helius PFA 2017"
        },
        {
            featureid: "86662ACMPDS_2017_0F",
            description: "Autodesk Helius Composite 2017"
        },
        {
            featureid: "86668IW360DBP_F",
            description: "Autodesk InfraWorks 360 - Design Bundle Plus Package"
        },
        {
            featureid: "86669TRUCOM_F",
            description: "Autodesk TruComp Package"
        },
        {
            featureid: "86669TRUCOM_T_F",
            description: "Autodesk TruComp Package Term"
        },
        {
            featureid: "86670TRULSR_F",
            description: "Autodesk TruLaser Package"
        },
        {
            featureid: "86670TRULSR_T_F",
            description: "Autodesk TruLaser Package Term"
        },
        {
            featureid: "86671STUWAL_F",
            description: "Autodesk Studio Wall Package"
        },
        {
            featureid: "86671STUWAL_T_F",
            description: "Autodesk Studio Wall Package Term"
        },
        {
            featureid: "86673VRNCBL_F",
            description: "Autodesk VRED Render Node - consumption-based license Package"
        },
        {
            featureid: "86673VRNCBL_T_F",
            description: "Autodesk VRED Render Node - consumption-based license Package Term"
        },
        {
            featureid: "86676TRUNST_F",
            description: "Autodesk TruNest - Nesting Engine Package"
        },
        {
            featureid: "86676TRUNST_T_F",
            description: "Autodesk TruNest - Nesting Engine Package Term"
        },
        {
            featureid: "86678TNCONT_F",
            description: "Autodesk TruNest Contour Package"
        },
        {
            featureid: "86678TNCONT_T_F",
            description: "Autodesk TruNest Contour Package Term"
        },
        {
            featureid: "86680TNCOMP_F",
            description: "Autodesk TruNest Composites Package"
        },
        {
            featureid: "86680TNCOMP_T_F",
            description: "Autodesk TruNest Composites Package Term"
        },
        {
            featureid: "86682TNMULT_F",
            description: "Autodesk TruNest Multi-Tool Package"
        },
        {
            featureid: "86682TNMULT_T_F",
            description: "Autodesk TruNest Multi-Tool Package Term"
        },
        {
            featureid: "86683DSSTD_2017_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2017"
        },
        {
            featureid: "86685DSPRM_2017_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2017"
        },
        {
            featureid: "86687DSADV_2017_0F",
            description: "Autodesk AutoCAD Design Suite Ultimate 2017"
        },
        {
            featureid: "86689PDSU_2017_0F",
            description: "Autodesk Product Design Suite Ultimate 2017"
        },
        {
            featureid: "86690PDSP_2017_0F",
            description: "Autodesk Product Design Suite Premium 2017"
        },
        {
            featureid: "86691FDU_2017_0F",
            description: "Autodesk Factory Design Utilities 2017"
        },
        {
            featureid: "86692FDSADV_2017_0F",
            description: "Autodesk Factory Design Suite Ultimate 2017"
        },
        {
            featureid: "86693FDSS_2017_0F",
            description: "Autodesk Factory Design Suite Standard 2017"
        },
        {
            featureid: "86694FDSPRM_2017_0F",
            description: "Autodesk Factory Design Suite Premium 2017"
        },
        {
            featureid: "86695BDSS_2017_0F",
            description: "Autodesk Building Design Suite Standard 2017"
        },
        {
            featureid: "86696BDSPRM_2017_0F",
            description: "Autodesk Building Design Suite Premium 2017"
        },
        {
            featureid: "86697BDSADV_2017_0F",
            description: "Autodesk Building Design Suite Ultimate 2017"
        },
        {
            featureid: "86698IW360DBP_2017_0F",
            description: "Autodesk InfraWorks 360 - Design Bundle Plus"
        },
        {
            featureid: "86700IDSS_2017_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2017"
        },
        {
            featureid: "86702IDSP_2017_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2017"
        },
        {
            featureid: "86704IDSU_2017_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2017"
        },
        {
            featureid: "86706RVT_2017_0F",
            description: "Autodesk Revit 2017"
        },
        {
            featureid: "86707RVTLT_2017_0F",
            description: "Autodesk Revit LT 2017"
        },
        {
            featureid: "86711ACDMAC_2017_0F",
            description: "Autodesk AutoCAD 2017 for Mac"
        },
        {
            featureid: "86716CONCRD_2017_0F",
            description: "Autodesk Revit Collaboration Suite 2017"
        },
        {
            featureid: "86717RVTLTS_2017_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2017"
        },
        {
            featureid: "86718CIV3D_2017_0F",
            description: "Autodesk AutoCAD Civil 3D 2017"
        },
        {
            featureid: "86719MAP_2017_0F",
            description: "Autodesk AutoCAD Map 3D 2017"
        },
        {
            featureid: "86720UTLDESN_2017_0F",
            description: "Autodesk AutoCAD Utility Design 2017"
        },
        {
            featureid: "86721IMS_2017_0F",
            description: "Autodesk Infrastructure Map Server 2017"
        },
        {
            featureid: "86722IMS5A_2017_0F",
            description: "Autodesk Infrastructure Map Server 5 Activations 2017"
        },
        {
            featureid: "86723ACDLTC_2017_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2017"
        },
        {
            featureid: "86725PNTLAY_2017_0F",
            description: "Autodesk Point Layout 2017"
        },
        {
            featureid: "86726VEHTRK_2017_0F",
            description: "Autodesk Vehicle Tracking 2017"
        },
        {
            featureid: "86732INTSER_2017_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2017"
        },
        {
            featureid: "86733IETODEV_2017_0F",
            description: "Autodesk Inventor Engineer-to-Order 2017 - Developer"
        },
        {
            featureid: "86734IETODIS_2017_0F",
            description: "Autodesk Inventor Engineer-to-Order 2017 - Distribution"
        },
        {
            featureid: "86735IETOSRV_2017_0F",
            description: "Autodesk Inventor Engineer-to-Order 2017 - Server"
        },
        {
            featureid: "86736INVETO_2017_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2017"
        },
        {
            featureid: "86737ALSSF_2017_0F",
            description: "Autodesk Alias SpeedForm 2017"
        },
        {
            featureid: "86738STUWAL_2017_0F",
            description: "Autodesk Studio Wall 2017"
        },
        {
            featureid: "86740MEPCS_F",
            description: "Autodesk MEP Fabrication Suite Package"
        },
        {
            featureid: "86740MEPCS_T_F",
            description: "Autodesk MEP Fabrication Suite Package Term"
        },
        {
            featureid: "86742SFS_F",
            description: "Autodesk Structural Fabrication Suite Package"
        },
        {
            featureid: "86742SFS_T_F",
            description: "Autodesk Structural Fabrication Suite Package Term"
        },
        {
            featureid: "86743TRULSR_2017_0F",
            description: "Autodesk TruLaser 2017"
        },
        {
            featureid: "86744INVHSM_2017_0F",
            description: "Autodesk Inventor HSM 2017"
        },
        {
            featureid: "86745INHSMP_2017_0F",
            description: "Autodesk Inventor HSM Pro 2017"
        },
        {
            featureid: "86746INVLTS_2017_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2017"
        },
        {
            featureid: "86747RECAP_2017_0F",
            description: "Autodesk ReCap 360 Pro"
        },
        {
            featureid: "86748MFAD_2017_0F",
            description: "Autodesk Moldflow Adviser Standard 2017"
        },
        {
            featureid: "86749MFAM_2017_0F",
            description: "Autodesk Moldflow Adviser Premium 2017"
        },
        {
            featureid: "86750MFAA_2017_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2017"
        },
        {
            featureid: "86751MFIB_2017_0F",
            description: "Autodesk Moldflow Insight Standard 2017"
        },
        {
            featureid: "86752MFIP_2017_0F",
            description: "Autodesk Moldflow Insight Premium 2017"
        },
        {
            featureid: "86753MFIA_2017_0F",
            description: "Autodesk Moldflow Insight Ultimate 2017"
        },
        {
            featureid: "86754MFS_2017_0F",
            description: "Autodesk Moldflow Synergy 2017"
        },
        {
            featureid: "86755SIMDFM_2017_0F",
            description: "Autodesk Moldflow Design 2017"
        },
        {
            featureid: "86756MFCD_2017_0F",
            description: "CADdoctor for Autodesk Simulation 2017"
        },
        {
            featureid: "86757ASMES_2017_0F",
            description: "Autodesk Simulation Mechanical 2017"
        },
        {
            featureid: "86762IW360P_2017_0F",
            description: "Autodesk InfraWorks 360 2017"
        },
        {
            featureid: "86766NAVMAN_2017_0F",
            description: "Autodesk Navisworks Manage 2017"
        },
        {
            featureid: "86767NAVSIM_2017_0F",
            description: "Autodesk Navisworks Simulate 2017"
        },
        {
            featureid: "86768ADKVRD_2017_0F",
            description: "Autodesk VRED 2017"
        },
        {
            featureid: "86769VRDDES_2017_0F",
            description: "Autodesk VRED Design 2017"
        },
        {
            featureid: "86770VRDPRS_2017_0F",
            description: "Autodesk VRED Presenter 2017"
        },
        {
            featureid: "86771VRDPRO_2017_0F",
            description: "Autodesk VRED Professional 2017"
        },
        {
            featureid: "86772VRNCBL_2017_0F",
            description: "Autodesk VRED Render Node - consumption-based license 2017"
        },
        {
            featureid: "86773RCMVRD_2017_0F",
            description: "Autodesk VRED Render Node 2017"
        },
        {
            featureid: "86774VRDSRV_2017_0F",
            description: "Autodesk VRED Server 2017"
        },
        {
            featureid: "86775ESTMEP_2017_0F",
            description: "Autodesk Fabrication ESTmep 2017"
        },
        {
            featureid: "86776CAMDCT_2017_0F",
            description: "Autodesk Fabrication CAMduct 2017"
        },
        {
            featureid: "86777CADMEP_2017_0F",
            description: "Autodesk Fabrication CADmep 2017"
        },
        {
            featureid: "86778TNMULT_2017_0F",
            description: "Autodesk TruNest Multi-Tool 2017"
        },
        {
            featureid: "86779TNCONT_2017_0F",
            description: "Autodesk TruNest Contour 2017"
        },
        {
            featureid: "86780TNCOMP_2017_0F",
            description: "Autodesk TruNest Composites 2017"
        },
        {
            featureid: "86781TRUNST_2017_0F",
            description: "Autodesk TruNest 2017 - Nesting Engine"
        },
        {
            featureid: "86782TRUCOM_2017_0F",
            description: "Autodesk TruComp 2017"
        },
        {
            featureid: "86786AECS_2017_0F",
            description: "Autodesk Architecture Engineering and Construction Suite 2017"
        },
        {
            featureid: "86786AECS_F",
            description: "Autodesk Architecture Engineering and Construction Suite Package"
        },
        {
            featureid: "86788MES_2017_0F",
            description: "Autodesk Media and Entertainment Suite 2017"
        },
        {
            featureid: "86788MES_F",
            description: "Autodesk Media and Entertainment Suite Package"
        },
        {
            featureid: "86790MEPCS_2017_0F",
            description: "Autodesk MEP Fabrication Suite 2017"
        },
        {
            featureid: "86791SFS_2017_0F",
            description: "Autodesk Structural Fabrication Suite 2017"
        },
        {
            featureid: "86792SBRDES_2017_0F",
            description: "Autodesk Structural Bridge Design 2017"
        },
        {
            featureid: "86802PLC0000023_2017_0F",
            description: "Autodesk SimStudio Tools 2016 R2"
        },
        {
            featureid: "86802PLC0000023_F",
            description: "Autodesk SimStudio Tools 2016 R2 Package"
        },
        {
            featureid: "86802PLC0000023_T_F",
            description: "Autodesk SimStudio Tools 2016 R2 Package Term"
        },
        {
            featureid: "86803SHOWCASE_2017_0F",
            description: "Autodesk Showcase 2017"
        },
        {
            featureid: "86804SCFD_2017_0F",
            description: "Autodesk CFD 2017"
        },
        {
            featureid: "86805SCFDA_2017_0F",
            description: "Autodesk CFD 2017 Advanced"
        },
        {
            featureid: "86806SCDSE_2017_0F",
            description: "Autodesk CFD 2017 Design Study Environment"
        },
        {
            featureid: "86807SCFDM_2017_0F",
            description: "Autodesk CFD 2017 Motion"
        },
        {
            featureid: "86808TRUFBR_F",
            description: "Autodesk TruFiber Package"
        },
        {
            featureid: "86808TRUFBR_T_F",
            description: "Autodesk TruFiber Package Term"
        },
        {
            featureid: "86809TRUPLN_2017_0F",
            description: "Autodesk TruPlan 2017"
        },
        {
            featureid: "86809TRUPLN_F",
            description: "Autodesk TruPlan Package"
        },
        {
            featureid: "86809TRUPLN_T_F",
            description: "Autodesk TruPlan Package Term"
        },
        {
            featureid: "86810STNGRAY_2017_0F",
            description: "Autodesk Stingray 2017"
        },
        {
            featureid: "86810STNGRAY_F",
            description: "Autodesk Stingray"
        },
        {
            featureid: "86810STNGRAY_T_F",
            description: "Autodesk Stingray Term"
        },
        {
            featureid: "86812MMNTO_2017_0F",
            description: "Autodesk Memento"
        },
        {
            featureid: "86812MMNTO_F",
            description: "Autodesk Memento Package"
        },
        {
            featureid: "86812MMNTO_T_F",
            description: "Autodesk Memento Package Term"
        },
        {
            featureid: "86813MAYAIO_F",
            description: "Autodesk Maya IO Package"
        },
        {
            featureid: "86813MAYAIO_T_F",
            description: "Autodesk Maya IO Package Term"
        },
        {
            featureid: "86815AECCOL_T_F",
            description: "AEC Collection Package"
        },
        {
            featureid: "86816T1MF_2017_0F",
            description: "T1 Enterprise Multi-flex 2017"
        },
        {
            featureid: "86817T1MFSB_2017_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2017"
        },
        {
            featureid: "86818EMFEB_2017_0F",
            description: "Enterprise Multi-Flex Enhanced Bundle 2017"
        },
        {
            featureid: "86819T1MFPB_2017_0F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle 2017"
        },
        {
            featureid: "86820VLTEAD_F",
            description: "Enterprise Add-On for Autodesk Vault Package"
        },
        {
            featureid: "86820VLTEAD_T_F",
            description: "Enterprise Add-On for Autodesk Vault Package Term"
        },
        {
            featureid: "86822DYNSTD_2017_0F",
            description: "Autodesk Dynamo Studio 2017"
        },
        {
            featureid: "86823MAYALT_F",
            description: "Autodesk Maya LT 2018 Package"
        },
        {
            featureid: "86823MAYALT_T_F",
            description: "Autodesk Maya LT Package Term"
        },
        {
            featureid: "86824INVNTOR_2018_0F",
            description: "Autodesk Inventor 2018"
        },
        {
            featureid: "86825INVPROSA_2018_0F",
            description: "Autodesk Inventor Professional 2018"
        },
        {
            featureid: "86826INVLT_2018_0F",
            description: "Autodesk Inventor LT 2018"
        },
        {
            featureid: "86827INVOEM_2018_0F",
            description: "Autodesk Inventor OEM 2018"
        },
        {
            featureid: "86829PDCOLL_F",
            description: "PD Collection"
        },
        {
            featureid: "86829PDCOLL_T_F",
            description: "PD Collection Package Term"
        },
        {
            featureid: "86830ACD_2018_0F",
            description: "Autodesk AutoCAD 2018"
        },
        {
            featureid: "86831ACDLT_2018_0F",
            description: "Autodesk AutoCAD LT 2018"
        },
        {
            featureid: "86832ARDES_2018_0F",
            description: "Autodesk AutoCAD Raster Design 2018"
        },
        {
            featureid: "868333DSMAX_2018_0F",
            description: "Autodesk 3ds Max 2018"
        },
        {
            featureid: "86834NETFS_F",
            description: "Autodesk Netfabb Standard Package"
        },
        {
            featureid: "86834NETFS_T_F",
            description: "Autodesk Netfabb Standard Package Term"
        },
        {
            featureid: "86835NETFA_F",
            description: "Autodesk Netfabb Premium Package"
        },
        {
            featureid: "86835NETFA_T_F",
            description: "Autodesk Netfabb Premium Package Term"
        },
        {
            featureid: "86836NETFE_F",
            description: "Autodesk Netfabb Ultimate Package"
        },
        {
            featureid: "86836NETFE_T_F",
            description: "Autodesk Netfabb Ultimate Package Term"
        },
        {
            featureid: "86837FDU_2018_0F",
            description: "Autodesk Factory Design Utilities 2018"
        },
        {
            featureid: "86839AMECH_PP_2018_0F",
            description: "Autodesk AutoCAD Mechanical 2018"
        },
        {
            featureid: "86840PWRSU_F",
            description: "Autodesk PowerShape Ultimate Package"
        },
        {
            featureid: "86840PWRSU_T_F",
            description: "Autodesk PowerShape Ultimate Package Term"
        },
        {
            featureid: "86841PWRSS_F",
            description: "Autodesk PowerShape Standard Package"
        },
        {
            featureid: "86841PWRSS_T_F",
            description: "Autodesk PowerShape Standard Package Term"
        },
        {
            featureid: "86842PWRSP_F",
            description: "Autodesk PowerShape Premium Package"
        },
        {
            featureid: "86842PWRSP_T_F",
            description: "Autodesk PowerShape Premium Package Term"
        },
        {
            featureid: "86843PWRMS_F",
            description: "Autodesk PowerMill Standard Package"
        },
        {
            featureid: "86843PWRMS_T_F",
            description: "Autodesk PowerMill Standard Package Term"
        },
        {
            featureid: "86844PWRMP_F",
            description: "Autodesk PowerMill Premium Package"
        },
        {
            featureid: "86844PWRMP_T_F",
            description: "Autodesk PowerMill Premium Package Term"
        },
        {
            featureid: "86845PWRMU_F",
            description: "Autodesk PowerMill Ultimate Package"
        },
        {
            featureid: "86845PWRMU_T_F",
            description: "Autodesk PowerMill Ultimate Package Term"
        },
        {
            featureid: "86846PWRIS_F",
            description: "Autodesk PowerInspect Standard Package"
        },
        {
            featureid: "86846PWRIS_T_F",
            description: "Autodesk PowerInspect Standard Package Term"
        },
        {
            featureid: "86847PWRIP_2017_0F",
            description: "Autodesk PowerInspect Premium 2018"
        },
        {
            featureid: "86847PWRIP_F",
            description: "Autodesk PowerInspect Premium Package"
        },
        {
            featureid: "86847PWRIP_T_F",
            description: "Autodesk PowerInspect Premium Package Term"
        },
        {
            featureid: "86848PWRIU_F",
            description: "Autodesk PowerInspect Ultimate Package"
        },
        {
            featureid: "86848PWRIU_T_F",
            description: "Autodesk PowerInspect Ultimate Package Term"
        },
        {
            featureid: "86849ARTCS_F",
            description: "Autodesk ArtCAM Standard Package"
        },
        {
            featureid: "86849ARTCS_T_F",
            description: "Autodesk ArtCAM Standard Package Term"
        },
        {
            featureid: "86850ARTCE_F",
            description: "Autodesk ArtCAM Express Package"
        },
        {
            featureid: "86850ARTCE_T_F",
            description: "Autodesk ArtCAM Express Package Term"
        },
        {
            featureid: "86851ARTCP_F",
            description: "Autodesk ArtCAM Premium Package"
        },
        {
            featureid: "86851ARTCP_T_F",
            description: "Autodesk ArtCAM Premium Package Term"
        },
        {
            featureid: "86852FCAMU_F",
            description: "Autodesk FeatureCAM Ultimate Package"
        },
        {
            featureid: "86852FCAMU_T_F",
            description: "Autodesk FeatureCAM Ultimate Package Term"
        },
        {
            featureid: "86853FCAMS_F",
            description: "Autodesk FeatureCAM Standard Package"
        },
        {
            featureid: "86853FCAMS_T_F",
            description: "Autodesk FeatureCAM Standard Package Term"
        },
        {
            featureid: "86854FCAMP_F",
            description: "Autodesk FeatureCAM Premium Package"
        },
        {
            featureid: "86854FCAMP_T_F",
            description: "Autodesk FeatureCAM Premium Package Term"
        },
        {
            featureid: "86855BLDSYS_2018_0F",
            description: "Autodesk AutoCAD MEP 2018"
        },
        {
            featureid: "86856ARCHDESK_2018_0F",
            description: "Autodesk AutoCAD Architecture 2018"
        },
        {
            featureid: "86857ADSTPR_2018_0F",
            description: "Autodesk Advance Steel 2018"
        },
        {
            featureid: "86859MFGDEXUTLP_F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium Package"
        },
        {
            featureid: "86859MFGDEXUTLP_T_F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium Package Term"
        },
        {
            featureid: "86860MFGPPUTL_F",
            description: "Autodesk Manufacturing Post Processor Utility Package"
        },
        {
            featureid: "86860MFGPPUTL_T_F",
            description: "Autodesk Manufacturing Post Processor Utility Package Term"
        },
        {
            featureid: "86861PMAKER_F",
            description: "Autodesk PartMaker Package"
        },
        {
            featureid: "86861PMAKER_T_F",
            description: "Autodesk PartMaker Package Term"
        },
        {
            featureid: "86862PWRMMOD_F",
            description: "Autodesk PowerMill Modeling Package"
        },
        {
            featureid: "86862PWRMMOD_T_F",
            description: "Autodesk PowerMill Modeling Package Term"
        },
        {
            featureid: "86863ALAUST_2018_0F",
            description: "Autodesk Alias AutoStudio 2018"
        },
        {
            featureid: "86865SURFST_2018_0F",
            description: "Autodesk Alias Surface 2018"
        },
        {
            featureid: "86866MAP_2018_0F",
            description: "Autodesk AutoCAD Map 3D 2018"
        },
        {
            featureid: "86867UTLDESN_2018_0F",
            description: "Autodesk AutoCAD Utility Design 2018"
        },
        {
            featureid: "86868MPPUS_F",
            description: "Autodesk Manufacturing Automation Utility Package"
        },
        {
            featureid: "86868MPPUS_T_F",
            description: "Autodesk Manufacturing Automation Utility Package Term"
        },
        {
            featureid: "86869MDEUS_F",
            description: "Autodesk Manufacturing Data Exchange Utility Standard Package"
        },
        {
            featureid: "86869MDEUS_T_F",
            description: "Autodesk Manufacturing Data Exchange Utility Standard Package Term"
        },
        {
            featureid: "86870ACPLH_2018_0F",
            description: "ArtCAM Premium 2018"
        },
        {
            featureid: "86870ACPLH_F",
            description: "ArtCAM Premium Package"
        },
        {
            featureid: "86870ACPLH_T_F",
            description: "ArtCAM Premium Package Term"
        },
        {
            featureid: "86871ACSLH_F",
            description: "ArtCAM Standard Package"
        },
        {
            featureid: "86871ACSLH_T_F",
            description: "ArtCAM Standard Package Term"
        },
        {
            featureid: "86872FCMPLH_F",
            description: "FeatureCAM Premium Package"
        },
        {
            featureid: "86872FCMPLH_T_F",
            description: "FeatureCAM Premium Package Term"
        },
        {
            featureid: "86873FCMSLH_F",
            description: "FeatureCAM Standard Package"
        },
        {
            featureid: "86873FCMSLH_T_F",
            description: "FeatureCAM Standard Package Term"
        },
        {
            featureid: "86874FCMULH_F",
            description: "FeatureCAM Ultimate Package"
        },
        {
            featureid: "86874FCMULH_T_F",
            description: "FeatureCAM Ultimate Package Term"
        },
        {
            featureid: "86875PWIPLH_F",
            description: "PowerInspect Premium Package"
        },
        {
            featureid: "86875PWIPLH_T_F",
            description: "PowerInspect Premium Package Term"
        },
        {
            featureid: "86876PWISLH_F",
            description: "PowerInspect Standard Package"
        },
        {
            featureid: "86876PWISLH_T_F",
            description: "PowerInspect Standard Package Term"
        },
        {
            featureid: "86877PWIULH_F",
            description: "PowerInspect Ultimate Package"
        },
        {
            featureid: "86877PWIULH_T_F",
            description: "PowerInspect Ultimate Package Term"
        },
        {
            featureid: "86878PMPLHD_F",
            description: "PowerMill Premium Package"
        },
        {
            featureid: "86878PMPLHD_T_F",
            description: "PowerMill Premium Package Term"
        },
        {
            featureid: "86879PMSLHD_F",
            description: "PowerMill Standard Package"
        },
        {
            featureid: "86879PMSLHD_T_F",
            description: "PowerMill Standard Package Term"
        },
        {
            featureid: "86880PMULHD_F",
            description: "PowerMill Ultimate Package"
        },
        {
            featureid: "86880PMULHD_T_F",
            description: "PowerMill Ultimate Package Term"
        },
        {
            featureid: "86881PWSPLH_F",
            description: "PowerShape Premium Package"
        },
        {
            featureid: "86881PWSPLH_T_F",
            description: "PowerShape Premium Package Term"
        },
        {
            featureid: "86882PWSSLH_F",
            description: "PowerShape Standard Package"
        },
        {
            featureid: "86882PWSSLH_T_F",
            description: "PowerShape Standard Package Term"
        },
        {
            featureid: "86883PWSULH_F",
            description: "PowerShape Ultimate Package"
        },
        {
            featureid: "86883PWSULH_T_F",
            description: "PowerShape Ultimate Package Term"
        },
        {
            featureid: "86884MAYA_2018_0F",
            description: "Autodesk Maya 2018"
        },
        {
            featureid: "86885MOBPRO_2018_0F",
            description: "Autodesk MotionBuilder 2018"
        },
        {
            featureid: "86886MBXPRO_2018_0F",
            description: "Autodesk Mudbox 2018"
        },
        {
            featureid: "86887MAYALT_2018_0F",
            description: "Autodesk Maya LT 2018"
        },
        {
            featureid: "86888MAYAIO_2018_0F",
            description: "Autodesk Maya IO 2018"
        },
        {
            featureid: "86889MYECS_2018_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2018"
        },
        {
            featureid: "86890MAYAS_2018_0F",
            description: "Autodesk Maya 2018 with Softimage"
        },
        {
            featureid: "86891ENCSU_2018_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2018"
        },
        {
            featureid: "86892MXECS_2018_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2018"
        },
        {
            featureid: "86893CIV3D_2018_0F",
            description: "Autodesk AutoCAD Civil 3D 2018"
        },
        {
            featureid: "86894PLNT3D_2018_0F",
            description: "Autodesk AutoCAD Plant 3D 2018"
        },
        {
            featureid: "86895PNID_2018_0F",
            description: "Autodesk AutoCAD P&ID 2018"
        },
        {
            featureid: "86896PDSU_2018_0F",
            description: "Autodesk Product Design Suite Ultimate 2018"
        },
        {
            featureid: "86897PDSP_2018_0F",
            description: "Autodesk Product Design Suite Premium 2018"
        },
        {
            featureid: "86898SFS_2018_0F",
            description: "Autodesk Structural Fabrication Suite 2018"
        },
        {
            featureid: "86899DSPRM_2018_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2018"
        },
        {
            featureid: "86900DSSTD_2018_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2018"
        },
        {
            featureid: "86901DSADV_2018_0F",
            description: "Autodesk AutoCAD Design Suite Ultimate 2018"
        },
        {
            featureid: "86902RVTLTS_2018_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2018"
        },
        {
            featureid: "86903BDSPRM_2018_0F",
            description: "Autodesk Building Design Suite Premium 2018"
        },
        {
            featureid: "86904BDSS_2018_0F",
            description: "Autodesk Building Design Suite Standard 2018"
        },
        {
            featureid: "86905BDSADV_2018_0F",
            description: "Autodesk Building Design Suite Ultimate 2018"
        },
        {
            featureid: "86906FDSPRM_2018_0F",
            description: "Autodesk Factory Design Suite Premium 2018"
        },
        {
            featureid: "86907FDSS_2018_0F",
            description: "Autodesk Factory Design Suite Standard 2018"
        },
        {
            featureid: "86908FDSADV_2018_0F",
            description: "Autodesk Factory Design Suite Ultimate 2018"
        },
        {
            featureid: "86909IDSP_2018_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2018"
        },
        {
            featureid: "86910IDSS_2018_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2018"
        },
        {
            featureid: "86911IDSU_2018_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2018"
        },
        {
            featureid: "86912MEPCS_2018_0F",
            description: "Autodesk MEP Fabrication Suite 2018"
        },
        {
            featureid: "86913PDSPRM_2018_0F",
            description: "Autodesk Plant Design Suite Premium 2018"
        },
        {
            featureid: "86914PLTDSS_2018_0F",
            description: "Autodesk Plant Design Suite Standard 2018"
        },
        {
            featureid: "86915PDSADV_2018_0F",
            description: "Autodesk Plant Design Suite Ultimate 2018"
        },
        {
            featureid: "86916ACDMAC_2018_0F",
            description: "Autodesk AutoCAD 2018 for Mac"
        },
        {
            featureid: "86917RVT_2018_0F",
            description: "Autodesk Revit 2018"
        },
        {
            featureid: "86918RVTLT_2018_0F",
            description: "Autodesk Revit LT 2018"
        },
        {
            featureid: "86919SBRDES_2018_0F",
            description: "Autodesk Structural Bridge Design 2018"
        },
        {
            featureid: "86920VEHTRK_2018_0F",
            description: "Autodesk Vehicle Tracking 2018"
        },
        {
            featureid: "86921NTFABLS_F",
            description: "Autodesk Netfabb Local Simulation Package"
        },
        {
            featureid: "86921NTFABLS_T_F",
            description: "Autodesk Netfabb Local Simulation Package Term"
        },
        {
            featureid: "86922HSMPRM_2017_0F",
            description: "HSMWorks Ultimate 2017"
        },
        {
            featureid: "86923HSMPRO_2017_0F",
            description: "HSMWorks Premium 2017"
        },
        {
            featureid: "86924MAXIO_F",
            description: "Autodesk 3ds Max IO Package"
        },
        {
            featureid: "86924MAXIO_T_F",
            description: "Autodesk 3ds Max IO Package Term"
        },
        {
            featureid: "86925MAXIO_2018_0F",
            description: "Autodesk 3ds Max IO 2018"
        },
        {
            featureid: "86926INVLTS_2018_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2018"
        },
        {
            featureid: "86927IETODEV_2018_0F",
            description: "Autodesk Inventor Engineer-to-Order 2018 - Developer"
        },
        {
            featureid: "86928IETODIS_2018_0F",
            description: "Autodesk Inventor Engineer-to-Order 2018 - Distribution"
        },
        {
            featureid: "86929IETOSRV_2018_0F",
            description: "Autodesk Inventor Engineer-to-Order 2018 - Server"
        },
        {
            featureid: "86930INVETO_2018_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2018"
        },
        {
            featureid: "86931INTSER_2018_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2018"
        },
        {
            featureid: "86932INVHSM_2018_0F",
            description: "Autodesk Inventor HSM Premium 2018"
        },
        {
            featureid: "86933INHSMP_2018_0F",
            description: "Autodesk Inventor HSM Ultimate 2018"
        },
        {
            featureid: "86934TRUFBR_2018_0F",
            description: "Autodesk TruFiber 2018"
        },
        {
            featureid: "86935TRULSR_2018_0F",
            description: "Autodesk TruLaser 2018"
        },
        {
            featureid: "86936TRUPLN_2018_0F",
            description: "Autodesk TruPlan 2018"
        },
        {
            featureid: "86937LIVE_2018_0F",
            description: "Autodesk Live"
        },
        {
            featureid: "86937LIVE_F",
            description: "Autodesk Live Package"
        },
        {
            featureid: "86937LIVE_T_F",
            description: "Autodesk Live Package Term"
        },
        {
            featureid: "86938CADMEP_2018_0F",
            description: "Autodesk Fabrication CADmep 2018"
        },
        {
            featureid: "86939ESTMEP_2018_0F",
            description: "Autodesk Fabrication ESTmep 2018"
        },
        {
            featureid: "86940CAMDCT_2018_0F",
            description: "Autodesk Fabrication CAMduct 2018"
        },
        {
            featureid: "86941RSAPRO_2018_0F",
            description: "Autodesk Robot Structural Analysis Professional 2018"
        },
        {
            featureid: "86942ACSLH_2018_0F",
            description: "ArtCAM Standard 2018"
        },
        {
            featureid: "86943FCMPLH_2018_0F",
            description: "FeatureCAM Premium 2018"
        },
        {
            featureid: "86944FCMSLH_2018_0F",
            description: "FeatureCAM Standard 2018"
        },
        {
            featureid: "86945FCMULH_2018_0F",
            description: "FeatureCAM Ultimate 2018"
        },
        {
            featureid: "86946PWIPLH_2018_0F",
            description: "PowerInspect Premium 2018"
        },
        {
            featureid: "86947PWISLH_2018_0F",
            description: "PowerInspect Standard 2018"
        },
        {
            featureid: "86948PWIULH_2018_0F",
            description: "PowerInspect Ultimate 2018"
        },
        {
            featureid: "86949PMPLHD_2018_0F",
            description: "PowerMill Premium 2018"
        },
        {
            featureid: "86950PMSLHD_2018_0F",
            description: "PowerMill Standard 2018"
        },
        {
            featureid: "86951PMULHD_2018_0F",
            description: "PowerMill Ultimate 2018"
        },
        {
            featureid: "86952PWSPLH_2018_0F",
            description: "PowerShape Premium 2018"
        },
        {
            featureid: "86953PWSSLH_2018_0F",
            description: "PowerShape Standard 2018"
        },
        {
            featureid: "86954PWSULH_2018_0F",
            description: "PowerShape Ultimate 2018"
        },
        {
            featureid: "86955ARTCE_2018_0F",
            description: "Autodesk ArtCAM Express 2018"
        },
        {
            featureid: "86956ARTCP_2018_0F",
            description: "Autodesk ArtCAM Premium 2018"
        },
        {
            featureid: "86957ARTCS_2018_0F",
            description: "Autodesk ArtCAM Standard 2018"
        },
        {
            featureid: "86958FCAMP_2018_0F",
            description: "Autodesk FeatureCAM Premium 2018"
        },
        {
            featureid: "86959FCAMS_2018_0F",
            description: "Autodesk FeatureCAM Standard 2018"
        },
        {
            featureid: "86960FCAMU_2018_0F",
            description: "Autodesk FeatureCAM Ultimate 2018"
        },
        {
            featureid: "86961MPPUS_2018_0F",
            description: "Autodesk Manufacturing Automation Utility 2018"
        },
        {
            featureid: "86962MFGDEXUTLP_2018_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium 2018"
        },
        {
            featureid: "86963MDEUS_2018_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Standard 2018"
        },
        {
            featureid: "86964MFGPPUTL_2018_0F",
            description: "Autodesk Manufacturing Post Processor Utility 2018"
        },
        {
            featureid: "86965PMAKER_2018_0F",
            description: "Autodesk PartMaker 2018"
        },
        {
            featureid: "86966PWRIP_2018_0F",
            description: "Autodesk PowerInspect Premium 2018"
        },
        {
            featureid: "86967PWRIS_2018_0F",
            description: "Autodesk PowerInspect Standard 2018"
        },
        {
            featureid: "86968PWRIU_2018_0F",
            description: "Autodesk PowerInspect Ultimate 2018"
        },
        {
            featureid: "86969PWRMMOD_2018_0F",
            description: "Autodesk PowerMill Modeling 2018"
        },
        {
            featureid: "86970PWRMP_2018_0F",
            description: "Autodesk PowerMill Premium 2018"
        },
        {
            featureid: "86971PWRMS_2018_0F",
            description: "Autodesk PowerMill Standard 2018"
        },
        {
            featureid: "86972PWRMU_2018_0F",
            description: "Autodesk PowerMill Ultimate 2018"
        },
        {
            featureid: "86973PWRSP_2018_0F",
            description: "Autodesk PowerShape Premium 2018"
        },
        {
            featureid: "86974PWRSS_2018_0F",
            description: "Autodesk PowerShape Standard 2018"
        },
        {
            featureid: "86975PWRSU_2018_0F",
            description: "Autodesk PowerShape Ultimate 2018"
        },
        {
            featureid: "86976NETFA_2018_0F",
            description: "Autodesk Netfabb Premium 2018"
        },
        {
            featureid: "86977NETFS_2018_0F",
            description: "Autodesk Netfabb Standard 2018"
        },
        {
            featureid: "86978NETFE_2018_0F",
            description: "Autodesk Netfabb Ultimate 2018"
        },
        {
            featureid: "86979PCOFFI_2018_0F",
            description: "Autodesk Vault Office 2018"
        },
        {
            featureid: "86980VLTM_2018_0F",
            description: "Autodesk Vault Professional 2018"
        },
        {
            featureid: "86981VLTWG_2018_0F",
            description: "Autodesk Vault Workgroup 2018"
        },
        {
            featureid: "86982NSTRN_2018_0F",
            description: "Autodesk Nastran 2018"
        },
        {
            featureid: "86983NINCAD_2018_0F",
            description: "Autodesk Nastran In-CAD 2018"
        },
        {
            featureid: "86984RECAP_2018_0F",
            description: "Autodesk ReCap Pro"
        },
        {
            featureid: "86985ARNOL_2018_0F",
            description: "Arnold 2018"
        },
        {
            featureid: "86985ARNOL_F",
            description: "Arnold Package"
        },
        {
            featureid: "86985ARNOL_T_F",
            description: "Arnold Package Term"
        },
        {
            featureid: "86986ALSCPT_2018_0F",
            description: "Autodesk Alias Concept 2018"
        },
        {
            featureid: "86987SMKDS_2018_0F",
            description: "Autodesk Smoke 2018 Desktop Rental"
        },
        {
            featureid: "86988A250_2018_0F",
            description: "Autodesk Flame 2018"
        },
        {
            featureid: "86988A250_F",
            description: "Autodesk Flame Package"
        },
        {
            featureid: "86988A250_T_F",
            description: "Autodesk Flame Package Term"
        },
        {
            featureid: "86989A350_2018_0F",
            description: "Autodesk Flame Assist 2018"
        },
        {
            featureid: "86989A350_F",
            description: "Autodesk Flame Assist Package"
        },
        {
            featureid: "86989A350_T_F",
            description: "Autodesk Flame Assist Package Term"
        },
        {
            featureid: "86990A400_2018_0F",
            description: "Autodesk Flare 2018"
        },
        {
            featureid: "86990A400_F",
            description: "Autodesk Flare Package"
        },
        {
            featureid: "86990A400_T_F",
            description: "Autodesk Flare Package Term"
        },
        {
            featureid: "86991K100_2018_0F",
            description: "Autodesk Lustre 2018"
        },
        {
            featureid: "86991K100_F",
            description: "Autodesk Lustre Package"
        },
        {
            featureid: "86991K100_T_F",
            description: "Autodesk Lustre Package Term"
        },
        {
            featureid: "86992A280_2018_0F",
            description: "Autodesk Flame Premium 2018"
        },
        {
            featureid: "86992A280_F",
            description: "Autodesk Flame Premium Package"
        },
        {
            featureid: "86992A280_T_F",
            description: "Autodesk Flame Premium Package Term"
        },
        {
            featureid: "86993C300_2018_0F",
            description: "Autodesk Burn 2018"
        },
        {
            featureid: "86993C300_F",
            description: "Autodesk Burn Package"
        },
        {
            featureid: "86993C300_T_F",
            description: "Autodesk Burn Package Term"
        },
        {
            featureid: "86994WTGTWY_2018_0F",
            description: "Autodesk Wiretap Gateway 2018"
        },
        {
            featureid: "86994WTGTWY_F",
            description: "Autodesk Wiretap Gateway Package"
        },
        {
            featureid: "86994WTGTWY_T_F",
            description: "Autodesk Wiretap Gateway Package Term"
        },
        {
            featureid: "86995LSTRBRN_2018_0F",
            description: "Autodesk Lustre Burn 2018"
        },
        {
            featureid: "86995LSTRBRN_F",
            description: "Autodesk Lustre Burn Package"
        },
        {
            featureid: "86995LSTRBRN_T_F",
            description: "Autodesk Lustre Burn Package Term"
        },
        {
            featureid: "86996LSTRSR_2018_0F",
            description: "Autodesk Lustre ShotReactor 2018"
        },
        {
            featureid: "86996LSTRSR_F",
            description: "Autodesk Lustre ShotReactor Package"
        },
        {
            featureid: "86996LSTRSR_T_F",
            description: "Autodesk Lustre ShotReactor Package Term"
        },
        {
            featureid: "86997ALSSF_2018_0F",
            description: "Autodesk Alias SpeedForm 2018"
        },
        {
            featureid: "86998NAVMAN_2018_0F",
            description: "Autodesk Navisworks Manage 2018"
        },
        {
            featureid: "86999NAVSIM_2018_0F",
            description: "Autodesk Navisworks Simulate 2018"
        },
        {
            featureid: "87000VRDPRO_2018_0F",
            description: "Autodesk VRED Professional 2018"
        },
        {
            featureid: "87001HSMULT_F",
            description: "Autodesk HSM Ultimate Package"
        },
        {
            featureid: "87001HSMULT_T_F",
            description: "Autodesk HSM Ultimate Package Term"
        },
        {
            featureid: "87002HSMPREM_F",
            description: "Autodesk HSM Premium Package"
        },
        {
            featureid: "87002HSMPREM_T_F",
            description: "Autodesk HSM Premium Package Term"
        },
        {
            featureid: "87003HSMULT_2018_0F",
            description: "Autodesk HSM Ultimate 2018"
        },
        {
            featureid: "87004HSMPREM_2018_0F",
            description: "Autodesk HSM Premium 2018"
        },
        {
            featureid: "87005ASMES_2018_0F",
            description: "Autodesk Simulation Mechanical 2018"
        },
        {
            featureid: "87006NTFABLS_2018_0F",
            description: "Autodesk Netfabb Local Simulation 2018"
        },
        {
            featureid: "87007ACMPDS_2018_0F",
            description: "Autodesk Helius Composite 2018"
        },
        {
            featureid: "87008ACMPAN_2018_0F",
            description: "Autodesk Helius PFA 2018"
        },
        {
            featureid: "87009PNTLAY_2018_0F",
            description: "Autodesk Point Layout 2018"
        },
        {
            featureid: "87010MFAM_2018_0F",
            description: "Autodesk Moldflow Adviser Premium 2018"
        },
        {
            featureid: "87011MFAA_2018_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2018"
        },
        {
            featureid: "87012MFCD_2018_0F",
            description: "CADdoctor for Autodesk Simulation 2018"
        },
        {
            featureid: "87013ACDLTC_2018_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2018"
        },
        {
            featureid: "87014MFIP_2018_0F",
            description: "Autodesk Moldflow Insight Premium 2018"
        },
        {
            featureid: "87015MFIB_2018_0F",
            description: "Autodesk Moldflow Insight Standard 2018"
        },
        {
            featureid: "87016MFIA_2018_0F",
            description: "Autodesk Moldflow Insight Ultimate 2018"
        },
        {
            featureid: "87017MFS_2018_0F",
            description: "Autodesk Moldflow Synergy 2018"
        },
        {
            featureid: "870183DSMXS_2018_0F",
            description: "Autodesk 3ds Max 2018 with Softimage"
        },
        {
            featureid: "87019FLMEDU_2018_0F",
            description: "Autodesk Flame 2018 - Education"
        },
        {
            featureid: "87019FLMEDU_F",
            description: "Autodesk Flame - Education Package"
        },
        {
            featureid: "87019FLMEDU_T_F",
            description: "Autodesk Flame - Education Package Term"
        },
        {
            featureid: "87020SBPNL_2018_0F",
            description: "Autodesk SketchBook for Enterprise"
        },
        {
            featureid: "87021ADKVRD_2018_0F",
            description: "Autodesk VRED 2018"
        },
        {
            featureid: "87022VRDDES_2018_0F",
            description: "Autodesk VRED Design 2018"
        },
        {
            featureid: "87023VRDPRS_2018_0F",
            description: "Autodesk VRED Presenter 2018"
        },
        {
            featureid: "87024VRNCBL_2018_0F",
            description: "Autodesk VRED Render Node - consumption-based license 2018"
        },
        {
            featureid: "87025RCMVRD_2018_0F",
            description: "Autodesk VRED Render Node 2018"
        },
        {
            featureid: "87026VRDSRV_2018_0F",
            description: "Autodesk VRED Server 2018"
        },
        {
            featureid: "87027SIMDFM_2018_0F",
            description: "Autodesk Moldflow Design 2018"
        },
        {
            featureid: "87028SCFD_2018_0F",
            description: "Autodesk CFD 2018"
        },
        {
            featureid: "87029SCFDA_2018_0F",
            description: "Autodesk CFD 2018 Advanced"
        },
        {
            featureid: "87030SCDSE_2018_0F",
            description: "Autodesk CFD 2018 Design Study Environment"
        },
        {
            featureid: "87031SCFDM_2018_0F",
            description: "Autodesk CFD 2018 Motion"
        },
        {
            featureid: "87032PLC0000023_2018_0F",
            description: "Autodesk SimStudio Tools 2016 R3"
        },
        {
            featureid: "87033IMS_2018_0F",
            description: "Autodesk Infrastructure Map Server 2018"
        },
        {
            featureid: "87034IMS5A_2018_0F",
            description: "Autodesk Infrastructure Map Server 5 Activations 2018"
        },
        {
            featureid: "87035HSMPRO_2018_0F",
            description: "HSMWorks Premium 2018"
        },
        {
            featureid: "87036HSMPRM_2018_0F",
            description: "HSMWorks Ultimate 2018"
        },
        {
            featureid: "87037TRUCOM_2018_0F",
            description: "Autodesk TruComp 2018"
        },
        {
            featureid: "87038TRUNST_2018_0F",
            description: "Autodesk TruNest 2018 - Nesting Engine"
        },
        {
            featureid: "87039TNCOMP_2018_0F",
            description: "Autodesk TruNest Composites 2018"
        },
        {
            featureid: "87040TNCONT_2018_0F",
            description: "Autodesk TruNest Contour 2018"
        },
        {
            featureid: "87041TNMULT_2018_0F",
            description: "Autodesk TruNest Multi-Tool 2018"
        },
        {
            featureid: "87042T1MF_2018_0F",
            description: "T1 Enterprise Multi-flex 2018"
        },
        {
            featureid: "87043EMFEB_2018_0F",
            description: "Ent Multi-Flex Enhanced Bndl 2018"
        },
        {
            featureid: "87044T1MFPV_2018_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2018"
        },
        {
            featureid: "87045T1MFPB_2018_0F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle 2018"
        },
        {
            featureid: "87046T1MFSB_2018_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2018"
        },
        {
            featureid: "87047MAYA_2019_0F",
            description: "Autodesk Maya 2019"
        },
        {
            featureid: "87049PDSU_2019_0F",
            description: "Autodesk Product Design Suite Ultimate 2019"
        },
        {
            featureid: "87050PDSP_2019_0F",
            description: "Autodesk Product Design Suite Premium 2019"
        },
        {
            featureid: "87051FDSADV_2019_0F",
            description: "Autodesk Factory Design Suite Ultimate 2019"
        },
        {
            featureid: "87052FDSS_2019_0F",
            description: "Autodesk Factory Design Suite Standard 2019"
        },
        {
            featureid: "87053FDSPRM_2019_0F",
            description: "Autodesk Factory Design Suite Premium 2019"
        },
        {
            featureid: "87054BDSADV_2019_0F",
            description: "Autodesk Building Design Suite Ultimate 2019"
        },
        {
            featureid: "87055PDSADV_2019_0F",
            description: "Autodesk Plant Design Suite Ultimate 2019"
        },
        {
            featureid: "87056INVLTS_2019_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2019"
        },
        {
            featureid: "87057HSMULT_2019_0F",
            description: "Autodesk HSM Ultimate 2019"
        },
        {
            featureid: "87058HSMPREM_2019_0F",
            description: "Autodesk HSM Premium 2019"
        },
        {
            featureid: "87059INHSMP_2019_0F",
            description: "Autodesk Inventor HSM Ultimate 2019"
        },
        {
            featureid: "87060INVHSM_2019_0F",
            description: "Autodesk Inventor HSM Premium 2019"
        },
        {
            featureid: "87061FDU_2019_0F",
            description: "Autodesk Factory Design Utilities 2019"
        },
        {
            featureid: "87062INVLT_2019_0F",
            description: "Autodesk Inventor LT 2019"
        },
        {
            featureid: "87063TRUFBR_2019_0F",
            description: "Autodesk TruFiber 2019"
        },
        {
            featureid: "87064TRULSR_2019_0F",
            description: "Autodesk TruLaser 2019"
        },
        {
            featureid: "87065TRUPLN_2019_0F",
            description: "Autodesk TruPlan 2019"
        },
        {
            featureid: "87066INVOEM_2019_0F",
            description: "Autodesk Inventor OEM 2019"
        },
        {
            featureid: "87067INVNTOR_2019_0F",
            description: "Autodesk Inventor 2019"
        },
        {
            featureid: "87068INVPROSA_2019_0F",
            description: "Autodesk Inventor Professional 2019"
        },
        {
            featureid: "870723DSMAX_2019_0F",
            description: "Autodesk 3ds Max 2019"
        },
        {
            featureid: "87077MOBPRO_2019_0F",
            description: "Autodesk MotionBuilder 2019"
        },
        {
            featureid: "87079ALAUST_2019_0F",
            description: "Autodesk Alias AutoStudio 2019"
        },
        {
            featureid: "87080ALSCPT_2019_0F",
            description: "Autodesk Alias Concept 2019"
        },
        {
            featureid: "87081DESNST_2019_0F",
            description: "Autodesk Alias Design 2019"
        },
        {
            featureid: "87082ALSSF_2019_0F",
            description: "Autodesk Alias SpeedForm 2019"
        },
        {
            featureid: "87083SURFST_2019_0F",
            description: "Autodesk Alias Surface 2019"
        },
        {
            featureid: "87084ACD_2019_0F",
            description: "Autodesk AutoCAD 2019"
        },
        {
            featureid: "87085ACDLT_2019_0F",
            description: "Autodesk AutoCAD LT 2019"
        },
        {
            featureid: "87086ARDES_2019_0F",
            description: "Autodesk AutoCAD Raster Design 2019"
        },
        {
            featureid: "87087ARCHDESK_2019_0F",
            description: "Autodesk AutoCAD Architecture 2019"
        },
        {
            featureid: "87088ACAD_E_2019_0F",
            description: "Autodesk AutoCAD Electrical 2019"
        },
        {
            featureid: "87089AMECH_PP_2019_0F",
            description: "Autodesk AutoCAD Mechanical 2019"
        },
        {
            featureid: "87090BLDSYS_2019_0F",
            description: "Autodesk AutoCAD MEP 2019"
        },
        {
            featureid: "87091MAP_2019_0F",
            description: "Autodesk AutoCAD Map 3D 2019"
        },
        {
            featureid: "87093RECAP_2019_0F",
            description: "Autodesk ReCap Pro"
        },
        {
            featureid: "87094MEPCS_2019_0F",
            description: "Autodesk MEP Fabrication Suite 2019"
        },
        {
            featureid: "87096ADSTPR_2019_0F",
            description: "Autodesk Advance Steel 2019"
        },
        {
            featureid: "87098MFIAT_F",
            description: "Autodesk Moldflow Insight Ultimate TFLEX Package"
        },
        {
            featureid: "87098MFIAT_T_F",
            description: "Autodesk Moldflow Insight Ultimate TFLEX Package Term"
        },
        {
            featureid: "87099MFST_F",
            description: "Autodesk Moldflow Synergy TFLEX Package"
        },
        {
            featureid: "87099MFST_T_F",
            description: "Autodesk Moldflow Synergy TFLEX Package Term"
        },
        {
            featureid: "87103FCAMP_2019_0F",
            description: "Autodesk FeatureCAM Premium 2019"
        },
        {
            featureid: "87104FCAMS_2019_0F",
            description: "Autodesk FeatureCAM Standard 2019"
        },
        {
            featureid: "87105FCAMU_2019_0F",
            description: "Autodesk FeatureCAM Ultimate 2019"
        },
        {
            featureid: "87106MPPUS_2019_0F",
            description: "Autodesk Manufacturing Automation Utility 2019"
        },
        {
            featureid: "87107MFGDEXUTLP_2019_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium 2019"
        },
        {
            featureid: "87108MDEUS_2019_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Standard 2019"
        },
        {
            featureid: "87109MFGPPUTL_2019_0F",
            description: "Autodesk Manufacturing Post Processor Utility 2019"
        },
        {
            featureid: "87111NETFE_2019_0F",
            description: "Autodesk Netfabb Ultimate 2019"
        },
        {
            featureid: "87112PMAKER_2019_0F",
            description: "Autodesk PartMaker 2019"
        },
        {
            featureid: "87113PWRIP_2019_0F",
            description: "Autodesk PowerInspect Premium 2019"
        },
        {
            featureid: "87114PWRIS_2019_0F",
            description: "Autodesk PowerInspect Standard 2019"
        },
        {
            featureid: "87115PWRIU_2019_0F",
            description: "Autodesk PowerInspect Ultimate 2019"
        },
        {
            featureid: "87116PWRMMOD_2019_0F",
            description: "Autodesk PowerMill Modeling 2019"
        },
        {
            featureid: "87117PWRMP_2019_0F",
            description: "Autodesk PowerMill Premium 2019"
        },
        {
            featureid: "87118PWRMS_2019_0F",
            description: "Autodesk PowerMill Standard 2019"
        },
        {
            featureid: "87119PWRMU_2019_0F",
            description: "Autodesk PowerMill Ultimate 2019"
        },
        {
            featureid: "87120PWRSP_2019_0F",
            description: "Autodesk PowerShape Premium 2019"
        },
        {
            featureid: "87121PWRSS_2019_0F",
            description: "Autodesk PowerShape Standard 2019"
        },
        {
            featureid: "87122PWRSU_2019_0F",
            description: "Autodesk PowerShape Ultimate 2019"
        },
        {
            featureid: "87123DSPRM_2019_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2019"
        },
        {
            featureid: "87124DSSTD_2019_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2019"
        },
        {
            featureid: "87125ACDMAC_2019_0F",
            description: "Autodesk AutoCAD 2019 for Mac"
        },
        {
            featureid: "87128FCMPLH_2019_0F",
            description: "FeatureCAM Premium 2019"
        },
        {
            featureid: "87129FCMSLH_2019_0F",
            description: "FeatureCAM Standard 2019"
        },
        {
            featureid: "87130FCMULH_2019_0F",
            description: "FeatureCAM Ultimate 2019"
        },
        {
            featureid: "87131PWIPLH_2019_0F",
            description: "PowerInspect Premium 2019"
        },
        {
            featureid: "87132PWISLH_2019_0F",
            description: "PowerInspect Standard 2019"
        },
        {
            featureid: "87133PWIULH_2019_0F",
            description: "PowerInspect Ultimate 2019"
        },
        {
            featureid: "87134PMPLHD_2019_0F",
            description: "Autodesk PowerMill Premium 2019"
        },
        {
            featureid: "87134PMPLHD_F",
            description: "PowerMill Premium Package"
        },
        {
            featureid: "87134PMPLHD_T_F",
            description: "PowerMill Premium Package Term"
        },
        {
            featureid: "87135PMSLHD_2019_0F",
            description: "PowerMill Standard 2019"
        },
        {
            featureid: "87136PMULHD_2019_0F",
            description: "PowerMill Ultimate 2019"
        },
        {
            featureid: "87137PWSPLH_2019_0F",
            description: "PowerShape Premium 2019"
        },
        {
            featureid: "87138PWSSLH_2019_0F",
            description: "PowerShape Standard 2019"
        },
        {
            featureid: "87139PWSULH_2019_0F",
            description: "PowerShape Ultimate 2019"
        },
        {
            featureid: "87140CIV3D_2019_0F",
            description: "Autodesk Civil 3D 2019"
        },
        {
            featureid: "87141RVTLTS_2019_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2019"
        },
        {
            featureid: "87142BDSPRM_2019_0F",
            description: "Autodesk Building Design Suite Premium 2019"
        },
        {
            featureid: "87143BDSS_2019_0F",
            description: "Autodesk Building Design Suite Standard 2019"
        },
        {
            featureid: "87144IDSP_2019_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2019"
        },
        {
            featureid: "87145IDSS_2019_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2019"
        },
        {
            featureid: "87146IDSU_2019_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2019"
        },
        {
            featureid: "87147PDSPRM_2019_0F",
            description: "Autodesk Plant Design Suite Premium 2019"
        },
        {
            featureid: "87148PLTDSS_2019_0F",
            description: "Autodesk Plant Design Suite Standard 2019"
        },
        {
            featureid: "87149RVT_2019_0F",
            description: "Autodesk Revit 2019"
        },
        {
            featureid: "87150RVTLT_2019_0F",
            description: "Autodesk Revit LT 2019"
        },
        {
            featureid: "87151SBRDES_2019_0F",
            description: "Autodesk Structural Bridge Design 2019"
        },
        {
            featureid: "87152VEHTRK_2019_0F",
            description: "Autodesk Vehicle Tracking 2019"
        },
        {
            featureid: "87153PLNT3D_2019_0F",
            description: "Autodesk AutoCAD Plant 3D 2019"
        },
        {
            featureid: "87154A250_2019_0F",
            description: "Autodesk Flame 2019"
        },
        {
            featureid: "87155FLMEDU_2019_0F",
            description: "Autodesk Flame 2019 - Education"
        },
        {
            featureid: "87156A350_2019_0F",
            description: "Autodesk Flame Assist 2019"
        },
        {
            featureid: "87157A280_2019_0F",
            description: "Autodesk Flame Premium 2019"
        },
        {
            featureid: "87158A400_2019_0F",
            description: "Autodesk Flare 2019"
        },
        {
            featureid: "87159K100_2019_0F",
            description: "Autodesk Lustre 2019"
        },
        {
            featureid: "87160LSTRBRN_2019_0F",
            description: "Autodesk Lustre Burn 2019"
        },
        {
            featureid: "87161LSTRSR_2019_0F",
            description: "Autodesk Lustre ShotReactor 2019"
        },
        {
            featureid: "87163C300_2019_0F",
            description: "Autodesk Burn 2019"
        },
        {
            featureid: "87164WTGTWY_2019_0F",
            description: "Autodesk Wiretap Gateway 2019"
        },
        {
            featureid: "87166CADMEP_2019_0F",
            description: "Autodesk Fabrication CADmep 2019"
        },
        {
            featureid: "87167CAMDCT_2019_0F",
            description: "Autodesk Fabrication CAMduct 2019"
        },
        {
            featureid: "87168ESTMEP_2019_0F",
            description: "Autodesk Fabrication ESTmep 2019"
        },
        {
            featureid: "87169ACDLTC_2019_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2019"
        },
        {
            featureid: "87170RSAPRO_2019_0F",
            description: "Autodesk Robot Structural Analysis Professional 2019"
        },
        {
            featureid: "871733DSMXS_2019_0F",
            description: "Autodesk 3ds Max 2019 with Softimage"
        },
        {
            featureid: "87174HSMPRO_2019_0F",
            description: "HSMWorks Premium 2019"
        },
        {
            featureid: "87175HSMPRM_2019_0F",
            description: "HSMWorks Ultimate 2019"
        },
        {
            featureid: "87176NAVMAN_2019_0F",
            description: "Autodesk Navisworks Manage 2019"
        },
        {
            featureid: "87177NAVSIM_2019_0F",
            description: "Autodesk Navisworks Simulate 2019"
        },
        {
            featureid: "87178SBPNL_2019_0F",
            description: "Autodesk SketchBook for Enterprise"
        },
        {
            featureid: "87179ADKVRD_2019_0F",
            description: "Autodesk VRED 2019"
        },
        {
            featureid: "87180VRDDES_2019_0F",
            description: "Autodesk VRED Design 2019"
        },
        {
            featureid: "87181VRDPRS_2019_0F",
            description: "Autodesk VRED Presenter 2019"
        },
        {
            featureid: "87182VRDPRO_2019_0F",
            description: "Autodesk VRED Professional 2019"
        },
        {
            featureid: "87183VRNCBL_2019_0F",
            description: "Autodesk VRED Render Node - consumption-based license 2019"
        },
        {
            featureid: "87184RCMVRD_2019_0F",
            description: "Autodesk VRED Render Node 2019"
        },
        {
            featureid: "87185VRDSRV_2019_0F",
            description: "Autodesk VRED Server 2019"
        },
        {
            featureid: "87186PNTLAY_2019_0F",
            description: "Autodesk Point Layout 2019"
        },
        {
            featureid: "87187PCOFFI_2019_0F",
            description: "Autodesk Vault Office 2019"
        },
        {
            featureid: "87188VLTM_2019_0F",
            description: "Autodesk Vault Professional 2019"
        },
        {
            featureid: "87189VLTWG_2019_0F",
            description: "Autodesk Vault Workgroup 2019"
        },
        {
            featureid: "87190ACMPAN_2019_0F",
            description: "Autodesk Helius PFA 2019"
        },
        {
            featureid: "87192MFAM_2019_0F",
            description: "Autodesk Moldflow Adviser Premium 2019"
        },
        {
            featureid: "87193MFAA_2019_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2019"
        },
        {
            featureid: "87194MFS_2019_0F",
            description: "Autodesk Moldflow Synergy 2019"
        },
        {
            featureid: "87195ACDIST_T_F",
            description: "AutoCAD - including specialized toolsets Package"
        },
        {
            featureid: "87196MFIP_2019_0F",
            description: "Autodesk Moldflow Insight Premium 2019"
        },
        {
            featureid: "87197MFIB_2019_0F",
            description: "Autodesk Moldflow Insight Standard 2019"
        },
        {
            featureid: "87198MFIA_2019_0F",
            description: "Autodesk Moldflow Insight Ultimate 2019"
        },
        {
            featureid: "87201SIMDFM_2019_0F",
            description: "Autodesk Moldflow Design 2019"
        },
        {
            featureid: "87202SCDSE_2019_0F",
            description: "Autodesk CFD Design Study Environment 2019"
        },
        {
            featureid: "87203SCFDA_2019_0F",
            description: "Autodesk CFD Premium 2019"
        },
        {
            featureid: "87204SCFDM_2019_0F",
            description: "Autodesk CFD Ultimate 2019"
        },
        {
            featureid: "87205NSTRN_2019_0F",
            description: "Autodesk Nastran 2019"
        },
        {
            featureid: "87206NINCAD_2019_0F",
            description: "Autodesk Nastran In-CAD 2019"
        },
        {
            featureid: "87207IETODEV_2019_0F",
            description: "Autodesk Inventor Engineer-to-Order 2019 - Developer"
        },
        {
            featureid: "87208IETODIS_2019_0F",
            description: "Autodesk Inventor Engineer-to-Order 2019 - Distribution"
        },
        {
            featureid: "87209IETOSRV_2019_0F",
            description: "Autodesk Inventor Engineer-to-Order 2019 - Server"
        },
        {
            featureid: "87210INVETO_2019_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2019"
        },
        {
            featureid: "87211TNNUINV_2019_0F",
            description: "Autodesk Nesting Utility 2019"
        },
        {
            featureid: "87211TNNUINV_F",
            description: "Autodesk Inventor Nesting Package"
        },
        {
            featureid: "87211TNNUINV_T_F",
            description: "Autodesk Inventor Nesting Package Term"
        },
        {
            featureid: "87212EMFEB_F",
            description: "Ent Multi-Flex Enhanced Bndl Package"
        },
        {
            featureid: "87213T1MF_2019_0F",
            description: "T1 Enterprise Multi-flex 2019"
        },
        {
            featureid: "87217INTSER_2019_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2019"
        },
        {
            featureid: "87218TRUCOM_2019_0F",
            description: "Autodesk TruComp 2019"
        },
        {
            featureid: "87219TRUNST_2019_0F",
            description: "Autodesk TruNest 2019 - Nesting Engine"
        },
        {
            featureid: "87220TNCOMP_2019_0F",
            description: "Autodesk TruNest Composites 2019"
        },
        {
            featureid: "87221TNCONT_2019_0F",
            description: "Autodesk TruNest Contour 2019"
        },
        {
            featureid: "87222TNMULT_2019_0F",
            description: "Autodesk TruNest Multi-Tool 2019"
        },
        {
            featureid: "87223RRI2ADD_F",
            description: "Revit  - with RIB iTWO add-on Package"
        },
        {
            featureid: "87223RRI2ADD_T_F",
            description: "Revit  - with RIB iTWO add-on Package Term"
        },
        {
            featureid: "87224ACD_2020_0F",
            description: "Autodesk AutoCAD 2020"
        },
        {
            featureid: "87225ACDLT_2020_0F",
            description: "Autodesk AutoCAD LT 2020"
        },
        {
            featureid: "87226ARDES_2020_0F",
            description: "Autodesk AutoCAD Raster Design 2020"
        },
        {
            featureid: "87227ARCHDESK_2020_0F",
            description: "Autodesk AutoCAD Architecture 2020"
        },
        {
            featureid: "87228ACAD_E_2020_0F",
            description: "Autodesk AutoCAD Electrical 2020"
        },
        {
            featureid: "87229AMECH_PP_2020_0F",
            description: "Autodesk AutoCAD Mechanical 2020"
        },
        {
            featureid: "87230BLDSYS_2020_0F",
            description: "Autodesk AutoCAD MEP 2020"
        },
        {
            featureid: "87231INVLTS_2020_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2020"
        },
        {
            featureid: "87232INVNTOR_2020_0F",
            description: "Autodesk Inventor 2020"
        },
        {
            featureid: "87233INVLT_2020_0F",
            description: "Autodesk Inventor LT 2020"
        },
        {
            featureid: "87234INVOEM_2020_0F",
            description: "Autodesk Inventor OEM 2020"
        },
        {
            featureid: "87235INVPROSA_2020_0F",
            description: "Autodesk Inventor Professional 2020"
        },
        {
            featureid: "87236PDSP_2020_0F",
            description: "Autodesk Product Design Suite Premium 2020"
        },
        {
            featureid: "87237PDSU_2020_0F",
            description: "Autodesk Product Design Suite Ultimate 2020"
        },
        {
            featureid: "87240MAP_2020_0F",
            description: "Autodesk AutoCAD Map 3D 2020"
        },
        {
            featureid: "87241PLNT3D_2020_0F",
            description: "Autodesk AutoCAD Plant 3D 2020"
        },
        {
            featureid: "872423DSMAX_2020_0F",
            description: "Autodesk 3ds Max 2020"
        },
        {
            featureid: "872433DSMXS_2020_0F",
            description: "Autodesk 3ds Max 2020 with Softimage"
        },
        {
            featureid: "87244MAXIO_2020_0F",
            description: "Autodesk 3ds Max IO 2020"
        },
        {
            featureid: "87245RSAPRO_2020_0F",
            description: "Autodesk Robot Structural Analysis Professional 2020"
        },
        {
            featureid: "87246ADSTPR_2020_0F",
            description: "Autodesk Advance Steel 2020"
        },
        {
            featureid: "87247RVTLTS_2020_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2020"
        },
        {
            featureid: "87248BDSPRM_2020_0F",
            description: "Autodesk Building Design Suite Premium 2020"
        },
        {
            featureid: "87249BDSS_2020_0F",
            description: "Autodesk Building Design Suite Standard 2020"
        },
        {
            featureid: "87250BDSADV_2020_0F",
            description: "Autodesk Building Design Suite Ultimate 2020"
        },
        {
            featureid: "87251CIV3D_2020_0F",
            description: "Autodesk Civil 3D 2020"
        },
        {
            featureid: "87252IDSP_2020_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2020"
        },
        {
            featureid: "87253IDSS_2020_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2020"
        },
        {
            featureid: "87254IDSU_2020_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2020"
        },
        {
            featureid: "87255MEPCS_2020_0F",
            description: "Autodesk MEP Fabrication Suite 2020"
        },
        {
            featureid: "87256PDSPRM_2020_0F",
            description: "Autodesk Plant Design Suite Premium 2020"
        },
        {
            featureid: "87257PLTDSS_2020_0F",
            description: "Autodesk Plant Design Suite Standard 2020"
        },
        {
            featureid: "87258PDSADV_2020_0F",
            description: "Autodesk Plant Design Suite Ultimate 2020"
        },
        {
            featureid: "87259RVT_2020_0F",
            description: "Autodesk Revit 2020"
        },
        {
            featureid: "87260RVTLT_2020_0F",
            description: "Autodesk Revit LT 2020"
        },
        {
            featureid: "87261SBRDES_2020_0F",
            description: "Autodesk Structural Bridge Design 2020"
        },
        {
            featureid: "87262SFS_2020_0F",
            description: "Autodesk Structural Fabrication Suite 2020"
        },
        {
            featureid: "87263VEHTRK_2020_0F",
            description: "Autodesk Vehicle Tracking 2020"
        },
        {
            featureid: "87264RRI2ADD_2020_0F",
            description: "Revit 2020 - with RIB iTWO add-on 2020"
        },
        {
            featureid: "87265HSMPREM_2020_0F",
            description: "Autodesk HSM Premium 2020"
        },
        {
            featureid: "87266HSMULT_2020_0F",
            description: "Autodesk HSM Ultimate 2020"
        },
        {
            featureid: "87267INVHSM_2020_0F",
            description: "Autodesk Inventor CAM Premium 2020"
        },
        {
            featureid: "87268INHSMP_2020_0F",
            description: "Autodesk Inventor HSM Ultimate 2020"
        },
        {
            featureid: "87269HSMPRM_2020_0F",
            description: "HSMWorks Ultimate 2020"
        },
        {
            featureid: "87270HSMPRO_2020_0F",
            description: "HSMWorks Premium 2020"
        },
        {
            featureid: "87271DSPRM_2020_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2020"
        },
        {
            featureid: "87272DSSTD_2020_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2020"
        },
        {
            featureid: "87273FDSPRM_2020_0F",
            description: "Autodesk Factory Design Suite Premium 2020"
        },
        {
            featureid: "87274FDSS_2020_0F",
            description: "Autodesk Factory Design Suite Standard 2020"
        },
        {
            featureid: "87275FDSADV_2020_0F",
            description: "Autodesk Factory Design Suite Ultimate 2020"
        },
        {
            featureid: "87276MXECS_2020_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite Standard 2020"
        },
        {
            featureid: "87277ENCSU_2020_0F",
            description: "Autodesk Entertainment Creation Suite Ultimate 2020"
        },
        {
            featureid: "87278MAYAS_2020_0F",
            description: "Autodesk Maya 2020 with Softimage"
        },
        {
            featureid: "87279MYECS_2020_0F",
            description: "Autodesk Maya Entertainment Creation Suite Standard 2020"
        },
        {
            featureid: "87280TRCMPS_2019_0F",
            description: "Autodesk TruComposites - Standard 2019"
        },
        {
            featureid: "87280TRCMPS_F",
            description: "Autodesk TruComposites - Standard Package"
        },
        {
            featureid: "87280TRCMPS_T_F",
            description: "Autodesk TruComposites - Standard Package Term"
        },
        {
            featureid: "87281TRCMPU_F",
            description: "Autodesk TruComposites Ultimate Package"
        },
        {
            featureid: "87281TRCMPU_T_F",
            description: "Autodesk TruComposites Ultimate Package Term"
        },
        {
            featureid: "87282RECAP_2020_0F",
            description: "Autodesk ReCap Pro 2020"
        },
        {
            featureid: "87283MAYA_2020_0F",
            description: "Autodesk Maya 2020"
        },
        {
            featureid: "87284INVTOL_2019_0F",
            description: "Autodesk Inventor Tolerance Analysis 2019"
        },
        {
            featureid: "87284INVTOL_F",
            description: "Autodesk Inventor Tolerance Analysis Package"
        },
        {
            featureid: "87284INVTOL_T_F",
            description: "Autodesk Inventor Tolerance Analysis Package Term"
        },
        {
            featureid: "87285ACDMAC_2020_0F",
            description: "Autodesk AutoCAD 2018 for Mac"
        },
        {
            featureid: "87286ACDLTM_2020_0F",
            description: "Autodesk AutoCAD LT 2020 for Mac"
        },
        {
            featureid: "87287ARNOL_2020_0F",
            description: "Arnold 2020"
        },
        {
            featureid: "87288ALAUST_2020_0F",
            description: "Autodesk Alias AutoStudio 2020"
        },
        {
            featureid: "87288ALAUST_2021_0F",
            description: "Autodesk Alias AutoStudio 2021"
        },
        {
            featureid: "87289SURFST_2020_0F",
            description: "Autodesk Alias Surface 2020"
        },
        {
            featureid: "87290ALSCPT_2020_0F",
            description: "Autodesk Alias Concept 2020"
        },
        {
            featureid: "87291DESNST_2020_0F",
            description: "Autodesk Alias Design 2020"
        },
        {
            featureid: "87292C300_2020_0F",
            description: "Autodesk Burn 2020"
        },
        {
            featureid: "87293A250_2020_0F",
            description: "Autodesk Flame 2020"
        },
        {
            featureid: "87294FLMEDU_2020_0F",
            description: "Autodesk Flame 2020 - Education"
        },
        {
            featureid: "87295A350_2020_0F",
            description: "Autodesk Flame Assist 2020"
        },
        {
            featureid: "87296A280_2020_0F",
            description: "Autodesk Flame Premium 2020"
        },
        {
            featureid: "87297A400_2020_0F",
            description: "Autodesk Flare 2020"
        },
        {
            featureid: "87298K100_2020_0F",
            description: "Autodesk Lustre 2020"
        },
        {
            featureid: "87299LSTRBRN_2020_0F",
            description: "Autodesk Lustre Burn 2020"
        },
        {
            featureid: "87300LSTRSR_2020_0F",
            description: "Autodesk Lustre ShotReactor 2020"
        },
        {
            featureid: "87301WTGTWY_2020_0F",
            description: "Autodesk Wiretap Gateway 2020"
        },
        {
            featureid: "87302NINCAD_2020_0F",
            description: "Autodesk Nastran In-CAD 2020"
        },
        {
            featureid: "87303IETODEV_2020_0F",
            description: "Autodesk Inventor Engineer-to-Order 2020 - Developer"
        },
        {
            featureid: "87304INTSER_2020_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2020"
        },
        {
            featureid: "87305INVETO_2020_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2020"
        },
        {
            featureid: "87306NAVMAN_2020_0F",
            description: "Autodesk Navisworks Manage 2020"
        },
        {
            featureid: "87307NAVSIM_2020_0F",
            description: "Autodesk Navisworks Simulate 2020"
        },
        {
            featureid: "87308PNTLAY_2020_0F",
            description: "Autodesk Point Layout 2020"
        },
        {
            featureid: "87309FDU_2020_0F",
            description: "Autodesk Factory Design Utilities 2020"
        },
        {
            featureid: "87310MAYAIO_2020_0F",
            description: "Autodesk Maya IO 2020"
        },
        {
            featureid: "87311MAYALT_2020_0F",
            description: "Autodesk Maya LT 2020"
        },
        {
            featureid: "87312MOBPRO_2020_0F",
            description: "Autodesk MotionBuilder 2020"
        },
        {
            featureid: "87313MBXPRO_2020_0F",
            description: "Autodesk Mudbox 2020"
        },
        {
            featureid: "87314ACDLTC_2020_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2020"
        },
        {
            featureid: "87315MFAM_2020_0F",
            description: "Autodesk Moldflow Adviser Premium 2020"
        },
        {
            featureid: "87316MFAA_2020_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2020"
        },
        {
            featureid: "87317MFIP_2020_0F",
            description: "Autodesk Moldflow Insight Premium 2020"
        },
        {
            featureid: "87318MFIB_2020_0F",
            description: "Autodesk Moldflow Insight Standard 2020"
        },
        {
            featureid: "87319MFIA_2020_0F",
            description: "Autodesk Moldflow Insight Ultimate 2020"
        },
        {
            featureid: "87320MFIAT_2020_0F",
            description: "Autodesk Moldflow Insight Ultimate TFLEX 2020"
        },
        {
            featureid: "87321MFS_2020_0F",
            description: "Autodesk Moldflow Synergy 2020"
        },
        {
            featureid: "87322MFST_2020_0F",
            description: "Autodesk Moldflow Synergy TFLEX 2020"
        },
        {
            featureid: "87323CADMEP_2020_0F",
            description: "Autodesk Fabrication CADmep 2020"
        },
        {
            featureid: "87324CAMDCT_2020_0F",
            description: "Autodesk Fabrication CAMduct 2020"
        },
        {
            featureid: "87325ESTMEP_2020_0F",
            description: "Autodesk Fabrication ESTmep 2020"
        },
        {
            featureid: "87326FCAMP_2020_0F",
            description: "Autodesk FeatureCAM Premium 2020"
        },
        {
            featureid: "87327FCAMS_2020_0F",
            description: "Autodesk FeatureCAM Standard 2020"
        },
        {
            featureid: "87328FCAMU_2020_0F",
            description: "Autodesk FeatureCAM Ultimate 2020"
        },
        {
            featureid: "87329MPPUS_2020_0F",
            description: "Autodesk Manufacturing Automation Utility 2020"
        },
        {
            featureid: "87330MFGDEXUTLP_2020_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium 2020"
        },
        {
            featureid: "87330MFGDEXUTLP_2021_0F",
            description: "Autodesk Manufacturing Data Exchange Utility Premium 2021"
        },
        {
            featureid: "87331NETFA_2020_0F",
            description: "Autodesk Netfabb Premium 2020"
        },
        {
            featureid: "87332NETFE_2020_0F",
            description: "Autodesk Netfabb Ultimate 2020"
        },
        {
            featureid: "87333PMAKER_2020_0F",
            description: "Autodesk PartMaker 2020"
        },
        {
            featureid: "87334PWRIP_2020_0F",
            description: "Autodesk PowerInspect Premium 2020"
        },
        {
            featureid: "87335PWRIS_2020_0F",
            description: "Autodesk PowerInspect Standard 2020"
        },
        {
            featureid: "87336PWRIU_2020_0F",
            description: "Autodesk PowerInspect Ultimate 2020"
        },
        {
            featureid: "87337PWRMMOD_2020_0F",
            description: "Autodesk PowerMill Modeling 2019"
        },
        {
            featureid: "87338PWRMP_2020_0F",
            description: "Autodesk PowerMill Premium 2020"
        },
        {
            featureid: "87339PWRMS_2020_0F",
            description: "Autodesk PowerMill Standard 2020"
        },
        {
            featureid: "87340PWRMU_2020_0F",
            description: "Autodesk PowerMill Ultimate 2020"
        },
        {
            featureid: "87341PWRSS_2020_0F",
            description: "Autodesk PowerShape Standard 2020"
        },
        {
            featureid: "87342PWRSU_2020_0F",
            description: "Autodesk PowerShape Ultimate 2020"
        },
        {
            featureid: "87343FCMPLH_2020_0F",
            description: "FeatureCAM Premium 2020"
        },
        {
            featureid: "87344FCMSLH_2020_0F",
            description: "FeatureCAM Standard 2020"
        },
        {
            featureid: "87345PWIPLH_2020_0F",
            description: "PowerInspect Premium 2020"
        },
        {
            featureid: "87346PWISLH_2020_0F",
            description: "PowerInspect Standard 2020"
        },
        {
            featureid: "87347PWIULH_2020_0F",
            description: "PowerInspect Ultimate 2020"
        },
        {
            featureid: "87348PMPLHD_2020_0F",
            description: "PowerMill Premium 2020"
        },
        {
            featureid: "87349PMSLHD_2020_0F",
            description: "PowerMill Standard 2020"
        },
        {
            featureid: "87350PMULHD_2020_0F",
            description: "PowerMill Ultimate 2020"
        },
        {
            featureid: "87351PWSPLH_2020_0F",
            description: "PowerShape Premium 2020"
        },
        {
            featureid: "87352PWSULH_2020_0F",
            description: "PowerShape Ultimate 2020"
        },
        {
            featureid: "87353PWSSLH_2020_0F",
            description: "PowerShape Standard 2020"
        },
        {
            featureid: "87354PCOFFI_2020_0F",
            description: "Autodesk Vault Office 2020"
        },
        {
            featureid: "87355VLTM_2020_0F",
            description: "Autodesk Vault Professional 2020"
        },
        {
            featureid: "87356VLTWG_2020_0F",
            description: "Autodesk Vault Workgroup 2020"
        },
        {
            featureid: "87357PWRSP_2020_0F",
            description: "Autodesk PowerShape Premium 2020"
        },
        {
            featureid: "87358FCMULH_2020_0F",
            description: "FeatureCAM Ultimate 2020"
        },
        {
            featureid: "87359NETFS_2020_0F",
            description: "Autodesk Netfabb Standard 2020"
        },
        {
            featureid: "87360SBPNL_2020_0F",
            description: "Autodesk SketchBook Pro"
        },
        {
            featureid: "87361TNNUINV_2020_0F",
            description: "Autodesk Nesting Utility 2020"
        },
        {
            featureid: "87362TRCMPS_2020_0F",
            description: "Autodesk TruComposites - Standard 2020"
        },
        {
            featureid: "87363TRCMPU_2020_0F",
            description: "Autodesk TruComposites Ultimate 2020"
        },
        {
            featureid: "87364TRUNEST_2020_0F",
            description: "Autodesk TruNest 2020"
        },
        {
            featureid: "87364TRUNEST_F",
            description: "Autodesk TruNest Package"
        },
        {
            featureid: "87364TRUNEST_T_F",
            description: "Autodesk TruNest Package Term"
        },
        {
            featureid: "87365TRUNST_2020_0F",
            description: "Autodesk TruNest 2020 - Nesting Engine"
        },
        {
            featureid: "87366ACMPAN_2020_0F",
            description: "Autodesk Helius PFA 2020"
        },
        {
            featureid: "87367VRDDES_2020_0F",
            description: "Autodesk VRED Design 2020"
        },
        {
            featureid: "87368VRDPRS_2020_0F",
            description: "Autodesk VRED Presenter 2020"
        },
        {
            featureid: "87369VRDPRO_2020_0F",
            description: "Autodesk VRED Professional 2020"
        },
        {
            featureid: "87370RCMVRD_2020_0F",
            description: "Autodesk VRED Render Node 2020"
        },
        {
            featureid: "87371VRDSRV_2020_0F",
            description: "Autodesk VRED Server 2020"
        },
        {
            featureid: "87372SCFDA_2020_0F",
            description: "Autodesk CFD Premium 2020"
        },
        {
            featureid: "87373SCFDM_2020_0F",
            description: "Autodesk CFD Ultimate 2020"
        },
        {
            featureid: "87374SCDSE_2020_0F",
            description: "Autodesk CFD Design Study Environment 2020"
        },
        {
            featureid: "87375NTFABLS_2020_0F",
            description: "Autodesk Netfabb Local Simulation 2020"
        },
        {
            featureid: "87376EMFEB_2020_0F",
            description: "Ent Multi-Flex Enhanced Bndl 2020"
        },
        {
            featureid: "87377T1MF_2020_0F",
            description: "T1 Enterprise Multi-flex 2020"
        },
        {
            featureid: "87378T1MFPV_2020_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2020"
        },
        {
            featureid: "87378T1MFPV_2021_0F",
            description: "T1 Enterprise Multi-flex Prior Version 2021"
        },
        {
            featureid: "87379T1MFSB_2020_0F",
            description: "T1 Enterprise Multi-flex Standard Bundle 2020"
        },
        {
            featureid: "87380T1MFPB_2020_0F",
            description: "T1 Enterprise Multi-Flex Standard Prior Version Bundle 2020"
        },
        {
            featureid: "87381INVTOL_2020_0F",
            description: "Autodesk Inventor Tolerance Analysis 2020"
        },
        {
            featureid: "87382INVNTOR_2021_0F",
            description: "Autodesk Inventor 2021"
        },
        {
            featureid: "87383INVLT_2021_0F",
            description: "Autodesk Inventor LT 2021"
        },
        {
            featureid: "87384INVOEM_2021_0F",
            description: "Autodesk Inventor OEM 2021"
        },
        {
            featureid: "87385INVPROSA_2021_0F",
            description: "Autodesk Inventor Professional 2021"
        },
        {
            featureid: "87386VLTEAD_2020_0F",
            description: "Enterprise Add-On for Autodesk Vault 2020"
        },
        {
            featureid: "87387IW360P_2020_0F",
            description: "Autodesk InfraWorks 2020"
        },
        {
            featureid: "87387IW360P_F",
            description: "Autodesk InfraWorks Package"
        },
        {
            featureid: "87387IW360P_T_F",
            description: "Autodesk InfraWorks Package Term"
        },
        {
            featureid: "87388INFMDR_F",
            description: "Autodesk InfraWorks LT Package"
        },
        {
            featureid: "87388INFMDR_T_F",
            description: "Autodesk InfraWorks LT Package Term"
        },
        {
            featureid: "87389RSAPRO_2021_0F",
            description: "Autodesk Robot Structural Analysis Professional 2021"
        },
        {
            featureid: "87390PCOFFI_2021_0F",
            description: "Autodesk Vault Office 2021"
        },
        {
            featureid: "87391VLTM_2021_0F",
            description: "Autodesk Vault Professional 2021"
        },
        {
            featureid: "87392VLTWG_2021_0F",
            description: "Autodesk Vault Workgroup 2021"
        },
        {
            featureid: "87393ACD_2021_0F",
            description: "Autodesk AutoCAD 2021"
        },
        {
            featureid: "87394DSPRM_2021_0F",
            description: "Autodesk AutoCAD Design Suite Premium 2021"
        },
        {
            featureid: "87394DSSTD_2021_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2021"
        },
        {
            featureid: "87395DSSTD_2021_0F",
            description: "Autodesk AutoCAD Design Suite Standard 2021"
        },
        {
            featureid: "87396ACDLT_2021_0F",
            description: "Autodesk AutoCAD LT 2021"
        },
        {
            featureid: "87397ARDES_2021_0F",
            description: "Autodesk AutoCAD Raster Design 2021"
        },
        {
            featureid: "87398ARCHDESK_2021_0F",
            description: "Autodesk AutoCAD Architecture 2021"
        },
        {
            featureid: "87399ACAD_E_2021_0F",
            description: "Autodesk AutoCAD Electrical 2021"
        },
        {
            featureid: "87400AMECH_PP_2021_0F",
            description: "Autodesk AutoCAD Mechanical 2021"
        },
        {
            featureid: "87401BLDSYS_2021_0F",
            description: "Autodesk AutoCAD MEP 2021"
        },
        {
            featureid: "87402PLNT3D_2021_0F",
            description: "Autodesk AutoCAD Plant 3D 2021"
        },
        {
            featureid: "87403MAP_2021_0F",
            description: "Autodesk AutoCAD Map 3D 2021"
        },
        {
            featureid: "87404ACDMAC_2021_0F",
            description: "Autodesk AutoCAD 2021 for Mac"
        },
        {
            featureid: "87405ACDLTM_2021_0F",
            description: "Autodesk AutoCAD LT 2021 for Mac"
        },
        {
            featureid: "87406NAVMAN_2021_0F",
            description: "Autodesk Navisworks Manage 2021"
        },
        {
            featureid: "87407NAVSIM_2021_0F",
            description: "Autodesk Navisworks Simulate 2021"
        },
        {
            featureid: "874083DSMXS_2021_0F",
            description: "Autodesk 3ds Max 2021 with Softimage"
        },
        {
            featureid: "87409MAXIO_2021_0F",
            description: "Autodesk 3ds Max IO 2021"
        },
        {
            featureid: "87410BDSPRM_2021_0F",
            description: "Autodesk Building Design Suite Premium 2021"
        },
        {
            featureid: "87411BDSS_2021_0F",
            description: "Autodesk Building Design Suite Standard 2021"
        },
        {
            featureid: "87412BDSADV_2021_0F",
            description: "Autodesk Building Design Suite Ultimate 2021"
        },
        {
            featureid: "87413PDSPRM_2021_0F",
            description: "Autodesk Plant Design Suite Premium 2021"
        },
        {
            featureid: "87414PLTDSS_2021_0F",
            description: "Autodesk Plant Design Suite Standard 2021"
        },
        {
            featureid: "87415PDSADV_2021_0F",
            description: "Autodesk Plant Design Suite Ultimate 2021"
        },
        {
            featureid: "87416IDSP_2021_0F",
            description: "Autodesk Infrastructure Design Suite Premium 2021"
        },
        {
            featureid: "87417IDSS_2021_0F",
            description: "Autodesk Infrastructure Design Suite Standard 2021"
        },
        {
            featureid: "87418IDSU_2021_0F",
            description: "Autodesk Infrastructure Design Suite Ultimate 2021"
        },
        {
            featureid: "87419MEPCS_2021_0F",
            description: "Autodesk MEP Fabrication Suite 2021"
        },
        {
            featureid: "87420SFS_2021_0F",
            description: "Autodesk Structural Fabrication Suite 2021"
        },
        {
            featureid: "87421ACDLTC_2021_0F",
            description: "Autodesk AutoCAD LT Civil Suite 2021"
        },
        {
            featureid: "87422RVTLTS_2021_0F",
            description: "Autodesk AutoCAD Revit LT Suite 2021"
        },
        {
            featureid: "87423CADMEP_2021_0F",
            description: "Autodesk Fabrication CADmep 2021"
        },
        {
            featureid: "87424CAMDCT_2021_0F",
            description: "Autodesk Fabrication CAMduct 2021"
        },
        {
            featureid: "87425ESTMEP_2021_0F",
            description: "Autodesk Fabrication ESTmep 2021"
        },
        {
            featureid: "87426IW360P_2021_0F",
            description: "Autodesk InfraWorks 2021"
        },
        {
            featureid: "87427VEHTRK_2021_0F",
            description: "Autodesk Vehicle Tracking 2021"
        },
        {
            featureid: "87428SBRDES_2021_0F",
            description: "Autodesk Structural Bridge Design 2021"
        },
        {
            featureid: "87429FDSPRM_2021_0F",
            description: "Autodesk Factory Design Suite Premium 2021"
        },
        {
            featureid: "87430FDSS_2021_0F",
            description: "Autodesk Factory Design Suite Standard 2021"
        },
        {
            featureid: "87431FDSADV_2021_0F",
            description: "Autodesk Factory Design Suite Ultimate 2021"
        },
        {
            featureid: "87432PDSP_2021_0F",
            description: "Autodesk Product Design Suite Premium 2021"
        },
        {
            featureid: "87433PDSU_2021_0F",
            description: "Autodesk Product Design Suite Ultimate 2021"
        },
        {
            featureid: "87434INVLTS_2021_0F",
            description: "Autodesk AutoCAD Inventor LT Suite 2021"
        },
        {
            featureid: "87435RRI2ADD_2021_0F",
            description: "Revit 2021 - with RIB iTWO add-on 2021"
        },
        {
            featureid: "87436ADSTPR_2021_0F",
            description: "Autodesk Advance Steel 2021"
        },
        {
            featureid: "87438HSMPRM_2021_0F",
            description: "Autodesk HSMWorks Premium 2021"
        },
        {
            featureid: "87439HSMPREM_2021_0F",
            description: "Autodesk HSM Premium 2021"
        },
        {
            featureid: "87440HSMULT_2021_0F",
            description: "Autodesk HSM Ultimate 2021"
        },
        {
            featureid: "87441INVHSM_2021_0F",
            description: "Autodesk Inventor CAM Premium 2021"
        },
        {
            featureid: "87442INHSMP_2021_0F",
            description: "Autodesk Inventor CAM Ultimate 2021"
        },
        {
            featureid: "87443RVT_2021_0F",
            description: "Autodesk Revit 2021"
        },
        {
            featureid: "87444RVTLT_2021_0F",
            description: "Autodesk Revit LT 2021"
        },
        {
            featureid: "87445CIV3D_2021_0F",
            description: "Autodesk Civil 3D 2021"
        },
        {
            featureid: "87446MXECS_2021_0F",
            description: "Autodesk 3ds Max Entertainment Creation Suite 2021"
        },
        {
            featureid: "87453MOBPRO_2021_0F",
            description: "Autodesk MotionBuilder 2021"
        },
        {
            featureid: "87454IETODEV_2021_0F",
            description: "Autodesk Inventor Engineer-to-Order 2021 - Developer"
        },
        {
            featureid: "87455INVETO_2021_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2021"
        },
        {
            featureid: "87456INTSER_2021_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2021"
        },
        {
            featureid: "87457FDU_2021_0F",
            description: "Autodesk Factory Design Utilities 2021"
        },
        {
            featureid: "874583DSMAX_2021_0F",
            description: "Autodesk 3ds Max 2021"
        },
        {
            featureid: "87459PNTLAY_2021_0F",
            description: "Autodesk Point Layout 2021"
        },
        {
            featureid: "87461FCAMP_2021_0F",
            description: "Autodesk FeatureCAM Premium 2021"
        },
        {
            featureid: "87462FCAMS_2021_0F",
            description: "Autodesk FeatureCAM Standard 2021"
        },
        {
            featureid: "87463FCAMU_2021_0F",
            description: "Autodesk FeatureCAM Ultimate 2021"
        },
        {
            featureid: "87464MPPUS_2021_0F",
            description: "Autodesk Manufacturing Automation Utility 2021"
        },
        {
            featureid: "87465NETFA_2021_0F",
            description: "Autodesk Netfabb Premium 2021"
        },
        {
            featureid: "87466NETFS_2021_0F",
            description: "Autodesk Netfabb Standard 2021"
        },
        {
            featureid: "87467NETFE_2021_0F",
            description: "Autodesk Netfabb Ultimate 2021"
        },
        {
            featureid: "87468PMAKER_2021_0F",
            description: "Autodesk PartMaker 2021"
        },
        {
            featureid: "87469PWRIP_2021_0F",
            description: "Autodesk PowerInspect Premium 2021"
        },
        {
            featureid: "87470PWRIS_2021_0F",
            description: "Autodesk PowerInspect Standard 2021"
        },
        {
            featureid: "87471PWRIU_2021_0F",
            description: "Autodesk PowerInspect Ultimate 2021"
        },
        {
            featureid: "87472PWRMMOD_2021_0F",
            description: "Autodesk PowerMill Modeling 2021"
        },
        {
            featureid: "87473PWRMP_2021_0F",
            description: "Autodesk PowerMill Premium 2021"
        },
        {
            featureid: "87474RECAP_2021_0F",
            description: "Autodesk ReCap Pro"
        },
        {
            featureid: "87475NINCAD_2021_0F",
            description: "Autodesk Inventor Nastran 2021"
        },
        {
            featureid: "87476PWRMS_2021_0F",
            description: "Autodesk PowerMill Standard 2021"
        },
        {
            featureid: "87477PWRMU_2021_0F",
            description: "Autodesk PowerMill Ultimate 2021"
        },
        {
            featureid: "87478PWRSP_2021_0F",
            description: "Autodesk PowerShape Premium 2021"
        },
        {
            featureid: "87479PWRSS_2021_0F",
            description: "Autodesk PowerShape Standard 2021"
        },
        {
            featureid: "87480PWRSU_2021_0F",
            description: "Autodesk PowerShape Ultimate 2021"
        },
        {
            featureid: "87492NTFABLS_2021_0F",
            description: "Autodesk Netfabb Local Simulation 2021"
        },
        {
            featureid: "87493C300_2021_0F",
            description: "Autodesk Burn 2021"
        },
        {
            featureid: "87494A250_2021_0F",
            description: "Autodesk Flame 2021"
        },
        {
            featureid: "87494A250_2022_0F",
            description: "Autodesk Flame 2022"
        },
        {
            featureid: "87495FLMEDU_2021_0F",
            description: "Autodesk Flame 2021 - Education"
        },
        {
            featureid: "87495FLMEDU_2022_0F",
            description: "Autodesk Flame 2022 - Education"
        },
        {
            featureid: "87496A350_2021_0F",
            description: "Autodesk Flame Assist 2021"
        },
        {
            featureid: "87497A280_2021_0F",
            description: "Autodesk Flame Premium 2021"
        },
        {
            featureid: "87498A400_2021_0F",
            description: "Autodesk Flare 2021"
        },
        {
            featureid: "87499K100_2021_0F",
            description: "Autodesk Lustre 2021"
        },
        {
            featureid: "87500LSTRBRN_2021_0F",
            description: "Autodesk Lustre Burn 2021"
        },
        {
            featureid: "87501LSTRSR_2021_0F",
            description: "Autodesk Lustre ShotReactor 2021"
        },
        {
            featureid: "87503ALSCPT_2021_0F",
            description: "Autodesk Alias Concept 2021"
        },
        {
            featureid: "87504DESNST_2021_0F",
            description: "Autodesk Alias Design 2021"
        },
        {
            featureid: "87505SURFST_2021_0F",
            description: "Autodesk Alias Surface 2021"
        },
        {
            featureid: "87506WTGTWY_2021_0F",
            description: "Autodesk Wiretap Gateway 2021"
        },
        {
            featureid: "87507VRDDES_2021_0F",
            description: "Autodesk VRED Design 2021"
        },
        {
            featureid: "87507VRDDES_2022_0F",
            description: "Autodesk VRED Design 2022"
        },
        {
            featureid: "87508VRDPRS_2021_0F",
            description: "Autodesk VRED Presenter 2021"
        },
        {
            featureid: "87509VRDPRO_2021_0F",
            description: "Autodesk VRED Professional 2021"
        },
        {
            featureid: "87510RCMVRD_2021_0F",
            description: "Autodesk VRED Render Node 2021"
        },
        {
            featureid: "87511VRDSRV_2021_0F",
            description: "Autodesk VRED Server 2021"
        },
        {
            featureid: "87513TRUNST_2021_0F",
            description: "Autodesk TruNest 2021 - Nesting Engine"
        },
        {
            featureid: "87514TNNUINV_2021_0F",
            description: "Autodesk Inventor Nesting 2021"
        },
        {
            featureid: "87515TRCMPS_2021_0F",
            description: "Autodesk TruComposites Standard 2021"
        },
        {
            featureid: "87516TRCMPU_2021_0F",
            description: "Autodesk TruComposites Ultimate 2021"
        },
        {
            featureid: "87517TRUNEST_2021_0F",
            description: "Autodesk TruNest 2021"
        },
        {
            featureid: "87518RVTGD_2021_0F",
            description: "Autodesk Revit Generative Design 2021"
        },
        {
            featureid: "87519ACMPAN_2021_0F",
            description: "Autodesk Helius PFA 2021"
        },
        {
            featureid: "87520MFAM_2021_0F",
            description: "Autodesk Moldflow Adviser Premium 2021"
        },
        {
            featureid: "87521MFAA_2021_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2021"
        },
        {
            featureid: "87522MFIP_2021_0F",
            description: "Autodesk Moldflow Insight Premium 2021"
        },
        {
            featureid: "87523MFIB_2021_0F",
            description: "Autodesk Moldflow Insight Standard 2021"
        },
        {
            featureid: "87524MFIA_2021_0F",
            description: "Autodesk Moldflow Insight Ultimate 2021"
        },
        {
            featureid: "87525MFIAT_2021_0F",
            description: "Autodesk Moldflow Insight Ultimate TFLEX 2021"
        },
        {
            featureid: "87526MFS_2021_0F",
            description: "Autodesk Moldflow Synergy 2021"
        },
        {
            featureid: "87527MFST_2021_0F",
            description: "Autodesk Moldflow Synergy TFLEX 2021"
        },
        {
            featureid: "87528SBPNL_2021_0F",
            description: "Autodesk SketchBook Pro"
        },
        {
            featureid: "87529INVTOL_2021_0F",
            description: "Autodesk Inventor Tolerance Analysis 2021"
        },
        {
            featureid: "87530SCFDM_2021_0F",
            description: "Autodesk CFD Ultimate 2021"
        },
        {
            featureid: "87531VLTEAD_2021_0F",
            description: "Enterprise Add-On for Autodesk Vault 2021"
        },
        {
            featureid: "87532SCDSE_2021_0F",
            description: "Autodesk CFD 2021 Design Study Environment"
        },
        {
            featureid: "87533RSAPRO_2022_0F",
            description: "Autodesk Robot Structural Analysis Professional 2022"
        },
        {
            featureid: "87534INVNTOR_2022_0F",
            description: "Autodesk Inventor 2022"
        },
        {
            featureid: "87536INVOEM_2022_0F",
            description: "Autodesk Inventor OEM 2022"
        },
        {
            featureid: "87537INVPROSA_2022_0F",
            description: "Autodesk Inventor Professional 2022"
        },
        {
            featureid: "87538EMFEB_2021_0F",
            description: "Ent Multi-Flex Enhanced Bndl 2021"
        },
        {
            featureid: "87539T1MFPB_2021_0F",
            description: "T1 Enterprise Multi-flex 2021"
        },
        {
            featureid: "87540ARCHDESK_2022_0F",
            description: "Autodesk AutoCAD Architecture 2022"
        },
        {
            featureid: "87541ACAD_E_2022_0F",
            description: "Autodesk AutoCAD Electrical 2022"
        },
        {
            featureid: "87542AMECH_PP_2022_0F",
            description: "Autodesk AutoCAD Mechanical 2022"
        },
        {
            featureid: "87543BLDSYS_2022_0F",
            description: "Autodesk AutoCAD MEP 2022"
        },
        {
            featureid: "87544PLNT3D_2022_0F",
            description: "Autodesk AutoCAD Plant 3D 2022"
        },
        {
            featureid: "87545ACD_2022_0F",
            description: "Autodesk AutoCAD 2022"
        },
        {
            featureid: "87546ACDLT_2022_0F",
            description: "Autodesk AutoCAD LT 2022"
        },
        {
            featureid: "87547ARDES_2022_0F",
            description: "Autodesk AutoCAD Raster Design 2022"
        },
        {
            featureid: "87548MAP_2022_0F",
            description: "Autodesk AutoCAD Map 3D 2022"
        },
        {
            featureid: "87551ACDMAC_2022_0F",
            description: "Autodesk AutoCAD 2022 for Mac"
        },
        {
            featureid: "87552ACDLTM_2022_0F",
            description: "Autodesk AutoCAD LT 2022 for Mac"
        },
        {
            featureid: "87553ADSTPR_2022_0F",
            description: "Autodesk Advance Steel 2022"
        },
        {
            featureid: "87556CIV3D_2022_0F",
            description: "Autodesk Civil 3D 2022"
        },
        {
            featureid: "87557CADMEP_2022_0F",
            description: "Autodesk Fabrication CADmep 2022"
        },
        {
            featureid: "87558CAMDCT_2022_0F",
            description: "Autodesk Fabrication CAMduct 2022"
        },
        {
            featureid: "87559ESTMEP_2022_0F",
            description: "Autodesk Fabrication ESTmep 2022"
        },
        {
            featureid: "87560IW360P_2022_0F",
            description: "Autodesk InfraWorks 2022"
        },
        {
            featureid: "87561RVT_2022_0F",
            description: "Autodesk Revit 2022"
        },
        {
            featureid: "87563RVTLT_2022_0F",
            description: "Autodesk Revit LT 2022"
        },
        {
            featureid: "87564SBRDES_2022_0F",
            description: "Autodesk Structural Bridge Design 2022"
        },
        {
            featureid: "87565VEHTRK_2022_0F",
            description: "Autodesk Vehicle Tracking 2022"
        },
        {
            featureid: "87566NAVMAN_2022_0F",
            description: "Autodesk Navisworks Manage 2022"
        },
        {
            featureid: "87567NAVSIM_2022_0F",
            description: "Autodesk Navisworks Simulate 2022"
        },
        {
            featureid: "875683DSMAX_2022_0F",
            description: "Autodesk 3ds Max 2022"
        },
        {
            featureid: "875693DSMXS_2022_0F",
            description: "Autodesk 3ds Max 2022 with Softimage"
        },
        {
            featureid: "87570MAXIO_2022_0F",
            description: "Autodesk 3ds Max IO 2022"
        },
        {
            featureid: "87571SIMDFM_2021_0F",
            description: "Autodesk Moldflow Design 2021"
        },
        {
            featureid: "87572C3DPE_2021_0F",
            description: "Autodesk Civil 3D - Project Explorer 2021"
        },
        {
            featureid: "87572C3DPE_F",
            description: "Autodesk Civil 3D - Project Explorer Package"
        },
        {
            featureid: "87572C3DPE_T_F",
            description: "Autodesk Civil 3D - Project Explorer Package Term"
        },
        {
            featureid: "87573PNTLAY_2022_0F",
            description: "Autodesk Point Layout 2022"
        },
        {
            featureid: "87574HSMPREM_2022_0F",
            description: "Autodesk HSM Premium 2022"
        },
        {
            featureid: "87575HSMULT_2022_0F",
            description: "Autodesk HSM Ultimate 2022"
        },
        {
            featureid: "87576INVHSM_2022_0F",
            description: "Autodesk Inventor CAM Premium 2022"
        },
        {
            featureid: "87577INHSMP_2022_0F",
            description: "Autodesk Inventor CAM Ultimate 2022"
        },
        {
            featureid: "87580FCAMP_2022_0F",
            description: "Autodesk FeatureCAM Premium 2022"
        },
        {
            featureid: "87581FCAMS_2022_0F",
            description: "Autodesk FeatureCAM Standard 2022"
        },
        {
            featureid: "87582FCAMU_2022_0F",
            description: "Autodesk FeatureCAM Ultimate 2022"
        },
        {
            featureid: "87583PMAKER_2022_0F",
            description: "Autodesk PartMaker 2022"
        },
        {
            featureid: "87584PWRIP_2022_0F",
            description: "Autodesk PowerInspect Premium 2022"
        },
        {
            featureid: "87585PWRIS_2022_0F",
            description: "Autodesk PowerInspect Standard 2022"
        },
        {
            featureid: "87586PWRIU_2022_0F",
            description: "Autodesk PowerInspect Ultimate 2022"
        },
        {
            featureid: "87587PWRMMOD_2022_0F",
            description: "Autodesk PowerMill Modeling 2022"
        },
        {
            featureid: "87588PWRMP_2022_0F",
            description: "Autodesk PowerMill Premium 2022"
        },
        {
            featureid: "87589PWRMS_2022_0F",
            description: "Autodesk PowerMill Standard 2022"
        },
        {
            featureid: "87590PWRMU_2022_0F",
            description: "Autodesk PowerMill Ultimate 2022"
        },
        {
            featureid: "87591PWRSP_2022_0F",
            description: "Autodesk PowerShape Premium 2022"
        },
        {
            featureid: "87592PWRSS_2022_0F",
            description: "Autodesk PowerShape Standard 2022"
        },
        {
            featureid: "87593PWRSU_2022_0F",
            description: "Autodesk PowerShape Ultimate 2022"
        },
        {
            featureid: "87606MPPUS_2022_0F",
            description: "Autodesk Manufacturing Automation Utility 2022"
        },
        {
            featureid: "87607NETFA_2022_0F",
            description: "Autodesk Netfabb Premium 2022"
        },
        {
            featureid: "87608NETFS_2022_0F",
            description: "Autodesk Netfabb Standard 2022"
        },
        {
            featureid: "87609NETFE_2022_0F",
            description: "Autodesk Netfabb Ultimate 2022"
        },
        {
            featureid: "87611PCOFFI_2022_0F",
            description: "Autodesk Vault Office 2022"
        },
        {
            featureid: "87612VLTM_2022_0F",
            description: "Autodesk Vault Professional 2022"
        },
        {
            featureid: "87613VLTWG_2022_0F",
            description: "Autodesk Vault Workgroup 2022"
        },
        {
            featureid: "87615C3DPE_2022_0F",
            description: "Autodesk Civil 3D - Project Explorer 2022"
        },
        {
            featureid: "87617MAYA_2022_0F",
            description: "Autodesk Maya 2022"
        },
        {
            featureid: "87618MAYAS_2022_0F",
            description: "Autodesk Maya 2022 with Softimage"
        },
        {
            featureid: "87619MAYAIO_2022_0F",
            description: "Autodesk Maya IO 2022"
        },
        {
            featureid: "87620MAYALT_2022_0F",
            description: "Autodesk Maya LT 2022"
        },
        {
            featureid: "87621MOBPRO_2022_0F",
            description: "Autodesk MotionBuilder 2022"
        },
        {
            featureid: "87622MBXPRO_2022_0F",
            description: "Autodesk Mudbox 2022"
        },
        {
            featureid: "87623RECAP_2022_0F",
            description: "Autodesk ReCap Pro"
        },
        {
            featureid: "87625NINCAD_2022_0F",
            description: "Autodesk Inventor Nastran 2022"
        },
        {
            featureid: "87628A350_2022_0F",
            description: "Autodesk Flame Assist 2022"
        },
        {
            featureid: "87629A280_2022_0F",
            description: "Autodesk Flame Premium 2022"
        },
        {
            featureid: "87630A400_2022_0F",
            description: "Autodesk Flare 2022"
        },
        {
            featureid: "87631K100_2022_0F",
            description: "Autodesk Lustre 2022"
        },
        {
            featureid: "87632IETODEV_2022_0F",
            description: "Autodesk Inventor Engineer-to-Order 2022 - Developer"
        },
        {
            featureid: "87633INVETO_2022_0F",
            description: "Autodesk Inventor Engineer-to-Order Series 2022"
        },
        {
            featureid: "87634INTSER_2022_0F",
            description: "Autodesk Inventor Engineer-to-Order Server 2022"
        },
        {
            featureid: "87635FDU_2022_0F",
            description: "Autodesk Factory Design Utilities 2022"
        },
        {
            featureid: "87636ALAUST_2022_0F",
            description: "Autodesk Alias AutoStudio 2022"
        },
        {
            featureid: "87637ALSCPT_2022_0F",
            description: "Autodesk Alias Concept 2022"
        },
        {
            featureid: "87638DESNST_2022_0F",
            description: "Autodesk Alias Design 2022"
        },
        {
            featureid: "87639SURFST_2022_0F",
            description: "Autodesk Alias Surface 2022"
        },
        {
            featureid: "87640CAMTP_2022_0F",
            description: "Autodesk CAMplete TruePath 2022"
        },
        {
            featureid: "87640CAMTP_F",
            description: "Autodesk CAMplete TruePath Package"
        },
        {
            featureid: "87640CAMTP_T_F",
            description: "Autodesk CAMplete TruePath Package Term"
        },
        {
            featureid: "87641CAMTM_2022_0F",
            description: "Autodesk CAMplete TurnMill 2022"
        },
        {
            featureid: "87641CAMTM_F",
            description: "Autodesk CAMplete TurnMill Package"
        },
        {
            featureid: "87641CAMTM_T_F",
            description: "Autodesk CAMplete TurnMill Package Term"
        },
        {
            featureid: "87644VRDPRS_2022_0F",
            description: "Autodesk VRED Presenter 2022"
        },
        {
            featureid: "87645VRDPRO_2022_0F",
            description: "Autodesk VRED Professional 2022"
        },
        {
            featureid: "87646RCMVRD_2022_0F",
            description: "Autodesk VRED Render Node 2022"
        },
        {
            featureid: "87647TNNUINV_2022_0F",
            description: "Autodesk Inventor Nesting 2022"
        },
        {
            featureid: "87648MFAM_2022_0F",
            description: "Autodesk Moldflow Adviser Premium 2022"
        },
        {
            featureid: "87649MFAA_2022_0F",
            description: "Autodesk Moldflow Adviser Ultimate 2022"
        },
        {
            featureid: "87650MFIP_2022_0F",
            description: "Autodesk Moldflow Insight Premium 2022"
        },
        {
            featureid: "87651MFIB_2022_0F",
            description: "Autodesk Moldflow Insight Standard 2022"
        },
        {
            featureid: "87652MFIA_2022_0F",
            description: "Autodesk Moldflow Insight Ultimate 2022"
        },
        {
            featureid: "87653MFIAT_2022_0F",
            description: "Autodesk Moldflow Insight Ultimate TFLEX 2022"
        },
        {
            featureid: "87654MFS_2022_0F",
            description: "Autodesk Moldflow Synergy 2022"
        },
        {
            featureid: "87655MFST_2022_0F",
            description: "Autodesk Moldflow Synergy TFLEX 2022"
        },
        {
            featureid: "87656INVTOL_2022_0F",
            description: "Autodesk Inventor Tolerance Analysis 2022"
        },
        {
            featureid: "87657SCFDM_2022_0F",
            description: "Autodesk CFD Ultimate 2022"
        },
        {
            featureid: "87658NTFABLS_2022_0F",
            description: "Autodesk Netfabb Local Simulation 2022"
        },
        {
            featureid: "87659SCDSE_2022_0F",
            description: "Autodesk CFD 2022 Design Study Environment"
        },
        {
            featureid: "87661VLTEAD_2022_0F",
            description: "Enterprise Add-On for Autodesk Vault 2022"
        },
        {
            featureid: "905787BDSPRM_F",
            description: "Autodesk Building Design Suite Premium Package"
        },
        {
            featureid: "905946BDSPRM_2013_0F",
            description: "Autodesk Building Design Suite Premium 2013"
        },
        {
            featureid: "95787BDSPRM_F",
            description: "Autodesk Building Design Suite Premium Package"
        },
        {
            featureid: "AVEVA201",
            description: "AVEVA Everything3D"
        },
        {
            featureid: "AVEVA231",
            description: "AVEVA Administration (admin module)"
        },
        {
            featureid: "AVEVA232",
            description: "AVEVA Catalogue (paragon)"
        },
        {
            featureid: "CM-C",
            description: "Tekla : Construction Modeling"
        },
        {
            featureid: "CW_ACAD",
            description: "CloudWorx for AutoCAD"
        },
        {
            featureid: "CW_ACAD_PRO",
            description: "CloudWorx for AutoCAD Pro"
        },
        {
            featureid: "CW_SPR",
            description: "CloudWorx for SmartPlant Review"
        },
        {
            featureid: "DESIGN-PLATFORM",
            description: "AVEVA DESIGN-PLATFORM"
        },
        {
            featureid: "DEV-C",
            description: "Tekla : Developer"
        },
        {
            featureid: "DFR-C",
            description: "Tekla : Drafter"
        },
        {
            featureid: "EDU-C",
            description: "Tekla : Educational"
        },
        {
            featureid: "FUD-C",
            description: "Tekla : Full version"
        },
        {
            featureid: "FULL-C",
            description: "Tekla : Full version"
        },
        {
            featureid: "FWP",
            description: "FrameWorks Plus"
        },
        {
            featureid: "INB",
            description: "SmartPlant Instrumentation modules"
        },
        {
            featureid: "INK",
            description: "SmartPlant Instrumentation (Currently Not Used)"
        },
        {
            featureid: "INM",
            description: "SmartPlant Instrumentation (INtools) Maintenance"
        },
        {
            featureid: "INS",
            description: "SmartPlant Instrumentation (INtools) Standard"
        },
        {
            featureid: "INV",
            description: "SmartPlant Instrumentation (INtools) View Only / API"
        },
        {
            featureid: "IS",
            description: "SmartMarine 3D"
        },
        {
            featureid: "ISK",
            description: "SmartPlant Isometrics"
        },
        {
            featureid: "ISM",
            description: "SmartPlant Isometrics Modules"
        },
        {
            featureid: "ISO",
            description: "SmartMarine 3D Outfitting"
        },
        {
            featureid: "IST",
            description: "SmartMarine 3D Tribon"
        },
        {
            featureid: "MAT",
            description: "SmartPlant Materials"
        },
        {
            featureid: "PCD-C",
            description: "Tekla : Precast concrete Detailing"
        },
        {
            featureid: "PLIST",
            description: "PLIST"
        },
        {
            featureid: "PMD",
            description: "SupportModeler for PDS"
        },
        {
            featureid: "PMN",
            description: "SupportManager for PDS"
        },
        {
            featureid: "PPC-C",
            description: "Tekla : Production Planner - Concrete"
        },
        {
            featureid: "PRI-C",
            description: "Tekla : Primary"
        },
        {
            featureid: "PWN1400",
            description: "GTXRaster CAD PLUS"
        },
        {
            featureid: "RBD-C",
            description: "Tekla : RebarDetailing"
        },
        {
            featureid: "RCD-C",
            description: "Tekla : Cast in place"
        },
        {
            featureid: "RD",
            description: "SmartPlant Reference Data"
        },
        {
            featureid: "RWN1400",
            description: "GTXRaster CAD"
        },
        {
            featureid: "S3D",
            description: "SmartPlant 3D"
        },
        {
            featureid: "SDE-C",
            description: "Tekla : Engineering"
        },
        {
            featureid: "SDF",
            description: "SDNF Import"
        },
        {
            featureid: "SEB",
            description: "SmartPlant Electrical SAP Module"
        },
        {
            featureid: "SEI",
            description: "SmartPlant Electrical Import Manager"
        },
        {
            featureid: "SEL",
            description: "SmartPlant Electrical"
        },
        {
            featureid: "SHOWPRO_F_S",
            description: "Autodesk Showcase Professional Package"
        },
        {
            featureid: "SIM",
            description: "SmartPlant 3D PDS Model and Data Translators"
        },
        {
            featureid: "SLA",
            description: "SmartMarine 3D Layout"
        },
        {
            featureid: "SPG",
            description: "SmartPlant Spoolgen"
        },
        {
            featureid: "SPP",
            description: "SmartPlant P&ID"
        },
        {
            featureid: "SPPD",
            description: "SmartPlant P&ID"
        },
        {
            featureid: "SPR",
            description: "SmartPlant Review"
        },
        {
            featureid: "SRM",
            description: "SmartPlant Review Modules"
        },
        {
            featureid: "SRP",
            description: "SmartPlant Review Publisher"
        },
        {
            featureid: "SSK",
            description: "SmartSketch"
        },
        {
            featureid: "STD-C",
            description: "Tekla : Steel detailing"
        },
        {
            featureid: "STDL-C",
            description: "Tekla : Primary"
        },
        {
            featureid: "VIE-C",
            description: "Tekla : Project Viewer"
        }
    ];

export default feature;