<template>
  <div>
    <!-- Render the AccessPerProductChart component with the data prop -->
    <access-per-product-chart :data="data"></access-per-product-chart>
  </div>
</template>

<script>
import AccessPerProductChart from "../../../charts/accessPerProductChart";
export default {
  components: {
    "access-per-product-chart": AccessPerProductChart,
  },
  data() {
    return {
      data: [],
    };
  },
  watch: {
    // Watch for changes in the filteredPerpetualAccesses prop
    filteredPerpetualAccesses: async function () {
      // Initialize an array to hold the processed data
      var result = [];

      // Loop through each filtered perpetual access entry
      this.filteredPerpetualAccesses.forEach(function (a) {
        // If the featureDescription does not exist in the result array, create it
        if (!this[a.featureDescription]) {
          this[a.featureDescription] = {
            featureDescription: a.featureDescription,
            used: 0,
          };
          result.push(this[a.featureDescription]);
        }
        // Increment the usage count for the corresponding featureDescription
        this[a.featureDescription].used += a.used;
      }, Object.create(null));

      result.sort((a, b) => {
        if (a.used > b.used) {
          return -1;
        } else {
          return 1;
        }
      });
      //update chart option
      this.data = result.map((d) => {
        return {
          name: d.featureDescription,
          y: parseFloat(d.used.toFixed(2)),
        };
      });
    },
  },
  props: ["filteredPerpetualAccesses"],
};
</script>