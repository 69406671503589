<template>
  <!-- Card component for displaying Autodesk Docs access information -->
  <b-card title="Autodesk Docs">
    <!-- BimAccessInfo component for displaying access information -->
    <bim-access-info
      :companyData="companyData"
      :filteredBimUsers="[]"
      :isLoading="false"
      :bimAccessesReportLog="bimAccessesReportLog"
    >
    </bim-access-info>
    <line-chart-container :accessesReportLog="bimAccessesReportLog" />
  </b-card>
</template>

<script>
import Vue from "vue";
import jwt_decode from "jwt-decode";
import BimAccessInfo from "../../../../bimAccess/info/index.vue";
import Bim360DocsLineChartContainer from "../../lineChartContainer";
import { getBimAccessReportLog } from "../../../../../../../../services/bimAccessService";

export default {
  data() {
    return {
      // Initialize bimAccessesReportLog data property
      bimAccessesReportLog: [],
    };
  },
  props: ["companyData"],
  components: {
    // Method to asynchronously fetch BIM access report log
    "bim-access-info": BimAccessInfo,
    "line-chart-container": Bim360DocsLineChartContainer,
  },
  methods: {
    async getBimAccessReportLog() {
      const parsedToken = await jwt_decode(Vue.$keycloak.token);
      var companyId = parsedToken.company_id;
      var result = await getBimAccessReportLog(companyId);
      // Update bimAccessesReportLog with fetched data
      this.bimAccessesReportLog = result.data;
    },
  },
  async created() {
    // Call getBimAccessReportLog method on component creation
    this.getBimAccessReportLog();
  },
};
</script>